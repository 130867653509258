import axios from 'axios'
import { lmsDevUrl, lmsProdUrl } from '../config'
import { store } from '../state'

const ExternalAppInstance = axios.create({
	baseURL: window.location.href.includes('varys') ? lmsProdUrl : lmsDevUrl,
	headers: {
		Accept: 'application/json',
		'Content-type': 'application/json',
	},
	withCredentials: false,
})

export default ExternalAppInstance
