import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { useDispatch } from 'react-redux'
import { Dispatch } from 'redux'
import { Action } from '../state/actions/index'
import { ActionType } from '../state/action-types'
import Authorizing from '../pages/status/Authorizing'
import { useLogActions } from '../hooks/useActions'

interface AuthProps {
	setFirstLoadingDone: React.Dispatch<React.SetStateAction<boolean>>
}

const Auth: React.FC<AuthProps> = ({ setFirstLoadingDone }) => {
	const query = new URLSearchParams(useLocation().search)
	const { insertLog } = useLogActions()

	const fetchTokenFromUrl = () => {
		return query.get('token') || ''
	}
	const navigate = useNavigate()
	const dispatch = useDispatch()
	useEffect(() => {
		const iframe = document.createElement('iframe')
		iframe.style.display = 'none'
		const token = fetchTokenFromUrl()
		const isProd = !window.location.hostname.includes('localhost')
		const isStage = window.location.hostname.includes('stage.varys.io')
		const url = `http${isProd ? 's' : ''}://${
			isProd ? (isStage ? 'stage.varys.io' : 'varys.io') : 'localhost:4000'
		}/api/authorize?redirect=${window.location.origin}&token=${token}`
		iframe.src = url
		document.body.appendChild(iframe)

		const messageListener = async (event: MessageEvent) => {
			if (event.data.hello) {
				return
			}
			if (event.data && event.data.token) {
				const parsedData = JSON.parse(atob(event.data.token))
				try {
					dispatch((dispatch: Dispatch<Action>) => {
						dispatch({
							type: ActionType.LOGIN,
							payload: parsedData.login,
						})
						setFirstLoadingDone(true)
						insertLog({
							email: parsedData.login.user.email,
							change: 'Login',
							object: {},
						})
						navigate('/news')
					})
				} catch (err) {
					console.error(err)
				}
			}
		}

		window.addEventListener('message', messageListener)

		return () => {
			window.removeEventListener('message', messageListener)
			document.body.removeChild(iframe)
		}
	}, [])

	return <Authorizing />
}

export default Auth
