import React, { useState, useRef } from 'react'
import {
	Modal,
	Box,
	Typography,
	Button,
	IconButton,
	Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { SubmitButton, UnborderedButton } from '../../pages/components/Buttons'

interface FileUploadModalProps {
	open: boolean
	handleClose: () => void
	onUpload: (file: File) => void
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
	open,
	handleClose,
	onUpload,
}) => {
	const [file, setFile] = useState<File | null>(null)
	const [isHovered, setIsHovered] = useState(false)
	const fileInputRef = useRef<HTMLInputElement | null>(null)

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			setFile(event.target.files[0])
		}
	}

	const handleUploadClick = () => {
		if (file) {
			onUpload(file)
		} else {
			// console.log('No file selected.')
		}
	}

	return (
		<Modal open={open} onClose={handleClose}>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					p: 4,
					boxShadow: 24,
					borderRadius: 2,
					backgroundColor: 'gray',
				}}
			>
				<UnborderedButton
					aria-label='close'
					onClick={handleClose}
					style={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
				>
					<CloseIcon />
				</UnborderedButton>
				<Typography variant='h6' component='h2' marginBottom={2} color='white'>
					Upload a File
				</Typography>
				<input
					type='file'
					style={{ display: 'block', marginBottom: 20, width: '100%' }}
					onChange={handleFileChange}
					ref={fileInputRef}
				/>
				<Stack direction='row' justifyContent='flex-end' spacing={4}>
					<UnborderedButton
						onClick={handleUploadClick}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: 'white',
							backgroundColor: isHovered ? 'white' : 'transparent',
						}}
					>
						Upload
					</UnborderedButton>
				</Stack>
			</Box>
		</Modal>
	)
}

export default FileUploadModal
