import { LicenseInfo } from '@mui/x-license-pro'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import './App.css'
import { Loading, LoadingOverlay } from './assets/svg/loading'
import {
	useActions,
	useUserActions,
	useSettingsActions,
	useReportActions,
} from './hooks/useActions'
import { useTypedSelector } from './hooks/useTypedSelector'
import { useIsAuthorized } from './hooks/useIsAuthorized'
import { NavbarContainer } from './navigation/NavBar'
import AdjustReportsPage from './pages/adjust/FiltersPage'
import AdvertiserPreviewPage from './pages/advertisers/AdvertiserPreviewPage'
import AdvertiserPage from './pages/advertisers/AdvertiserTable'
import AppsPage from './pages/apps/AppsTable'
import P360Page from './pages/apps/P360Table'
import AppsflyerAccountsTable from './pages/appsflyer/AppsflyerAccountsTable'
import CampaignPage from './pages/campaigns/CampaignTable'
import { Dashboard } from './pages/dashboard/Dashboard'
import LearningComponent from './pages/documents/LearningComponent'
import BalanceInPage from './pages/finance/BalanceInTable'
import BalanceOutPage from './pages/finance/BalanceOutTable'
import ControllerPage from './pages/finance/ControllerTable'
import DemandPage from './pages/finance/DemandTable'
import GlobalTable from './pages/finance/GlobalTable'
import MediaBuyingPlatform from './pages/finance/MediaBuyingTable'
import PersonalPage from './pages/finance/PersonalTable'
import SupplyPreview from './pages/finance/SupplyPreview'
import SupplyPage from './pages/finance/SupplyTable'
import InvalidReportsPage from './pages/reports/invalid/InvalidPage'
import Login from './pages/login/loginPage'
import LogsPage from './pages/logs/logsPage'
import MediaPlanPage from './pages/mediaplan/MediaPlanPage'
import NetworkPage from './pages/reports/network/NetworkPage'
import NewsPage from './pages/news/newsPage'
import AutoSwitchPage from './pages/optimization/AutoSwitch'
import P360Dashboard from './pages/p360dashboard/p360dashboard'
import { AdsPlacementPage } from './pages/adsplacement/AdsPlacementPage'
import PasswordsPage from './pages/passwords/PasswordsTable'
import PublisherPreviewPage from './pages/publishers/PublisherPreviewPage'
import PublisherPage from './pages/publishers/PublisherTable'
import RawReportsPage from './pages/rawdata/FiltersPage'
import AggreagatedReportPage from './pages/reports/aggregated/AggregatedPage'
import PersonalSettingsPage from './pages/settings/personalPage'
import SettingsPage from './pages/settings/settingsPage'
import UserPage from './pages/users/UserTable'
import { permissionNames } from './utils/helpers/permissionsHelper'
import NotLogged from './utils/NotLogged'
import DemandInvoiceTable from './pages/finance/DemandInvoiceTable'
import CampaignPreviewPage from './pages/campaigns/CampaignPreviewPage'
import WishListTable from './pages/publisher-wishlist/WishListTable'
import CreatorPreviewPage from './pages/publishers/CreatorPreviewPage'
import ResetPasswordPage from './pages/login/ResetPasswordPage'
import CreatorFinancePage from './pages/finance/creators/CreatorTable'
import CreatorPersonalPage from './pages/finance/creators/CreatorPersonalTable'
import CreatorSupplyPage from './pages/finance/creators/CreatorSupply'
import HandshakePage from './pages/handshake/HandshakePage'
import AlertContainer from './pages/components/alerts/AlertContainer'
import Auth from './navigation/AuthComponent'
import { LoadingComponent } from './navigation/LoadingComponent'
import CompanyPersonalPage from './pages/company/CompanyPersonalPage'
import CompanyPersonalPageEdit from './pages/company/CompanyPersonalPageEdit'
import CustomThemeProvider from './utils/CustomThemeProvider'
import NotFound from './pages/status/NotFound'
import UnderMaintenance from './pages/status/UnderMaintenance'
import { SYSTEM_DARK_MODE_QUERY, UNDERMAINTENANCE } from './config'
import SignUpDetailsPage from './pages/login/SignUpDetailsPage'
import LMSRedirect from './navigation/RedirectToLms'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MATERIAL_UI_KEY as string)
const App: React.FC = () => {
	const {
		users,
		login,
		advertiser,
		publisher,
		campaign,
		app,
		dashboard,
		logs,
		networks,
		passwords,
		campaign_history,
		autoswitch,
		avatars,
		news,
		settings,
		// permissions,
	} = useTypedSelector((state) => state)
	const [errorMessage, setErrorMessage] = useState(null)
	const [successMessage, setSuccessMessage] = useState(null)
	const [userMessage, setUserMessage] = useState(null)
	const [isErrorPromptOpened, setIsErrorPromptOpened] = useState(false)
	const [isSuccessPromptOpened, setIsSuccessPromptOpened] = useState(false)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [isUserMessagePromptOpened, setIsUserMessagePromptOpened] =
		useState(false)
	// const [loading, setLoading] = useState(false)
	const [loadingFromComponent, setLoadingFromComponent] = useState(false)
	// const [presetSearch, setPresetSearch] = useState<string>('')
	const [viewRecord, setViewRecord] = useState<any>(null)
	const [isPreviewOpened, setIsPreviewOpened] = useState(false)
	const [PopUpVisible, setPopUpVisible] = useState(false)
	const [forcedFilterStatus, setForcedFilterStatus] = useState(1)
	const [firstLoadingDone, setFirstLoadingDone] = useState(false)
	const [underMaintenance, setUnderMaintenance] = useState(UNDERMAINTENANCE)
	const themeLocalStorage = 'theme'
	let initialTheme = false
	const sessionKey = 'search-bar'
	const localStorageThemeValue = localStorage.getItem(themeLocalStorage)

	if (localStorageThemeValue && localStorageThemeValue.length > 4) {
		const data = JSON.parse(localStorageThemeValue)
		if (data.email === login.user.email) {
			initialTheme = data.value
		}
	} else {
		// If no localStorage preference, use system preference
		const systemPrefersDark = window.matchMedia(SYSTEM_DARK_MODE_QUERY).matches

		initialTheme = systemPrefersDark
	}

	if (localStorageThemeValue) {
		const storedData = JSON.parse(localStorageThemeValue)
		initialTheme = storedData.value
	}
	const [darkTheme, setDarkTheme] = useState<boolean>(initialTheme)
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [search, setSearch] = useState(
		sessionFilters.search ? sessionFilters.search : '',
	)
	const [presetSearch, setPresetSearch] = useState(
		sessionFilters.search ? sessionFilters.search : '',
	)
	const { getPermissions } = useActions()
	const { getSettingsAction } = useSettingsActions()
	const { usersAction, getLoggedInUser } = useUserActions()
	// const headers = {
	// 	Authorization: login.user ? `Token ${login.user.token}` : '',
	// }
	const companyType = login?.company?.type || 'agency'
	// const companyType = 'publisher'
	const companyId = login.user?.company_id
	const location = useLocation()

	const shouldRenderAlertContainer = (): boolean => {
		return login.user?.email && companyId && location.pathname !== '/'
	}

	useEffect(() => {
		const fetchDataOnAppStart = async () => {
			if (firstLoadingDone) {
				await getLoggedInUser()
				await getSettingsAction()
				await usersAction()
				await getPermissions()
			}
		}
		fetchDataOnAppStart()
	}, [firstLoadingDone])
	useEffect(() => {
		if (presetSearch !== '') {
			setSearch(presetSearch)
			setPresetSearch('')
		}
	}, [search])

	useEffect(() => {
		if (errorMessage !== null) {
			setIsErrorPromptOpened(true)
		}
	}, [errorMessage])

	useEffect(() => {
		if (viewRecord !== null) {
			setIsPreviewOpened(true)
		}
	}, [viewRecord])

	const checkAmountOfSlashes = (string: string) => {
		let result = 0
		for (const letter of string) {
			if (letter === '/') {
				result++
			}
		}
		return result
	}
	useEffect(() => {
		setDarkTheme(initialTheme)
		const handleBackButton = () => {
			//we check the amount of / because if we have more than 1 it means we are going to a page that needs popUpVisible.
			if (checkAmountOfSlashes(window.location.pathname) >= 2) {
				setPopUpVisible(true)
			} else {
				setPopUpVisible(false)
			}
		}

		window.addEventListener('popstate', handleBackButton)

		return () => {
			window.removeEventListener('popstate', handleBackButton)
		}
	}, [])

	useEffect(() => {
		const mediaQuery = window.matchMedia(SYSTEM_DARK_MODE_QUERY)
		const handleSystemThemeChange = (e: MediaQueryListEvent) => {
			// If no user preference, update based on system
			if (!localStorage.getItem(themeLocalStorage)) {
				setDarkTheme(e.matches)
			}
		}

		mediaQuery.addEventListener('change', handleSystemThemeChange)
		return () =>
			mediaQuery.removeEventListener('change', handleSystemThemeChange)
	}, [])

	const canEditAdvertiser = useIsAuthorized(permissionNames.ADD_EDIT_ADVERTISER)
	const canEditOwnAdvertiser = useIsAuthorized(
		permissionNames.EDIT_ADVERTISER_OWNER_ONLY,
	)
	const canEditCampaign = useIsAuthorized(permissionNames.ADD_EDIT_CAMPAIGN)
	const canEditAutoSwitch = useIsAuthorized(permissionNames.ADD_EDIT_AUTOSWITCH)
	const canViewSettings = useIsAuthorized(permissionNames.VIEW_SETTINGS)
	const canEditPermissions = useIsAuthorized(
		permissionNames.ADD_EDIT_USER_PERMISSIONS,
	)
	const addEditPublisher = useIsAuthorized(permissionNames.ADD_EDIT_PUBLISHER)
	return (
		<>
			<Routes>
				<Route element={<CustomThemeProvider darkMode={false} />}>
					<Route
						path='/'
						element={
							<Login
								setFirstLoadingDone={setFirstLoadingDone}
								maintenance={underMaintenance}
							/>
						}
					/>
					<Route path='/resetpassword' element={<ResetPasswordPage />} />
					<Route path='/maintenance' element={<UnderMaintenance />} />
					<Route path='*' element={<NotFound />} />
					<Route path='/notlogged' element={<NotLogged />} />
					<Route path='signupdetails' element={<SignUpDetailsPage />}></Route>
					<Route
						path='/auth'
						element={<Auth setFirstLoadingDone={setFirstLoadingDone} />}
					/>
					<Route path='/redirectlms' element={<LMSRedirect />}/>
				</Route>
				<Route element={<CustomThemeProvider darkMode={darkTheme} />}>
					<Route
						element={
							<LoadingComponent
								loadingFromComponent={loadingFromComponent}
								setLoadingFromComponent={setLoadingFromComponent}
								setUnderMaintenance={setUnderMaintenance}
							/>
						}
					>
						<Route element={shouldRenderAlertContainer() && <AlertContainer />}>
							<Route
								element={
									<NavbarContainer
										isErrorPromptOpened={isErrorPromptOpened}
										setIsErrorPromptOpened={setIsErrorPromptOpened}
										isSuccessPromptOpened={isSuccessPromptOpened}
										setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										successMessage={successMessage}
										setSuccessMessage={setSuccessMessage}
										isWarningPromptOpened={isWarningPromptOpened}
										setIsWarningPromptOpened={setIsWarningPromptOpened}
										isUserMessagePromptOpened={isUserMessagePromptOpened}
										setIsUserMessagePromptOpened={setIsUserMessagePromptOpened}
										setErrorMessage={setErrorMessage}
										setUserMessage={setUserMessage}
										errorMessage={errorMessage}
										userMessage={userMessage}
										PopUpVisible={PopUpVisible}
										setPopUpVisible={setPopUpVisible}
										forcedFilterStatus={forcedFilterStatus}
										setForcedFilterStatus={setForcedFilterStatus}
										isAuthorized={useIsAuthorized}
										setFirstLoadingDone={setFirstLoadingDone}
										search={search}
										setSearch={setSearch}
										presetSearch={presetSearch}
										setPresetSearch={setPresetSearch}
										setLoading={setLoadingFromComponent}
									/>
								}
							>
								{true && (
									<Route
										path={'/news'}
										element={
											<div>
												{/* <FirstLoading loading={firstLoading} report={false} /> */}

												<NewsPage
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.EDIT_COMPANY_DATA) && (
									<Route
										path='/companySettings'
										element={
											<CompanyPersonalPage
												companyType={companyType}
												companyId={companyId}
												setLoading={setLoadingFromComponent}
												setPopUpVisible={setPopUpVisible}
											/>
										}
									/>
								)}
								<Route
									path='/companySettings/edit'
									element={
										<CompanyPersonalPageEdit
											companyType={companyType}
											companyId={companyId}
											setLoading={setLoadingFromComponent}
											setPopUpVisible={setPopUpVisible}
											setErrorMessage={setErrorMessage}
											setIsErrorPromptOpened={setIsErrorPromptOpened}
											setIsSuccessPromptOpened={setIsSuccessPromptOpened}
										/>
									}
								></Route>
								{useIsAuthorized(permissionNames.VIEW_USERS) && (
									<Route
										path='/users'
										element={
											<div>
												<UserPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_ADVERTISER) && (
									<Route
										path='/advertisers'
										element={
											<div>
												<AdvertiserPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													popUpClear={false}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													setForcedFilterStatus={setForcedFilterStatus}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									>
										{(canEditAdvertiser || canEditOwnAdvertiser) && (
											<Route
												path='/advertisers/:id'
												element={
													<div>
														<AdvertiserPage
															setLoading={setLoadingFromComponent}
															errorMessage={errorMessage}
															setErrorMessage={setErrorMessage}
															isErrorPromptOpened={isErrorPromptOpened}
															setIsErrorPromptOpened={setIsErrorPromptOpened}
															isSuccessPromptOpened={isSuccessPromptOpened}
															setIsSuccessPromptOpened={
																setIsSuccessPromptOpened
															}
															viewRecord={viewRecord}
															setViewRecord={setViewRecord}
															popUpClear={false}
															PopUpVisible={PopUpVisible}
															setPopUpVisible={setPopUpVisible}
															setForcedFilterStatus={setForcedFilterStatus}
															search={search}
															setSearch={setSearch}
															presetSearch={presetSearch}
															setPresetSearch={setPresetSearch}
														/>
													</div>
												}
											/>
										)}
									</Route>
								)}
								{(useIsAuthorized(permissionNames.PREVIEW_ADVERTISERS) ||
									canEditOwnAdvertiser) && (
									<Route
										path='/advertisers/:id/preview'
										element={
											<div>
												<AdvertiserPreviewPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													setSuccessMessage={setSuccessMessage}
												/>
											</div>
										}
									/>
								)}

								{useIsAuthorized(permissionNames.ACCESS_TO_APPS) && (
									<Route
										path='/apps'
										element={
											<div>
												<AppsPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									></Route>
								)}

								{useIsAuthorized(permissionNames.VIEW_APP_P360) && (
									<Route
										path='/p360apps'
										element={
											<div>
												<P360Page
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_APP_P360) && (
									<Route
										path='/p360apps/:id'
										element={
											<div>
												<P360Page
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_APP_P360) && (
									<Route
										path='/p360apps/:id/:uuid'
										element={
											<div>
												<P360Page
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.ACCESS_TO_MEDIA_PLAN) && (
									<Route
										path='/mediaplan'
										element={
											<div>
												<MediaPlanPage />
											</div>
										}
									/>
								)}
								{/* {useIsAuthorized('/ACCESS_TO_MEDIA_PLAN') && (
										<Route
											path='/pidhandshake'
											element={
												<div>
													<HandshakePage
														setLoading={setLoadingFromComponent}
														errorMessage={errorMessage}
														setErrorMessage={setErrorMessage}
														isErrorPromptOpened={isErrorPromptOpened}
														setIsErrorPromptOpened={setIsErrorPromptOpened}
														isSuccessPromptOpened={isSuccessPromptOpened}
														setIsSuccessPromptOpened={setIsSuccessPromptOpened}
														PopUpVisible={PopUpVisible}
														setPopUpVisible={setPopUpVisible}
														search={search}
														setSearch={setSearch}
														presetSearch={presetSearch}
														setPresetSearch={setPresetSearch}
													/>
												</div>
											}
										/>
									)} */}
								{useIsAuthorized(permissionNames.VIEW_ADS_PLACEMENT) && (
									<Route
										path='/adsplacement'
										element={
											<div>
												<AdsPlacementPage
													setLoading={setLoadingFromComponent}
													setErrorMessage={setErrorMessage}
													setUserMessage={setUserMessage}
													setIsUserMessagePromptOpened={
														setIsUserMessagePromptOpened
													}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_PUBLISHER) && (
									<Route
										path='/publishers'
										element={
											<div>
												<PublisherPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
													successMessage={successMessage}
													setSuccessMessage={setSuccessMessage}
												/>
											</div>
										}
									>
										{companyType === 'agency' && (
											<Route
												path='/publishers/:id'
												element={
													<div>
														<PublisherPage
															setLoading={setLoadingFromComponent}
															errorMessage={errorMessage}
															setErrorMessage={setErrorMessage}
															isErrorPromptOpened={isErrorPromptOpened}
															setIsErrorPromptOpened={setIsErrorPromptOpened}
															isSuccessPromptOpened={isSuccessPromptOpened}
															setIsSuccessPromptOpened={
																setIsSuccessPromptOpened
															}
															viewRecord={viewRecord}
															setViewRecord={setViewRecord}
															PopUpVisible={PopUpVisible}
															setPopUpVisible={setPopUpVisible}
															search={search}
															setSearch={setSearch}
															presetSearch={presetSearch}
															setPresetSearch={setPresetSearch}
															successMessage={successMessage}
															setSuccessMessage={setSuccessMessage}
														/>
													</div>
												}
											/>
										)}
									</Route>
								)}
								{useIsAuthorized(permissionNames.PREVIEW_PUBLISHERS) && (
									<Route
										path='/publishers/:id/preview'
										element={
											<div>
												<PublisherPreviewPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													setSuccessMessage={setSuccessMessage}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.ADD_EDIT_PUBLISHER) && (
									<Route
										path='/publisherwishlist'
										element={
											<div>
												<WishListTable
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_PUBLISHER) && ( // will we add creators?
									<Route
										path='/creators/'
										element={
											<div>
												<PublisherPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
													successMessage={successMessage}
													setSuccessMessage={setSuccessMessage}
												/>
											</div>
										}
									/>
								)}
								{companyType === 'agency' && addEditPublisher && (
									<Route
										path='/creators/:id'
										element={
											<div>
												<PublisherPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
													successMessage={successMessage}
													setSuccessMessage={setSuccessMessage}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.PREVIEW_PUBLISHERS) && (
									<Route
										path='/creators/:id/preview'
										element={
											<div>
												<CreatorPreviewPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_CAMPAIGN) && (
									<Route
										path='/campaigns'
										element={
											<div>
												<CampaignPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													isEditorOpened={false}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													forcedFilterStatus={forcedFilterStatus}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									>
										{canEditCampaign && (
											<Route
												path='/campaigns/:id'
												element={
													<div>
														<CampaignPage
															setLoading={setLoadingFromComponent}
															errorMessage={errorMessage}
															setErrorMessage={setErrorMessage}
															isErrorPromptOpened={isErrorPromptOpened}
															setIsErrorPromptOpened={setIsErrorPromptOpened}
															isSuccessPromptOpened={isSuccessPromptOpened}
															setIsSuccessPromptOpened={
																setIsSuccessPromptOpened
															}
															isEditorOpened={false}
															PopUpVisible={PopUpVisible}
															setPopUpVisible={setPopUpVisible}
															forcedFilterStatus={forcedFilterStatus}
															search={search}
															setSearch={setSearch}
															presetSearch={presetSearch}
															setPresetSearch={setPresetSearch}
														/>
													</div>
												}
											/>
										)}
									</Route>
								)}
								{useIsAuthorized(permissionNames.PREVIEW_CAMPAIGNS) && (
									<Route
										path='/campaigns/:id/preview'
										element={
											<div>
												<CampaignPreviewPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
												/>
											</div>
										}
									></Route>
								)}
								{useIsAuthorized(permissionNames.VIEW_AGGREGATED) && (
									<Route
										path='/aggregated'
										element={
											<div>
												<AggreagatedReportPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													setErrorMessage={
														setErrorMessage as (message: string | null) => void
													}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													setIsWarningPromptOpened={setIsWarningPromptOpened}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized('VIEW_ADJUST') && ( // not in use!
									<Route
										path='/adjust'
										element={
											<div>
												<AdjustReportsPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													setIsWarningPromptOpened={setIsWarningPromptOpened}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_DASHBOARD) && (
									<Route
										path='/dashboard'
										element={
											<div>
												<Dashboard
													setErrorMessage={setErrorMessage}
													setLoading={setLoadingFromComponent}
													setIsWarningPromptOpened={setIsWarningPromptOpened}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_AUTOSWITCH) && (
									<Route
										path='/autoswitch'
										element={
											<div>
												<AutoSwitchPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													viewRecord={viewRecord}
													setViewRecord={setViewRecord}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
												/>
											</div>
										}
									>
										{canEditAutoSwitch && (
											<Route
												path='/autoswitch/:id'
												element={
													<div>
														<AutoSwitchPage
															setLoading={setLoadingFromComponent}
															errorMessage={errorMessage}
															setErrorMessage={setErrorMessage}
															isErrorPromptOpened={isErrorPromptOpened}
															setIsErrorPromptOpened={setIsErrorPromptOpened}
															isSuccessPromptOpened={isSuccessPromptOpened}
															setIsSuccessPromptOpened={
																setIsSuccessPromptOpened
															}
															viewRecord={viewRecord}
															setViewRecord={setViewRecord}
															PopUpVisible={PopUpVisible}
															setPopUpVisible={setPopUpVisible}
														/>
													</div>
												}
											/>
										)}
									</Route>
								)}
								{useIsAuthorized(permissionNames.VIEW_NETWORK) &&
									companyType === 'agency' && (
										<Route
											path='/network'
											element={
												<div>
													<NetworkPage
														setLoading={setLoadingFromComponent}
														errorMessage={errorMessage}
														setErrorMessage={
															setErrorMessage as (
																message: string | null,
															) => void
														}
														isErrorPromptOpened={isErrorPromptOpened}
														setViewRecord={setViewRecord}
														setIsErrorPromptOpened={setIsErrorPromptOpened}
														setIsWarningPromptOpened={setIsWarningPromptOpened}
													/>
												</div>
											}
										/>
									)}
								{useIsAuthorized(permissionNames.VIEW_RAWDATA) && (
									<Route
										path='/rawdata'
										element={
											<div>
												<RawReportsPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													setViewRecord={setViewRecord}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													setIsWarningPromptOpened={setIsWarningPromptOpened}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_INVALID) && (
									<Route
										path='/invalid'
										element={
											<div>
												<InvalidReportsPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={
														setErrorMessage as (message: string | null) => void
													}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													setIsWarningPromptOpened={setIsWarningPromptOpened}
													viewRecord={viewRecord || null}
													setViewRecord={setViewRecord || null}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_DOCUMENTS) && (
									<Route
										path='/documents'
										element={
											<div>
												<LearningComponent
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													setIsWarningPromptOpened={setIsWarningPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.ADD_EDIT_DOCUMENTS) && (
									<Route
										path='/documents/:id'
										element={
											<div>
												<LearningComponent
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													setIsWarningPromptOpened={setIsWarningPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{(canViewSettings || canEditPermissions) && (
									<Route
										path='/settings'
										element={
											<div>
												<SettingsPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													setIsWarningPromptOpened={setIsWarningPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_LOGS) && (
									<Route
										path='/logs'
										element={
											<LogsPage
												setLoading={setLoadingFromComponent}
												PopUpVisible={PopUpVisible}
												setPopUpVisible={setPopUpVisible}
												search={search}
												setSearch={setSearch}
												presetSearch={presetSearch}
												setPresetSearch={setPresetSearch}
											/>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_DASHBOARD) && ( // MATAM -> no p360 dashboard permission yet!
									<Route
										path='/p360dashboard'
										element={
											<div>
												<P360Dashboard />
											</div>
										}
									/>
								)}
								<Route path='/permissions' element={<div></div>} />
								{useIsAuthorized(permissionNames.VIEW_PASSWORDS) && (
									<Route
										path='/passwords'
										element={
											<div>
												<PasswordsPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{
									<Route
										path='/personalSettings'
										element={
											<div>
												<PersonalSettingsPage
													darkTheme={darkTheme}
													setDarkTheme={setDarkTheme}
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													setIsWarningPromptOpened={setIsWarningPromptOpened}
												/>
											</div>
										}
									/>
								}
								{useIsAuthorized(permissionNames.VIEW_APPSFLYER_ACCOUNTS) && (
									<Route
										path='/appsflyeraccounts'
										element={
											<div>
												<AppsflyerAccountsTable
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_PERSONAL_FINANCE) && (
									<Route
										path='/finance/personal'
										element={
											<div>
												<PersonalPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_CONTROL) && (
									<Route
										path='/finance/control'
										element={
											<div>
												<ControllerPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_MEDIA_BUYING) && (
									<Route
										path='/finance/mediabuying'
										element={
											<div>
												<MediaBuyingPlatform
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_INFLUENCERS) && (
									<Route
										path='/finance/creative'
										element={
											<div>
												<CreatorFinancePage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_INFLUENCERS) && (
									<Route
										path='/finance/creator/:id'
										element={
											<div>
												<CreatorPersonalPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_INFLUENCERS_SUPPLY) && (
									<Route
										path='/finance/creativesupply/'
										element={
											<div>
												<CreatorSupplyPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_GLOBAL) && (
									<Route
										path='/finance/global'
										element={
											<div>
												<GlobalTable
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_DEMAND) && (
									<Route
										path='/finance/demand'
										element={
											<div>
												<DemandPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_DEMAND) && (
									<Route
										path='/finance/demand/:id'
										element={
											<div>
												<DemandInvoiceTable
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_BALANCE_IN) && (
									<Route
										path='/finance/balancein'
										element={
											<div>
												<BalanceInPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_BALANCE_OUT) && (
									<Route
										path='/finance/balanceout'
										element={
											<div>
												<BalanceOutPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_SUPPLY) && (
									<Route
										path='/finance/supply'
										element={
											<div>
												<SupplyPage
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}
								{useIsAuthorized(permissionNames.VIEW_SUPPLY) && (
									<Route
										path='/finance/supply/:id'
										element={
											<div>
												<SupplyPreview
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
													search={search}
													setSearch={setSearch}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
												/>
											</div>
										}
									/>
								)}

								{useIsAuthorized(permissionNames.VIEW_APPSFLYER_ACCOUNTS) && (
									<Route
										path='/appsflyeraccounts'
										element={
											<div>
												<AppsflyerAccountsTable
													setLoading={setLoadingFromComponent}
													errorMessage={errorMessage}
													setErrorMessage={setErrorMessage}
													isErrorPromptOpened={isErrorPromptOpened}
													setIsErrorPromptOpened={setIsErrorPromptOpened}
													isSuccessPromptOpened={isSuccessPromptOpened}
													setIsSuccessPromptOpened={setIsSuccessPromptOpened}
													presetSearch={presetSearch}
													setPresetSearch={setPresetSearch}
													PopUpVisible={PopUpVisible}
													setPopUpVisible={setPopUpVisible}
												/>
											</div>
										}
									/>
								)}
							</Route>
						</Route>
					</Route>
				</Route>
			</Routes>
		</>
	)
}

export default App
