import type {} from '@mui/lab/themeAugmentation'
import {
	createTheme,
	responsiveFontSizes,
	Theme,
	Theme2024,
	ThemeOptions2024,
} from '@mui/material'
import { deepmerge } from '@mui/utils'
declare module '@mui/material/styles' {
	//theme is temporally added here
	export interface Theme {
		colors: {
			white: string
			black: string
			gradient: string
			buttonGradient: string
			lightGradient: string
			activeGradient: string
			disabledGradient: string
			titleGradient: string
			green: string
			gray: string
			lightGray: string
			purple: string
			yellow: string
			red: string
			blue: string
			gradientMenu: string
			gradientLoginPage: string
			base: any
			text: any
			chip: any
			alerts: any
			status: {
				green: string
				grey: string
				red: string
			}
			border: {
				light: string
				dark: string
			}
		}
		animatedItem: any
		shadow: {
			boxShadow: string
			lightShadow: string
		}
		dimentions: {
			drawerWidth: number
			smallerRadius: string
			biggerRadius: string
		}
	}

	export interface ThemeOptions {
		colors?: {
			white?: string
			black?: string
			gradient?: string
			buttonGradient?: string
			lightGradient?: string
			activeGradient?: string
			disabledGradient?: string
			titleGradient?: string
			green?: string
			gray?: string
			lightGray?: string
			purple?: string
			yellow?: string
			red?: string
			blue?: string
			gradientMenu?: string
			gradientLoginPage?: string
			base?: any
			text?: any
		}
		animatedItem?: any
		shadow?: {
			boxShadow?: string
			lightShadow?: string
		}
		dimentions?: {
			drawerWidth?: number
			smallerRadius?: string
			biggerRadius?: string
		}
		border?: {
			light?: string
			dark?: string
		}
	}
	interface Theme {
		font: any
		customComponents: any
	}
	export interface Theme2024 extends Theme {
		colors: any
		font: any
		customComponents: any
		shadow: {
			boxShadow: string
			lightShadow: string
		}
		dimentions: {
			drawerWidth: number
			smallerRadius: string
			biggerRadius: string
		}
	}

	export interface ThemeOptions2024 {
		font?: any
		colors?: any
		shadow?: {
			boxShadow?: string
			lightShadow?: string
		}
		dimentions?: {
			drawerWidth?: number
			smallerRadius?: string
			biggerRadius?: string
		}
	}
}
export const smallerRadius = '6px'
export const biggerRadius = '15px'
export const fontFamily = 'Lato, Arial, sans-serif'
export const font = {
	fontFamily: fontFamily,
	size: {
		titles: '22px',
		buttons: '0.9rem',
		body: '14px',
		caption: '12px',
		small: '10px',
		mainTitles: '35px',
	},
	weight: {
		skinny: '300',
		normal: '400',
		bold: '500',
		mediumBold: '600',
		bolder: '700',
	},
}
export const baseColors = {
	base: {
		white: 'rgba(255,255,255,1)',
		white2: 'rgba(255,255,255,1)',
		black: 'rgba(0, 0, 0, 1)',

		lightGreen: 'rgba(202, 240, 78, 1)',
		grey: 'rgba(251, 251, 251, 1)',
		lightGrey: 'rgba(242, 242, 242, 1)',
		grey2: 'rgba(233, 236, 239, 1)',
		grey3: 'rgba(138, 146, 166, 1)',

		// greys
		grey50: 'rgba(251, 251, 251, 1)', // originally grey
		grey80: 'rgba(241, 241, 241, 1)',
		grey100: 'rgba(242, 242, 242, 1)', // originally lightGrey
		grey150: 'rgba(216, 216, 216, 1)', // originally grey
		grey200: 'rgba(233, 236, 239, 1)', // originally grey2
		grey250: 'rgba(242, 242, 242, 1)',
		grey300: 'rgba(217, 217, 217, 1)',
		grey500: 'rgba(138, 146, 166, 1)', // originally grey3
		grey600: 'rgba(146, 146, 157, 1)',
		grey700: 'rgba(92, 92, 92, 1)',
		grey900: 'rgba(36, 45, 50, 1)',

		// greens
		green20: 'rgba(235, 255, 211, 1)',
		green50: 'rgba(228, 253, 197, 1)',
		green100: 'rgba(202, 240, 78, 1)', // originally lightGreen
		green200: 'rgba(181, 250, 97, 1)',
		green300: 'rgba(186, 220, 72, 1)',
		green400: 'rgba(163, 225, 87, 1)',
		green500: 'rgba(18, 174, 39, 1)',

		//yellows:
		yellow300: 'rgba(218, 174, 31, 1)', //error
		yellow400: 'rgba(248, 208, 79, 1)', //error
		//reds:
		red300: 'rgba(236, 60, 40, 1)', // Light red
		red400: 'rgba(255, 99, 71, 1)', // Slightly darker red
		red600: 'rgba(211, 47, 47, 1)', // Dark red
		//blues:
		blue100: 'rgba(69, 164, 196, 1)',
		blue300: 'rgba(72, 76, 255, 1)', //error
		blue400: 'rgba(66, 114, 246, 1)', //error
	},
	border: {
		light: 'rgba(200, 200, 200, 1)', // Light theme border
		dark: 'rgba(50, 50, 50, 1)', // Dark theme border
	},
	status: {
		green: 'rgba(113, 220, 54, 1)', //active
		grey: 'rgba(214, 214, 214, 1)', //inactive
		red: 'rgba(192, 50, 33, 1)', //error
	},
	chip: {
		textInactive: 'rgba(146, 146, 157, 1)',
		textActive: 'rgba(36, 45, 50, 1)',
		border: 'rgba(186, 220, 72, 1)',
		fill: 'rgba(36, 45, 50, 1)',
	},
	switch: {
		white: 'rgba(255,255,255,1)',
	},
	text: {
		grey: 'rgba(146, 146, 157, 1)',
		inactive: 'rgba(173, 185, 189, 1)', //grey darker
		titles: 'rgba(0, 0, 0, 1)', //black
		general: 'rgba(36, 45, 50, 1)', //black
		colored: 'rgba(98, 219, 123, 1)', //green
	},
	alerts: {
		grey50: 'rgba(251, 251, 251, 1)',
		grey250: 'rgba(242, 242, 242, 1)',
		grey300: 'rgba(217, 217, 217, 1)',
		grey600: 'rgba(146, 146, 157, 1)',
		grey900: 'rgba(36, 45, 50, 1)',
		white: 'rgba(255,255,255,1)',
		green: 'rgba(158, 219, 98, 1)',
	},
}
export const darkBaseColors = {
	base: {
		white: 'rgba(26,26,26,1)',
		white2: 'rgba(138, 138, 138, 1)',
		black: 'rgba(0,0,0,1)',
		lightGreen: 'rgba(216,251,62, 1)',
		grey: 'rgba(4, 4, 4, 1)',
		lightGrey: 'rgba(13, 13, 13, 1)',
		grey2: 'rgba(48, 48, 48, 1)',
		grey3: 'rgba(173,185,189, 1)',

		// greys
		grey50: 'rgba(43,43,43, 1)',
		grey80: 'rgba(43,43,43, 1)',
		grey100: 'rgba(113,131,37, 1)', // originally lightGrey
		grey150: 'rgba(43,43,43, 1)',
		grey200: 'rgba(43,43,43, 1)',
		grey250: 'rgba(61, 61, 61, 1)',
		grey300: 'rgba(173,185,189, 1)',
		grey500: 'rgba(239,239,240, 1)',
		grey600: 'rgba(160, 160, 160, 1)',
		grey700: 'rgba(239,239,240, 1)',
		grey900: 'rgba(239,239,240, 1)',

		// greens
		green20: 'rgba(196, 228, 54, 1)',
		green50: 'rgba(113,131,37, 1)',
		green100: 'rgba(216,251,62, 1)', // originally lightGreen
		green200: 'rgba(216,251,62, 1)',
		green300: 'rgba(216,251,62, 1)',
		green400: 'rgba(216,251,62, 1)',
		green500: 'rgba(216,251,62, 1)',

		// yellows:
		yellow300: 'rgba(218, 174, 31, 1)', //error
		yellow400: 'rgba(248, 208, 79, 1)', //error
		//reds:
		red100: 'rgba(64, 36, 36, 1)',
		red300: 'rgba(198, 51, 34, 1)', //error
		red400: 'red',
		//blues:
		blue100: 'rgba(35, 85, 102, 1)',
		blue300: 'rgba(72, 76, 255, 1)', //error
		blue400: 'rgba(66, 114, 246, 1)', //error
	},
	border: {
		light: 'rgba(61, 61, 61, 1)', // Light theme border in dark mode
		dark: 'rgba(239,239,240, 1)', // Dark theme border in dark mode
	},
	status: {
		green: 'rgba(216,251,62, 1)', //active
		grey: 'rgba(173,185,189, 1)', //inactive
		red: 'rgba(192, 50, 33, 1)', //error
	},
	chip: {
		textInactive: 'rgba(239,239,240, 1)',
		textActive: 'rgba(216,251,62, 1)',
		border: 'rgba(216,251,62, 1)',
		fill: 'rgba(26,26,26,1)',
	},
	switch: {
		white: 'rgba(255,255,255,1)',
	},
	text: {
		grey: 'rgba(131,131,141, 1)',
		inactive: 'rgba(173,185,189, 1)', //grey darker
		titles: 'rgba(239,239,240, 1)', //black
		general: 'rgba(239,239,240, 1)', //black
		colored: 'rgba(216,251,62, 1)', //green
	},
	alerts: {
		grey50: 'rgba(251, 251, 251, 1)',
		grey250: 'rgba(242, 242, 242, 1)',
		grey300: 'rgba(217, 217, 217, 1)',
		grey600: 'rgba(146, 146, 157, 1)',
		grey900: 'rgba(36, 45, 50, 1)',
		white: 'rgba(255,255,255,1)',
		green: 'rgba(158, 219, 98, 1)',
	},
	arrows: {
		white: '#ffffff', //overrides the colors of the arrows in frop list
		black: '#000000',
	},
}

export const customComponents = {
	button: {
		green: baseColors.base.green200,
		iconHeight: '20px',
		iconMargin: '1px 6px 0px 0px',
	},
	input: {
		borderRadius: '4px',
		border: `1px solid ${baseColors.base.grey2}`,
		backgroundColor: baseColors.base.lightGrey,
	},
}
export const darkCustomComponents = {
	button: {
		green: darkBaseColors.base.green200,
		iconHeight: '20px',
		iconMargin: '1px 6px 0px 0px',
	},
	input: {
		borderRadius: '4px',
		border: `1px solid ${darkBaseColors.base.grey2}`,
		backgroundColor: darkBaseColors.base.lightGrey,
	},
}

//green - yellow gradient
export const gradientColors = {
	primary: 'rgba(98, 239, 141, 1)', //green
	secondary: 'rgba(233, 219, 45, 1)', //yellow
	primarySecondaryMix: 'rgba(182, 227, 82, 1)', //mix light
	primarySecondaryMixDarker: 'rgba(152, 231, 105, 1)', //mix darker
}
export const darkGradientColors = {
	primary: 'rgba(98, 239, 141, 1)', //green
	secondary: 'rgba(233, 219, 45, 1)', //yellow
	primarySecondaryMix: 'rgba(182, 227, 82, 1)', //mix light
	primarySecondaryMixDarker: 'rgba(152, 231, 105, 1)', //mix darker
}

export const colors = {
	...baseColors,
	border: {
		light: baseColors.border.light,
	},
	gradientMenu: `conic-gradient(from 170deg, ${gradientColors.primary} 23%, ${gradientColors.secondary} 28%, ${gradientColors.primarySecondaryMix} 30%,${gradientColors.primarySecondaryMixDarker} 74%, ${gradientColors.primary} 80%)`,
	gradientLoginPage: `conic-gradient(from 175deg, ${gradientColors.primary} 0%, ${gradientColors.secondary} 22%,  ${gradientColors.primary}100%)`,
}

export const darkColors = {
	...darkBaseColors,
	border: {
		dark: darkBaseColors.border.dark,
	},
	gradientMenu: `
      conic-gradient(from 170deg,
        ${darkGradientColors.primary} 23%,
        ${darkGradientColors.secondary} 28%,
        ${darkGradientColors.primarySecondaryMix} 30%,
        ${darkGradientColors.primarySecondaryMixDarker} 74%,
        ${darkGradientColors.primary} 80%),
   radial-gradient(circle at center, transparent -2%, rgba(0, 0, 0, 1) 70%)
    `,
	gradientLoginPage: `conic-gradient(from 175deg, ${darkGradientColors.primary} 0%, ${darkGradientColors.secondary} 22%,  ${darkGradientColors.primary}100%)`,
}
