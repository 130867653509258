import { Dispatch } from 'redux'
import { endpoints } from '../../config'
import { downloadEverything } from '../../pages/dashboard/Dashboard'
import AxiosCustom from '../../utils/Axios'
import LmsAxios from '../../utils/LmsAxios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'

export interface updateAppParams {
	app_id: string
	status: boolean
	email: string
	created_by: string
}

export const getPermissions = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.LIST_ALL_PERMISSIONS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_SETTINGS,
				},
			})
			if (data && data.length > 0) {
				dispatch({
					type: ActionType.SETPERMISSIONS,
					payload: data,
				})
			}
		} catch (err) {
			console.error(err)
		}
	}
}

export const getCompanyAgencyAccessById = (companyId: number): any => {
	return async () => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.COMPANY_AGENCY_ACCESS + `/${companyId}`,
			)

			return data ? data.payload : false
		} catch (err) {
			console.error('Error in getCompanyAgencyAccessById: ', err)
		}
	}
}

export const getAllCompanies = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.COMPANIES, {
				headers: {
					'x-permission-name': permissionNames.ACCESS_TO_APPS,
				},
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_ALL_COMPANIES,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const updateCompanyData = (company: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.put(endpoints.COMPANY, { company })
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.UPDATE_COMPANY,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
// ------- APPS OPERATIONS--------
export const getAppsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APP, {
				headers: {
					'x-permission-name': permissionNames.ACCESS_TO_APPS,
				},
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_APP,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const getAppsIcons = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.ICONS, {
				headers: {
					'x-permission-name': permissionNames.ACCESS_TO_APPS,
				},
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_ICONS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}

export const getLMSRoles = (companyId: number, setLmsRoles: any) => {
	return async () => {
		try {
			const {data} = await LmsAxios.get('roles/varys/' + companyId, {
				headers: {
                    'x-company-id': companyId,
                },
			})
			setLmsRoles(data)
			return data
		} catch (err) {
			console.error(err)
		}
	}
}

export const updateAppP360 = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.P360APP, app, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_APP_P360,
				},
			})
			if (data.payload.successful === false) {
				setErrorMessage(data.payload.payload)
				return data.payload.payload
			}
			setSucessMessage(true)
			dispatch({
				type: ActionType.UPDATE_APP_P360,
				payload: app,
			})
			return
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const removeAppP360 = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
	setSucessMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.REMOVEP360APP, app, {
				headers: {
					'x-permission-name': permissionNames.DELETE_APP_P360,
				},
			})
			if (data.payload.successful === false) {
				setErrorMessage(data.payload.payload)
				return data.payload
			}
			setSucessMessage(true)
			dispatch({
				type: ActionType.REMOVE_APP_P360,
				payload: app,
			})
			return
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const insertApp = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.APP, app, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_APP_P360,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.ADD_APP,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const insertP360Rule = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.P360APPADD, app, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_APP_P360,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.INSERT_P360_RULE,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const removeP360Rule = (
	// headers: any,
	obj: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.P360APPREMOVE, app, {
				headers: {
					'x-permission-name': permissionNames.DELETE_APP_P360,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.REMOVE_P360_RULE,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const updateAppInfo = (obj: any, setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.post(endpoints.APPUPDATE, app, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_APP_P360,
				},
			})

			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			dispatch({
				type: ActionType.UPDATE_APP_INFO,
				payload: obj,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const updateAppsAction = (
	obj: updateAppParams,
	row: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const app = { app: obj }
			const { data } = await AxiosCustom.put(endpoints.APP, app) //TODO: MATAN -> what is the permission name for that?
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			row.status = !row.status

			dispatch({
				type: ActionType.UPDATE_APP,
				payload: row,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
// ------- ADVERTISER OPERATIONS--------

// --------- ICONS OPERATION --------

export const deleteIcon = (
	userType: keyof typeof endpoints,
	name: string,
	id: number,
	// headers: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.delete(
				// TODO: MATAN -> what is the permission name for icons?
				`${endpoints[userType]}/${id}/icon`,
			)

			if (data.successful) {
				switch (userType) {
					case 'ADVERTISER':
						dispatch({
							type: ActionType.DELETE_ADVERTISER_ICON,
							payload: { name, iconUrl: null },
						})
						break
					case 'PUBLISHER':
						dispatch({
							type: ActionType.DELETE_PUBLISHER_ICON,
							payload: { name, iconUrl: null },
						})
						break
					case 'CREATOR':
						dispatch({
							type: ActionType.DELETE_CREATOR_ICON,
							payload: { name, iconUrl: null },
						})
						break
					default:
						throw new Error(`Unsupported user type: ${userType}`)
				}

				return data
			} else {
				throw new Error('Failed to delete icon:', data.message)
			}
		} catch (error: any) {
			return { successful: false, payload: error.message }
		}
	}
}
export const uploadIcon = (
	userType: keyof typeof endpoints,
	file: File,
	name: string,
	id: number,
) => {
	return async (dispatch: Dispatch<Action>) => {
		const formData = new FormData()
		formData.append('file', file)
		formData.append(`${userType.toLowerCase()}_name`, name)
		formData.append('id', id.toString())

		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}

		try {
			const response = await AxiosCustom.post(
				`${endpoints[userType]}/uploadIcon`,
				formData,
				config,
			)

			if (response.data.successful) {
				switch (userType) {
					case 'ADVERTISER':
						dispatch({
							type: ActionType.UPDATE_ADVERTISER_ICON,
							payload: { name, iconUrl: response.data?.payload },
						})
						break
					case 'PUBLISHER':
						dispatch({
							type: ActionType.UPDATE_PUBLISHER_ICON,
							payload: { name, iconUrl: response.data?.payload },
						})
						break
					case 'CREATOR':
						dispatch({
							type: ActionType.UPDATE_CREATOR_ICON,
							payload: { name, iconUrl: response.data?.payload },
						})
						break
					default:
						throw new Error(`Unsupported user type: ${userType}`)
				}
				return response.data.payload
			} else {
				throw new Error(`Failed to upload file: ${response.data.message}`)
			}
		} catch (error: any) {
			console.error(`Upload icon error: ${error.message}`)
			return { successful: false, payload: error.message }
		}
	}
}

// ------- PUBLISHER OPERATIONS--------

export const uploadAdFileToStorage = async (payload: any) => {
	const obj = { post: payload }
	try {
		const { data } = await AxiosCustom.post(
			// TODO: MATAN -> what is the permission name for this?
			endpoints.ADS_PLACEMENT + '/file',
			JSON.stringify(obj),
		)
	} catch (err) {
		console.error(err)
	}
}

// ------- SALES PLAN OPERATIONS -------
export const getSalesPlanAppsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.SALES_PLAN) // TODO: MATAN -> sales plan permission names?
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GET_SALES_PLAN_APPS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			window.location.replace(window.location.origin)
		}
	}
}

export const downloadAppListAction = (
	setFileDownload: any,
	rows: any,
	// headers: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const report = { report: rows }
			const { data } = await AxiosCustom.post(
				endpoints.SALES_PLAN + '/download',
				report,
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}

// ------- CAMPAIGN OPERATIONS--------

export const reevaluateAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				// TODO: MATAN -> what is this?
				endpoints.APPSFLYER + '/reevaluate',
				undefined,
			)
			if (!data.successful) {
				//setErrorMessage(data.payload)
				return
			}

			dispatch({
				type: ActionType.REEVALUATE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			setErrorMessage(err)
		}
	}
}

// ------- APPSFLYER OPERATIONS--------
export const getAppsflyerAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APPSFLYER, {
				headers: {
					'x-permission-name': permissionNames.VIEW_AGGREGATED,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.GET_APPSFLYER,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getRefreshNetworkAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dates: { from: any; to: any } = {
				to: new Date().toISOString().split('T')[0],
				from: new Date(),
			}
			dates.from.setDate(new Date().getDate() - 1)
			dates.from = dates.from.toISOString().split('T')[0]
			const { data } = await AxiosCustom.post(endpoints.NETWORK_U, {
				network: {
					from_date: dates.from,
					to_date: dates.to,
				},
				headers: {
					'x-permission-name': permissionNames.VIEW_NETWORK,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.UPDATE_NETWORK,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}
export const getRefreshAction = (setErrorMessage: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER_U,
				undefined,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_NETWORK,
					},
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return
			}
			dispatch({
				type: ActionType.UPDATE_APPSFLYER,
				payload: data.payload,
			})
		} catch (err) {
			setErrorMessage(err)
			console.error(err)
		}
	}
}

export const saveAppsflyerAction = (
	setFileDownload: any,
	// headers: any,
	obj: any,
	user_email: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const dataObj = { save: { report: obj, user_email: user_email } }
			const save = JSON.stringify(dataObj)
			const { data } = await AxiosCustom.post(endpoints.SAVE, save, {
				headers: {
					'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
				},
			})
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.SAVE_APPSFLYER,
				payload: data.payload,
			})
			setFileDownload(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

//-------- ADJUST --------------- // TODO: MATAM -> we have no permissions for Adjust yet

// ------- APP OPERATIONS--------
export const getAppsByAdvertiserAction = (advertiser: string) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APP + '/' + advertiser, {
				headers: {
					'x-permission-name': permissionNames.ACCESS_TO_APPS,
				},
			})

			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_APP,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getEventsByAppsAction = (
	// headers: any,
	appId: string,
	setEventList: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(
				endpoints.APPSFLYER + '/' + appId,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_AGGREGATED,
					},
				},
			)
			// console.log(data)
			if (!data.successful) {
				return
			}

			setEventList(data.payload)
		} catch (err) {
			console.error(err)
		}
	}
}

export function delay(milliseconds: number) {
	return new Promise((resolve) => {
		setTimeout(resolve, milliseconds)
	})
}
//------------NAVIGATION ACTIONS---------------//

export const navigationAction = (data: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			dispatch({
				type: ActionType.NAVIGATION,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

//------------PRESEARCH ACTIONS---------------//

export const presearchAction = (data: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			dispatch({
				type: ActionType.PRESEARCH,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

//------------PRESETS ACTIONS---------------// TODO: MATAN -> what is this and what permissions does this require?
export const getPresetsAction = (user: string) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const url = endpoints.PRESET + (user !== undefined ? '/' + user : '')
			const { data } = await AxiosCustom.get(url)
			dispatch({
				type: ActionType.PRESET,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updatePresetAction = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const preset = { preset: obj }
			const { data } = await AxiosCustom.put(endpoints.PRESET, preset)
			dispatch({
				type: ActionType.PRESET,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getp360aggregated = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.p360aggregated, {
				headers: {
					'x-permission-name': permissionNames.VIEW_AGGREGATED_P360_REPORT,
				},
			})
			dispatch({
				type: ActionType.P360AGGREGATED,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deletePresetAction = (obj: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const preset = { preset: obj }
			const { data } = await AxiosCustom.put(
				endpoints.PRESET + 'delete',
				preset,
			)
			dispatch({
				type: ActionType.PRESET,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

//---------------NETWORK ACTIONS----------------//

export const getUpdatedAtAction = (
	// headers: any,
	report: string,
	setLastRefreshed: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { update: { report: report } }
			const { data } = await AxiosCustom.get(endpoints.UPDATE + '/' + report) // TODO: MATAN -> wtf is ths??
			if (!data.successful) {
				return
			}
			setLastRefreshed(data.payload.updatedAt)
			dispatch({
				type: ActionType.UPDATE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getPasswordsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.PASSWORDS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_PASSWORDS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETPASSWORDS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updatePasswordsAction = (
	// headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(endpoints.PASSWORDS, payload, {
				headers: {
					'x-permission-name': permissionNames.ADD_EDIT_PASSWORDS,
				},
			})
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}

			dispatch({
				type: ActionType.GETPASSWORDS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deletePasswordsAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.PASSWORDS + 'delete',
				payload,
				{
					headers: {
						'x-permission-name': permissionNames.DELETE_PASSWORDS,
					},
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETPASSWORDS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
//===========  invalid

// ==========accounts for appsflyer CRON ====================

export const getAccountsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.APPSFLYER_ACCOUNTS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const putAvatar = async (payload: any) => {
	try {
		const obj = { avatar: payload }
		await AxiosCustom.put(endpoints.AVATAR, obj) // TODO: MATAN -> no permissions for that or any avatar action actually
		await new Promise((resolve) => setTimeout(resolve, 10000))
	} catch (err) {
		console.error(err)
	}
}

const arrayBufferToBase64 = (buffer: any) => {
	var binary = ''
	var bytes = [].slice.call(new Uint8Array(buffer))
	bytes.forEach((b) => (binary += String.fromCharCode(b)))
	return window.btoa(binary)
}
export const getAvatars = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			// const { data } = await AxiosCustom.get(endpoints.AVATAR, {
			// 	headers,
			// })
			let { data } = await AxiosCustom.get(endpoints.USER)
			// console.log(data)
			// data.payload.forEach((user: UserInterface) => {
			// 	user.name = capitalizeFirstLetter(user.name)
			// })
			let imageArray: any = {}
			for (let user of data.payload) {
				// console.log(user)
				if (user.image_uuid) {
					const respomse = await fetch(
						'https://varys-avatar-storage.s3.eu-central-1.amazonaws.com/' +
							user.image_uuid,
					)

					const arrayBuffer = await respomse.arrayBuffer()
					const url = arrayBufferToBase64(arrayBuffer)

					//
					// let srcFnal = Buffer.from(url as any).toString('base64')
					let srcFnal = url
						.replace('data', 'data:')
						.replace('base64', ';base64,')

					imageArray[user.name] = srcFnal
				}
			}
			dispatch({
				type: ActionType.AVATAR,
				payload: imageArray,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const addAccountsAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER_ACCOUNTS,
				payload,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
					},
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const updateAccountsAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.put(
				endpoints.APPSFLYER_ACCOUNTS,
				payload,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
					},
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
export const deleteAccountsAction = (payload: any) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.APPSFLYER_ACCOUNTS + 'delete',
				payload,
				{
					headers: {
						'x-permission-name': permissionNames.VIEW_APPSFLYER_ACCOUNTS,
					},
				},
			)
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GETACCOUNTS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}
// ==========FINANCE ======================

// ==========dashboard ====================

export const getDashboardAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const data = await downloadEverything()
			if (data === undefined) {
				return
			}
			// console.log(data)
			dispatch({
				type: ActionType.GETDASHBOARD,
				payload: data,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

// ==================AutoSwitch====================

export const getAutoSwitchAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.AUTOSWITCH, {
				headers: {
					'x-permission-name': permissionNames.VIEW_AUTOSWITCH,
				},
			})
			// console.log('GET ', { data })
			if (data === undefined) {
				return
			}

			dispatch({
				type: ActionType.GETAUTOSWITCH,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const saveAutoSwitchRuleAction = async (payload: any) => {
	try {
		const pl = { payload: payload }
		const data = await AxiosCustom.post(endpoints.AUTOSWITCH, pl, {
			headers: {
				'x-permission-name': permissionNames.ADD_EDIT_AUTOSWITCH,
			},
		})
		if (data === undefined) {
			return
		}
		return data
		// dispatch({
		// 	type: ActionType.PUTAUTOSWITCH,
		// 	payload: data,
		// })
	} catch (err) {
		console.error(err)
	}
}

export const deleteAutoSwitchRuleAction = async (
	payload: any,
	// headers: any,
) => {
	try {
		const pl = {
			data: payload,
			headers: { 'x-permission-name': permissionNames.ADD_EDIT_AUTOSWITCH },
		}

		const data = await AxiosCustom.delete(endpoints.AUTOSWITCH, pl)
		if (data === undefined) {
			return
		}

		return data
	} catch (err) {
		console.error(err)
	}
}

export const getVersionAction = async (payload: any) => {
	try {
		const pl = { payload: payload }
		const data = await AxiosCustom.post(endpoints.VERSION, pl)
		// console.log('data', data)
		if (data === undefined) {
			return
		}

		return data
	} catch (err) {
		console.error(err)
		return
	}
}

// creators / influencers

//HANDSHAKE
export const insertPidHandshakeAction = (formData: FormData) => {
	return async () => {
		try {
			const updatedHeaders = {
				// ...headers,
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
			}
			const { data } = await AxiosCustom.post(
				endpoints.HANDSHAKE + '/updatelist', // TODO: MATAN -> handshake permissions?
				formData,
				{
					headers: updatedHeaders,
				},
			)
			if (!data.successful) {
				return data.payload
			}
			// dispatch({
			// 	type: ActionType.INSERT_CREATORS,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}

export const getHandshakeAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.HANDSHAKE)
			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.GETHANDSHAKE,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
			// if (
			// 	window.location.pathname !== '/notlogged' &&
			// 	window.location.pathname !== '/'
			// ) {
			// 	window.location.replace(window.location.origin + '/notlogged')
			// }
		}
	}
}
export const updateHandshake = (
	headers: any,
	payload: any,
	setErrorMessage: any,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.post(
				endpoints.HANDSHAKE,
				{ handshake: payload },
				{
					headers,
				},
			)
			if (!data.successful) {
				setErrorMessage(data.payload)
				return data.payload
			}
			// console.log('update', data)
			// dispatch({
			// 	type: ActionType.GETHANDSHAKE,
			// 	payload: data.payload,
			// })
		} catch (err) {
			console.error(err)
		}
	}
}
