import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import './../finance.css'
import { PageWrapper, TableWrapper } from '../../components/reusableComponents'
import { ThemeProvider } from '@emotion/react'
import { useActions, useFinanceActions } from '../../../hooks/useActions'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import {
	generateRowsWithIds,
	searchFor,
} from '../../../utils/helpers/tableHelper'
import {
	GridColDef,
	GridRowModesModel,
	GridRowModes,
	GridActionsCellItem,
	GridEventListener,
	GridRowEditStopReasons,
} from '@mui/x-data-grid-pro'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Checkbox, Typography, useTheme } from '@mui/material'
import { ReactComponent as EditIcon } from '../../../assets/svg/edit-icon.svg'
import { ReactComponent as MarkEmailReadIcon } from '../../../assets/svg/email-history-icon.svg'
import { ReactComponent as TableRowsIcon } from '../../../assets/svg/data-icon.svg'
import {
	extractPaymentLimit,
	formatDateToMonth,
	formatFinancePaymentDate,
	getApplicableRules,
	getMonthAbbreviation,
	getYear,
	handleFinanceStatusColor,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../../utils/helpers/helperFuncs'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import saveAs from 'file-saver'
import { useNavigate } from 'react-router'
import moment from 'moment'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import Guidelines from './../Guidelines'
import { TableComponent } from '../../components/TableComponents'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../../state/actions'
import { UploadFinanceImage } from '../UploadFinanceImage'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { SubmitButton } from '../../components/Buttons'
import { UploadInstructionsFinance } from '../UploadInstructionsFinance'
import { StyledTextAreaField } from '../../components/Inputs'
import { useIsAuthorized } from '../../../hooks/useIsAuthorized'
import { permissionNames } from '../../../utils/helpers/permissionsHelper'
import { HeaderFilterComponent } from '../../components/TableHelperComponents'

//In finance we have 4 pages:
// - Personal: Where the user is able to upload his excel
// - Global: Where the user can see all the data of the company
// - Supply: Where the user can see the data ordered by publisher
// - Demand: Where the user can see the data ordered by advertiser
// The idea of supply page is that the data is ordered by publisher. The publisher manager will be able to open one publisher
// in order to see all the data related to that publisher. Then he will be able to send an email to the publisher
// it could be a status email or a final numbers email. Both will update the history.
//status will only be allowed if there's an on hold while final numbers will only be allowed if no on hold value is registered.
// user is able to edit the rows in order to add invoice numbers and payment dates.
//supply page have different components. For the email templates, the preview page and the status page.
const CreatorSupplyPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const { login, finance, publisher, users, settings, creator } =
		useTypedSelector((state) => state)

	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_FINANCE),
		editCreatorInvoice: useIsAuthorized(permissionNames.EDIT_CREATOR_INVOICE),
		editSupplyInvoice: useIsAuthorized(permissionNames.EDIT_SUPPLY_INVOICE),
	}
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const {
		getFinanceData,
		uploadFinancePublisher,
		downloadFinance,
		saveProofFinance,
	} = useFinanceActions()
	const sessionKey = 'filters-finance-creator-supply'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [filteredFinance, setFilteredFinance] = useState<any>([])
	const rowCount = 20
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [fileDownload, setFileDownload] = useState()
	const [amountOfEmailRows, setAmountOfEmailRows] = useState(0)
	const [tableRows, setTableRows] = useState<any>(
		generateRowsWithIds(
			generateRowsByPublisher(
				filteredFinance,
				publisher.publisher,
				'',
				amountOfEmailRows,
				setAmountOfEmailRows,
			),
		),
	)
	const [openFilter, setOpenFilter] = useState(false)
	const [infoOpened, setInfoOpened] = useState(false)
	const [financeFilterStatus, setFinanceFilterStatus] = useState(
		sessionFilters.financeFilterStatus ? sessionFilters.financeFilterStatus : 0,
	)
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					publisher: [],
					publisher_manager: [],
					due_date: [],
			  },
	)
	const dateFilters = ['due_date']
	const [dateFilter, setDateFilter] = useState<any>({ due_date: [] })
	const [openDateFilter, setOpenDateFilter] = useState(false)
	const [applyFilter, setApplyFilter] = useState(false)
	const [forceRefresh, setForceRefresh] = useState(false)
	const [openImagePreview, setOpenImagePreview] = useState(false)

	const [proofToView, setProofToView] = useState<any>()
	const [selectedRow, setSelectedRow] = useState<any>(null)
	const [invoiceInstructions, setInvoiceInstructions] = useState<any>('')
	const [openInstructionsPreview, setOpenInstructionsPreview] = useState(false)
	const [uploadInvoiceTriggered, setUploadInvoiceTriggered] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		publisher: [],
		publisher_manager: [],
	})
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const [monthFilterArray, setMonthFilterArray] = useState<any>(
		sessionFilters.monthFilterArray ? sessionFilters.monthFilterArray : [],
	)
	const [yearFilterArray, setYearFilterArray] = useState<any>(
		sessionFilters.yearFilterArray ? sessionFilters.yearFilterArray : [],
	)
	const [columns, setColumns] = useState<GridColDef[]>([])

	const [dataFetched, setDataFetched] = useState(false)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const hasFetched = useRef(false)

	const fetchData = async () => {
		let i = 0
		let result
		do {
			result = await getFinanceData(
				'creatorsupply',
				props.errorMessage,
				login.user.email,
				200,
				i,
			)
			i++
			// console.log(result)
			setDataFetched(true)
		} while (
			result &&
			result.length > 0 &&
			window.location.pathname.endsWith('creativesupply')
		)
	}
	useEffect(() => {
		dispatch(emptyFinance())
		//this needs to be added for the StrictMode. Otherwise data is duplicated.
		if (hasFetched.current) {
			return
		}
		hasFetched.current = true
		fetchData()
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(sessionFilters.monthFilterArray)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(sessionFilters.yearFilterArray)
			sessionFilters.financeFilterStatus &&
				setFinanceFilterStatus(sessionFilters.financeFilterStatus)
		}
	}, [])

	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				monthFilterArray,
				yearFilterArray,

				financeFilterStatus,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [applyFilter, monthFilterArray, yearFilterArray, financeFilterStatus])

	useEffect(() => {
		setFilteredFinance(Array.isArray(finance.finance) ? finance.finance : [])
	}, [finance.finance])
	useEffect(() => {
		if (tableRows[0]) {
			setAmountOfEmailRows(tableRows[0].maxAmountOfEmails)
		}
		if (dataFetched) {
			setOptionsFilter({
				publisher: new Set(
					tableRows
						.map((element: any) => element.publisher)
						.filter((el: any) => el.length > 0),
				),
				publisher_manager: new Set(
					tableRows
						.map((element: any) => element.publisher_manager)
						.filter((el: any) => el && el.length > 0),
				),
			})
		}
	}, [tableRows])

	const handleUploadProof = (row: any, image: any) => {
		const formData = new FormData()
		formData.append('finance', image)
		const mainUuid = row.row.uuid.split('-invoice-')[0]
		// let mainRow = {}
		// for (const currentRow of tableRows) {
		// 	// if (currentRow.mainInvoice && currentRow.uuid === mainUuid) {
		// 	if (currentRow.mainInvoice) {
		// 		mainRow = { ...currentRow }
		// 		break
		// 	}
		// }
		const data = {
			row: row.row,
			email: login.user.email,
			main_row: row.row,
			type: 'publisher',
		}
		formData.append('data', JSON.stringify(data))
		saveProofFinance(
			formData,
			() => setForceRefresh(!forceRefresh),
			props.setErrorMessage,
			props.setLoading,
		)
	}

	//same logic for filtering. The main difference is that in this table we will render the tableRow grouped by publishers.
	//we will have only 1 row for each publisher.
	//the main difference is that we check the possibleFinanceFilter INSIDE the function that generates the rows.
	const possibleFinanceFilter = useMemo(() => ['All', 'Paid', 'Unpaid'], [])

	let columnsToShow: GridColDef[] = [
		{
			field: 'advertiser',
			headerName: 'Advertiser',
			width: rowWidth * 1.4,
			editable: false,
			type: 'singleSelect',

			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'campaign',
			headerName: 'Campaign',
			width: rowWidth * 1.4,
			editable: false,
			type: 'singleSelect',

			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'creator_id_finance',
			headerName: 'Finance ID',
			width: rowWidth * 1,
			editable: false,
			type: 'singleSelect',

			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'publisher',
			headerName: 'Name',
			width: rowWidth * 1.4,
			editable: false,
			type: 'singleSelect',
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Name'}
						width={rowWidth * 1.4}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'publisher_manager',
			headerName: 'PM',
			width: rowWidth * 1,
			editable: false,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'PM'}
						width={rowWidth * 2.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher_manager'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			editable: false,
			width: rowWidth * 1,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'final_amount',
			headerName: 'Amount',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			editable: false,
			renderCell: ({ value }) => {
				return <span>{Number(value).toFixed(2)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'finance-final-cell is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'final_with_vat',
			headerName: 'Amount inc. VAT',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			editable: false,
			renderCell: ({ value }) => {
				return <span>{Number(value).toFixed(2)}</span>
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},

		{
			field: 'invoice_number',
			headerName: 'Invoice Number',
			width: rowWidth * 0.8,
			align: 'left',
			headerAlign: 'left',
			editable: permissions.edit ? true : false,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'proof_publisher',
			headerName: '',
			width: rowWidth * 0.5,
			align: 'center',
			headerAlign: 'center',
			editable: false,
			renderCell: (row) => {
				let mainRow = {}
				// if (row.row.uuid) {
				// 	const mainUuid = row.row.uuid.split('-invoice-')[0]
				// 	for (const savedRow of tableRows) {
				// 		// if (savedRow.mainInvoice && savedRow.uuid === mainUuid) {
				// 		// 	mainRow = { ...savedRow }
				// 		// }
				// 		if (savedRow.mainInvoice) {
				// 			mainRow = { ...savedRow }
				// 			break
				// 		}
				// 	}
				// } else {
				mainRow = row.row
				// }
				return (
					<UploadFinanceImage
						row={row}
						mainRow={mainRow}
						handleUploadProof={handleUploadProof}
						setProofToView={setProofToView}
						setOpenImagePreview={setOpenImagePreview}
						setErrorMessage={props.setErrorMessage}
						setLoading={props.setLoading}
						permissions={false}
						forceRefresh={forceRefresh}
						setForceRefresh={setForceRefresh}
						type={'publisher'}
					></UploadFinanceImage>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'invoice_number_publisher_added_time',
			headerName: 'Received Date',
			width: rowWidth * 1,
			type: 'date',
			align: 'left',
			headerAlign: 'left',
			editable: permissions.edit ? true : false,
			valueGetter: ({ value }) => {
				if (typeof value === 'string' && value.includes('/')) {
					return moment(value, 'DD/MM/YYYY').toDate()
				} else {
					// return moment(value).toDate()
					return ''
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: (row) => {
				if (row.value) {
					const date = formatFinancePaymentDate(row.value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				} else {
					return <></>
				}
			},
			sortComparator: (str1, str2) => {
				return sortDates(str2, str1)
			},
		},
		{
			field: 'due_date',
			headerName: 'Due Date',
			width: rowWidth * 1,
			type: 'date',
			align: 'left',
			headerAlign: 'left',
			editable: false,
			valueGetter: ({ value }) => {
				if (!value) {
					return ''
				}
				if (typeof value === 'string' && value.includes('/')) {
					return moment(value, 'DD/MM/YYYY').toDate()
				} else {
					return moment(value).toDate()
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: (row) => {
				if (row.value) {
					const date = formatFinancePaymentDate(row.value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				} else {
					return <></>
				}
			},
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Due Date'}
						width={rowWidth * 2}
						openFilter={openDateFilter}
						setOpenFilter={setOpenDateFilter}
						field={'due_date'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			sortComparator: (str1, str2) => {
				return sortDates(str2, str1)
			},
		},
		{
			field: 'paid_amount_publisher',
			headerName: 'Paid Amount',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			editable:
				permissions.editCreatorInvoice && permissions.edit ? true : false,
			type: 'number',
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				if (!value) {
					return <></>
				}
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
		},
		{
			field: 'payment',
			headerName: 'Payment Date',
			width: rowWidth * 1,
			align: 'left',
			headerAlign: 'left',
			editable:
				permissions.editCreatorInvoice && permissions.edit ? true : false,
			type: 'date',
			valueGetter: ({ value }) => {
				if (!value) {
					return ''
				}
				if (typeof value === 'string' && value.includes('/')) {
					return moment(value, 'DD/MM/YYYY').toDate()
				} else {
					return moment(value).toDate()
				}
			},
			renderCell: ({ value }) => {
				if (value) {
					const date = formatFinancePaymentDate(value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			sortComparator: (str1, str2) => {
				return sortDates(str1, str2)
			},
		},
		{
			field: 'bank_fees_publisher',
			headerName: 'Bank Fees',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			editable:
				permissions.editCreatorInvoice && permissions.edit ? true : false,
			type: 'number',
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				if (!value) {
					return <></>
				}
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
		},
		{
			field: 'payment_method_publisher',
			headerName: 'Payment Method',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			type: 'singleSelect',
			editable:
				permissions.editCreatorInvoice && permissions.edit ? true : false,
			valueOptions: settings.settings.paymentMethod,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'invoice_instructions_publisher',
			headerName: 'Notes',
			width: rowWidth * 0.7,
			align: 'center',
			headerAlign: 'center',
			editable: false,
			renderCell: (row) => {
				let mainRow = {}
				if (row.row.uuid) {
					const mainUuid = row.row.uuid.split('-invoice-')[0]
					for (const savedRow of tableRows) {
						if (savedRow.mainInvoice && savedRow.uuid === mainUuid) {
							mainRow = { ...savedRow }
							break
						}
					}
				} else {
					mainRow = row.row
				}
				return (
					<UploadInstructionsFinance
						row={row}
						mainRow={mainRow}
						setOpenInstructionsPreview={setOpenInstructionsPreview}
						setErrorMessage={props.setErrorMessage}
						setLoading={props.setLoading}
						invoiceInstructions={invoiceInstructions}
						setInvoiceInstructions={setInvoiceInstructions}
						setUploadInvoiceTriggered={setUploadInvoiceTriggered}
						uploadInvoiceTriggered={uploadInvoiceTriggered}
						selectedRow={selectedRow}
						setSelectedRow={setSelectedRow}
						refreshFunc={() => setForceRefresh(!forceRefresh)}
						type={'publisher'}
					></UploadInstructionsFinance>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'saved_proof_publisher',
			headerName: 'Invoice Saved',
			width: rowWidth * 0.7,
			align: 'left',
			headerAlign: 'left',
			type: 'boolean',
			editable:
				permissions.editCreatorInvoice && permissions.edit ? true : false,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			getActions: (row) => {
				const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										cursor: 'pointer',
										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<CheckIcon fontSize={'inherit'} />
								</div>
							}
							label='Save'
							sx={{
								color: 'primary.main',
							}}
							onClick={handleSaveClick(row.id)}
						/>,
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										cursor: 'pointer',
										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<ClearIcon fontSize={'inherit'} />
								</div>
							}
							label='Cancel'
							className='textPrimary'
							onClick={handleCancelClick(row.id)}
							color='inherit'
						/>,
					]
				}

				return actions(row)
			},
		},
	]
	if (dataFetched) {
		props.setLoading(false)
	}

	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, finance.finance, columnsToShow)
			let maximumEmails = 0
			for (const row of searched) {
				if (row.last_send && row.last_send.length > maximumEmails) {
					maximumEmails = row.last_send.length
				}
			}
			if (searched.length > 0) {
				searched[0].maxAmountOfEmails = maximumEmails
			}
			filtered = searched
		} else {
			filtered = finance.finance
		}
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true

					foundByKey = customFilter[key].includes(data[key])
					if (key === 'publisher_manager') {
						const values = customFilter[key].map((el: string) =>
							el.toLowerCase(),
						)
						const userFound = users.users.find((e: any) => {
							return (
								values.includes(e.name.toLowerCase()) &&
								data[key].toLowerCase() === e.name.toLowerCase()
							)
						})
						foundByKey = !!userFound
					}
					if (dateFilters.includes(key)) {
						if (data[key]) {
							const dateFrom = moment(customFilter[key][0]['$d'])
							const dateTo = moment(customFilter[key][1]['$d'])
							let value
							if (typeof data[key] === 'string' && data[key].includes('/')) {
								value = moment(data[key], 'DD/MM/YYYY')
							} else {
								value = moment(data[key])
							}
							if (value.isBetween(dateFrom, dateTo, undefined, '[]')) {
								foundByKey = true
							}
						}
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}

		//this handles the year
		if (yearFilterArray.length !== 0) {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				// console.log(year, yearFilterArray)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = filteredData
		}
		//this handles the months
		if (monthFilterArray.length !== 0) {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = filteredData
		}
		const publisherRows = generateRowsByPublisher(
			arr,
			publisher.publisher,
			possibleFinanceFilter[financeFilterStatus],
			amountOfEmailRows,
			setAmountOfEmailRows,
		)
		arr = generateRowsWithIds(publisherRows)
		setTableRows(arr)
		setUnfiltered(arr)
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [
		financeFilterStatus,
		props.search,
		monthFilterArray,
		yearFilterArray,
		applyFilter,
		filteredFinance,
	])
	const handleApplyFilter = useCallback(() => {
		setApplyFilter(!applyFilter)
	}, [applyFilter])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])
	//logic behind the edit for the rows.
	const handleEditClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}
	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}
	// const handlePreviewClick = (id: any) => async () => {
	// 	navigate('/finance/supply/' + id)
	// }
	const handleDownloadFinance = useCallback(() => {
		downloadFinance(
			tableRows,
			'creator-supply',
			setFileDownload,
			props.setErrorMessage,
		)
	}, [tableRows])

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}

	const actions = (row: any) => {
		const result = []
		if (permissions.editSupplyInvoice && permissions.edit ? true : false) {
			result.push(
				<GridActionsCellItem
					icon={
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								border: 'none',
								height: '28px',
								width: '28px',
								fontSize: '16px',
								cursor: 'pointer',
							}}
							className={'finance-action-button'}
						>
							<EditIcon
								style={{ color: theme.colors.text.titles, scale: '0.8' }}
								className={'action-button'}
							/>
						</div>
					}
					label='Edit'
					className='textPrimary'
					onClick={handleEditClick(row.id)}
					color='inherit'
				/>,
			)
		}

		return result
	}

	useEffect(() => {
		console.log(tableRows)
	}, [tableRows])
	//we use this to handle the saving. It will trigger a save that will update all the rows that contain the same publisher and
	//period (month + year).
	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		try {
			if (newRow.due_date) {
				const dueDateMoment = moment(
					newRow.due_date,
					moment.ISO_8601,
					true,
				).isValid()
					? moment(newRow.due_date)
					: moment(newRow.due_date, 'DD/MM/YYYY')

				// Format the date to DD/MM/YYYY
				newRow.due_date = dueDateMoment.format('DD/MM/YYYY')
			}
			if (newRow.invoice_number_publisher_added_time) {
				const dueDateMoment = moment(
					newRow.invoice_number_publisher_added_time,
					moment.ISO_8601,
					true,
				).isValid()
					? moment(newRow.invoice_number_publisher_added_time)
					: moment(newRow.invoice_number_publisher_added_time, 'DD/MM/YYYY')

				// Format the date to DD/MM/YYYY
				newRow.invoice_number_publisher_added_time =
					dueDateMoment.format('DD/MM/YYYY')
			}
			// console.log('ayment', newRow.payment)
			// if (newRow.invoice_number && !newRow.due_date) {
			let currentDate = newRow.invoice_number_publisher_added_time
				? moment(newRow.invoice_number_publisher_added_time, 'DD/MM/YYYY')
				: moment()
			let isPrepayment = false
			const selectedCreator = creator.creator.filter(
				(creat: any) =>
					creat.name.toLowerCase().trim() ===
					newRow.publisher.toLowerCase().trim(),
			)
			// console.log(selectedCreator[0])
			let paymentTerm = 'Net30'
			if (selectedCreator.length > 0 && !!selectedCreator[0].payment_term) {
				paymentTerm = selectedCreator[0].payment_term
			}
			if (paymentTerm) {
				if (paymentTerm.startsWith('Net')) {
					const numberOfDays = extractPaymentLimit(paymentTerm)
					// console.log(paymentTerm, numberOfDays)
					// Add the number of days to the current date
					currentDate = currentDate.add(parseInt(numberOfDays) + 1, 'days')
				} else if (paymentTerm === 'Prepayment') {
					isPrepayment = true
				}
			} else {
				// By default we use Net45 which would be 45 + 1
				currentDate = currentDate.add(46, 'days')
			}

			// if (!newRow.due_date) {
			newRow.due_date = isPrepayment ? '-' : currentDate.format('DD/MM/YYYY')
			// }
			if (!newRow.invoice_number_publisher_added_time) {
				newRow.invoice_number_publisher_added_time =
					moment().format('DD/MM/YYYY')
			}
			// }
			// if (!newRow.invoice_number && newRow.due_date) {
			// 	newRow.due_date = ''
			// }
			// if (
			// 	!newRow.invoice_number &&
			// 	newRow.invoice_number_publisher_added_time
			// ) {
			// 	newRow.invoice_number_publisher_added_time = ''
			// }
			const updatedRow = { ...newRow, isNew: false }
			const failed = await uploadFinancePublisher(
				newRow,
				'creator',
				login.user.email,
				props.setErrorMessage,
			)
			if (!failed) {
				if (updatedRow.saved_proof_publisher) {
					updatedRow.proof_publisher = ''
				}
				setTableRows(
					tableRows.map((row: any) =>
						row.uuid === newRow.uuid ? updatedRow : row,
					),
				)
			} else {
				const notUpdatedData = [...tableRows]
				setTableRows(notUpdatedData)
			}
			return updatedRow
		} catch (error) {
			console.error(error)
		}
	}
	//prevent the editing with double click.
	const handleDoubleCellClick = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params, event) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params, event) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])
	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		return ''
	}

	const guidelines = [
		{
			title: 'Campaign',
			explanation: 'Name of the Campaign. \nExample: Azar UGC.',
		},
		{
			title: 'Advertiser',
			explanation:
				'Name of your advertiser written in the same way that is written in Varys - Demand - Advertiser.',
		},
		{
			title: 'Creator Finance ID',
			explanation: 'Finance ID associated to the creator.',
		},
		{
			title: 'Creator',
			explanation: `Creator's name. It is not necessary to add the creator name, it will come automatically from the system when you write the creator ID.`,
		},
		{
			title: 'PM',
			explanation: 'Name of PM of that Creator. \nExample: Sophia Gil.',
		},
		{
			title: 'Period',
			explanation:
				'Activity Month + Year. \nFor Example, Jan/2024, Feb/2024, Mar/2024, Apr/2024, May/2024, Jun/2024, Jul/2024, Aug/2024.',
		},
		{
			title: 'Amount',
			explanation:
				'What we will pay to the creator for that campaign. Cost-Deduction.',
		},
		{
			title: 'Invoice Number',
			explanation: 'Number of the invoice issued by the creator.',
		},
		{
			title: 'Received Date',
			explanation: 'When we receive the invoice from the creator.',
		},
		{
			title: 'Due Date',
			explanation:
				'Based on the payment term specified on the creator database, when that invoice will be overdue.',
		},
		{
			title: 'Payment Date',
			explanation: 'When we make the payment to the creator of that invoice.',
		},
		{
			title: 'Bank Fees',
			explanation: 'Bank fees of the invoice after the payment was made.',
		},
		{
			title: 'Payment Method',
			explanation: 'Payment Method used in order to pay the invoice.',
		},
		{
			title: 'Notes',
			explanation: 'Any specific note associated to the invoice.',
		},
		{
			title: 'Invoice Saved',
			explanation: 'Once saved, the pdf image will be removed from the system.',
		},
	]

	return (
		<ThemeProvider theme={theme}>
			{infoOpened && (
				<div
					style={{
						background: 'rgba(0,0,0,0.4)',
						width: '100%',
						height: '110%',
						zIndex: '20',
						display: 'flex',
						position: 'absolute',
						top: '0',
						left: '0',
					}}
				></div>
			)}
			{openImagePreview && (
				<div
					style={{
						width: '75%',
						height: '75%',
						background: theme.colors.base.white,
						position: 'absolute',
						zIndex: '14',
						left: '10%',
						border: '1px solid rgba(0,0,0,0.2)',
						borderRadius: '12px',
						fontSize: '16px',
						color: 'black',
						padding: '40px',
						display: 'flex',
						overflowY: 'auto',
						boxShadow: '2px 2px 2px 3px rgba(206,207,208,1)',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					}}
					key={'testdocviewer'}
				>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							border: 'none',
							borderRadius: '100px',
							height: '28px',
							width: '28px',
							fontSize: '16px',
							position: 'absolute',
							left: '94%',
							top: '2%',
							cursor: 'pointer',
							color: theme.colors.text.titles,
						}}
						onClick={() => {
							setOpenImagePreview(false)
							setProofToView(null)
						}}
					>
						<ClearIcon fontSize={'inherit'} />
					</button>
					<div
						style={{
							height: '100%',
							width: '100%',
							display: 'flex',
							overflowY: 'auto',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<DocViewer
							documents={[{ uri: proofToView }]}
							pluginRenderers={DocViewerRenderers}
						></DocViewer>
					</div>
					<SubmitButton
						onClick={() => {
							setOpenImagePreview(false)
							setProofToView(null)
						}}
						style={{
							width: 120,
							height: 30,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						Close
					</SubmitButton>
				</div>
			)}
			{openInstructionsPreview && (
				<div
					style={{
						width: '45%',
						height: '45%',
						background: theme.colors.base.white,
						position: 'absolute',
						zIndex: '14',
						left: '20%',
						border: '1px solid rgba(0,0,0,0.2)',
						borderRadius: '12px',
						fontSize: '16px',
						color: 'black',
						padding: '40px',
						display: 'flex',
						overflowY: 'auto',
						boxShadow: '2px 2px 2px 3px rgba(206,207,208,1)',
						justifyContent: 'space-around',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<button
						style={{
							display: 'flex',
							alignItems: 'center',
							border: 'none',
							borderRadius: '100px',
							height: '28px',
							width: '28px',
							fontSize: '16px',
							position: 'absolute',
							left: '94%',
							top: '2%',
							cursor: 'pointer',
						}}
						onClick={() => {
							setOpenInstructionsPreview(false)
							setInvoiceInstructions('')
						}}
					>
						<ClearIcon fontSize={'inherit'} />
					</button>
					<div style={{ width: '90%', marginLeft: 60 }}>
						<StyledTextAreaField
							label={'Comments'}
							value={invoiceInstructions}
							onChange={setInvoiceInstructions}
						/>
					</div>
					<div
						style={{
							display: 'flex',
							width: '50%',
							justifyContent: 'space-around',
						}}
					>
						<SubmitButton
							onClick={() => {
								setOpenInstructionsPreview(false)
								setUploadInvoiceTriggered(true)
							}}
							style={{
								width: 120,
								height: 30,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							Update
						</SubmitButton>
					</div>
				</div>
			)}
			<PageWrapper>
				{infoOpened && (
					<Guidelines
						data={guidelines}
						closeFunc={() => setInfoOpened(false)}
					></Guidelines>
				)}
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columnsToShow}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFilterStatus}
						setFilterStatus={setFinanceFilterStatus}
						rowHeight={'auto'}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						downloadTemplate={handleDownloadFinance}
						getRowClassName={handleRowStyle}
						customSx={{
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
							'& .MuiDataGrid-columnHeaderTitle': {
								lineHeight: '18px',
								whiteSpace: 'pre-line',
							},
						}}
						searchWithTimer={true}
						infoOpened={infoOpened}
						setInfoOpened={setInfoOpened}
						extraBolFunctionLabel={useMemo(
							() => ({
								true: 'Supply Data',
								false: 'Email History',
								iconTrue: <TableRowsIcon style={{ marginRight: 0 }} />,
								iconFalse: <MarkEmailReadIcon style={{ marginRight: 0 }} />,
							}),
							[],
						)}
						dateFilter={dateFilter}
						setDateFilter={setDateFilter}
						dateFilterHandler={openDateFilter}
						setDateFilterHandler={setOpenDateFilter}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

//this is the function that generates the rows by publishers. Basically we save the publisher name the first time it's read
//(generating a new row) and if we found the publisher then we just update its values instead of generating a new row.
const generateRowsByPublisher = (
	inputRows: any,
	publisherList: any,
	filterStatus: string = '',
	amountOfEmailRows: number,
	setAmountOfEmailRows: any,
) => {
	let result = inputRows
	// const rows = [...inputRows]
	// const result = []
	// const alreadyIn: string[] = []
	// for (const row of rows) {
	// 	let newRow: any = {}
	// 	let period = row.month
	// 	if (row.roll_over && row.roll_over_month) {
	// 		period = row.roll_over_month
	// 	}
	// 	if (
	// 		!alreadyIn.includes(
	// 			row.publisher + moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 		)
	// 	) {
	// 		const currentPublisher = publisherList.filter(
	// 			(pub: any) => pub.publisher_name === row.publisher,
	// 		)
	// 		if (currentPublisher[0]) {
	// 			newRow.publisher = row.publisher
	// 			newRow.publisher_manager = row.publisher_manager
	// 			newRow.platform = ''
	// 			newRow.uuid =
	// 				row.uuid +
	// 				'-supply-' +
	// 				moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY')
	// 			newRow.month = period
	// 			newRow.approved_conversions = row.approved_conversions
	// 			newRow.deduction_amount = row.deduction_amount
	// 			newRow.invoice_number = row.invoice_number
	// 			let amountOfEmails = 0
	// 			for (let i = 0; i < row.last_send.length; i++) {
	// 				const sentNumber = i + 1
	// 				if (row.final_numbers_sent && i === row.last_send.length - 1) {
	// 					newRow[`final_numbers_send` as keyof typeof newRow] =
	// 						row.last_send[i]
	// 					amountOfEmails++
	// 				} else {
	// 					if (
	// 						!row.last_send[i - 1] ||
	// 						moment(row.last_send[i]).format('DD/MM/YYYY') !==
	// 							moment(row.last_send[i - 1]).format('DD/MM/YYYY')
	// 					) {
	// 						newRow[`last_send_${sentNumber}` as keyof typeof newRow] =
	// 							row.last_send[i]
	// 						amountOfEmails++
	// 					}
	// 				}
	// 			}
	// 			newRow['last_send'] = row.last_send
	// 			if (amountOfEmailRows < amountOfEmails) {
	// 				setAmountOfEmailRows(amountOfEmails)
	// 			}
	// 			newRow.due_date = row.due_date
	// 			if (row.payment) {
	// 				newRow.payment = new Date(row.payment)
	// 			} else {
	// 				newRow.payment = ''
	// 			}
	// 			newRow.number_of_rows = 0
	// 			newRow.number_of_yes = 0
	// 			newRow.number_of_no = 0
	// 			newRow.number_of_on_hold = 0
	// 			newRow.final_amount = 0
	// 			newRow.pending_amount = 0
	// 			newRow.final_with_vat = 0
	// 			newRow.number_of_rows++
	// 			if (row.status.toLowerCase() === 'yes') {
	// 				newRow.final_amount = row.final_amount
	// 				newRow.final_with_vat =
	// 					Number(row.final_amount) +
	// 					Number(row.final_amount) * (Number(row.publisher_vat) / 100)
	// 				newRow.number_of_yes++
	// 			} else if (row.status.toLowerCase() === 'no') {
	// 				newRow.number_of_no++
	// 			} else if (row.status.toLowerCase() === 'on hold') {
	// 				newRow.pending_amount = row.final_amount
	// 				newRow.number_of_on_hold++
	// 			}
	// 			newRow.status_percentage =
	// 				((newRow.number_of_yes + newRow.number_of_no) * 100) /
	// 				newRow.number_of_rows
	// 			alreadyIn.push(
	// 				row.publisher + moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 			)
	// 			result.push(newRow)
	// 		}
	// 	} else {
	// 		let period = row.month
	// 		if (row.roll_over && row.roll_over_month) {
	// 			period = row.roll_over_month
	// 		}

	// 		let rowToEdit = -1
	// 		for (let i = 0; i < result.length; i++) {
	// 			if (result[i].publisher === row.publisher) {
	// 				if (
	// 					moment(result[i].month, 'DD/MM/YYYY').format('DD-MM-YYYY') ===
	// 					moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY')
	// 				) {
	// 					rowToEdit = i
	// 					break
	// 				}
	// 			}
	// 		}
	// 		result[rowToEdit].approved_conversions =
	// 			Number(result[rowToEdit].approved_conversions) +
	// 			Number(row.approved_conversions)
	// 		result[rowToEdit].deduction_amount =
	// 			Number(result[rowToEdit].deduction_amount) +
	// 			Number(row.deduction_amount)
	// 		if (row.status.toLowerCase() !== 'na') {
	// 			result[rowToEdit].number_of_rows++
	// 		}
	// 		if (row.status.toLowerCase() === 'yes') {
	// 			result[rowToEdit].final_amount =
	// 				Number(result[rowToEdit].final_amount) + Number(row.final_amount)
	// 			result[rowToEdit].final_with_vat =
	// 				Number(result[rowToEdit].final_amount) +
	// 				Number(result[rowToEdit].final_amount) *
	// 					(Number(row.publisher_vat) / 100)
	// 			result[rowToEdit].number_of_yes++
	// 		} else if (row.status.toLowerCase() === 'no') {
	// 			result[rowToEdit].number_of_no++
	// 		} else if (row.status.toLowerCase() === 'on hold') {
	// 			result[rowToEdit].pending_amount =
	// 				Number(result[rowToEdit].pending_amount) + Number(row.final_amount)
	// 			result[rowToEdit].number_of_on_hold++
	// 		}
	// 		result[rowToEdit].status_percentage = Math.floor(
	// 			((result[rowToEdit].number_of_yes + result[rowToEdit].number_of_no) *
	// 				100) /
	// 				result[rowToEdit].number_of_rows,
	// 		)
	// 	}
	// }
	// for (const row of rows) {
	// 	for (let i = 0; i < result.length; i++) {
	// 		if (
	// 			row.publisher === result[i].publisher &&
	// 			row.roll_over &&
	// 			row.month === result[i].month
	// 		) {
	// 			result[i].roll_over_value = true
	// 		}
	// 	}
	// }
	if (filterStatus) {
		let filteredArr = []
		if (filterStatus.toLowerCase() === 'all') {
			return result
		} else if (filterStatus.toLowerCase() === 'paid') {
			filteredArr = result.filter((el: any) => el.payment)
		} else if (filterStatus.toLowerCase() === 'unpaid') {
			filteredArr = result.filter((el: any) => !el.payment)
		}
		// } else if (filterStatus.toLowerCase() === 'final sent') {
		// 	filteredArr = result.filter((el: any) => el.final_numbers_send)
		// } else if (filterStatus.toLowerCase() === 'final missing') {
		// 	filteredArr = result.filter((el: any) => !el.final_numbers_send)
		// } else if (filterStatus.toLowerCase() === 'status sent') {
		// 	filteredArr = result.filter((el: any) => el.last_send_1)
		// } else if (filterStatus.toLowerCase() === 'status missing') {
		// 	filteredArr = result.filter(
		// 		(el: any) => !el.last_send_1 && !el.final_numbers_send,
		// 	)
		// }

		return filteredArr
	}

	return result
}

export default CreatorSupplyPage
