import { Typography } from '@mui/material'
import React, { useState } from 'react'

const EasterEggTag = ({ name }: { name: string | null }) => {
	const [hovered, setHovered] = useState(false)

	const handleMouseEnter = () => setHovered(true)
	const handleMouseLeave = () => setHovered(false)

	return (
		<div
			style={{
				position: 'relative',
				display: 'inline-block',
				minHeight: '32px',
				color: hovered ? 'gold' : 'inherit',
				transition: 'color 0.1s, transform 0.3s, margin 0.3s',
				transform: hovered ? 'scale(1.1)' : 'scale(1)',
				marginLeft: hovered ? '10px' : '0px',
				marginRight: hovered ? '-10px' : '0px',
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{hovered && (
				<div>
					<div
						style={{
							position: 'absolute',
							top: '-20%',
							left: '10%',
							width: '3px',
							height: '3px',
							background: 'gold',
							borderRadius: '50%',
							boxShadow: '0 0 10px gold, 0 0 20px gold',
							animation: 'shooting-star 2.4s linear infinite alternate',
						}}
					/>
					<div
						style={{
							position: 'absolute',
							top: '-20%',
							left: '20%',
							width: '5px',
							height: '5px',
							background: 'gold',
							borderRadius: '50%',
							boxShadow: '0 0 10px gold, 0 0 20px gold',
							animation: 'shooting-star 2.8s linear infinite alternate',
						}}
					/>

					<div
						style={{
							position: 'absolute',
							top: '-20%',
							left: '30%',
							width: '3px',
							height: '3px',
							background: 'gold',
							borderRadius: '50%',
							boxShadow: '0 0 10px gold, 0 0 20px gold',
							animation: 'shooting-star 2.2s linear infinite alternate',
						}}
					/>
					<div
						style={{
							position: 'absolute',
							top: '-20%',
							left: '60%',
							width: '3px',
							height: '3px',
							background: 'gold',
							borderRadius: '50%',
							boxShadow: '0 0 10px gold, 0 0 20px gold',
							animation: 'shooting-star 3.2s linear infinite alternate',
						}}
					/>
					<div
						style={{
							position: 'absolute',
							top: '-20%',
							left: '50%',
							width: '10px',
							height: '10px',
							background: 'gold',
							borderRadius: '50%',
							boxShadow: '0 0 10px gold, 0 0 20px gold',
							animation: 'shooting-star 2.8s linear infinite alternate',
						}}
					/>
					<div
						style={{
							position: 'absolute',
							top: '-20%',
							left: '80%',
							width: '3px',
							height: '3px',
							background: 'gold',
							borderRadius: '50%',
							boxShadow: '0 0 10px gold, 0 0 20px gold',
							animation: 'shooting-star 2s linear infinite alternate',
						}}
					/>
					<div
						style={{
							position: 'absolute',
							top: '-20%',
							left: '90%',
							width: '6px',
							height: '6px',
							background: 'gold',
							borderRadius: '50%',
							boxShadow: '0 0 10px gold, 0 0 20px gold',
							animation: 'shooting-star 2.4s linear infinite alternate',
						}}
					/>
				</div>
			)}
			<Typography variant={'h6'}>{name}</Typography>
			<style>
				{`
					@keyframes shooting-star {
                        0% {
							transform: translate(-100%, 50px) scale(0);
						}
						50% {
							transform: translate(-50%, 20px) scale(1);
						}
						100% {
							transform: translate(-100%, -30px) scale(0);
						}

					}
				`}
			</style>
		</div>
	)
}

export default EasterEggTag
