import React, {
	Dispatch,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import './finance.css'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { ThemeProvider } from '@emotion/react'
import { useActions, useFinanceActions } from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { generateRowsWithIds, searchFor } from '../../utils/helpers/tableHelper'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import {
	GridColDef,
	GridRowModesModel,
	GridRowModes,
	GridActionsCellItem,
	GridEventListener,
	GridRowEditStopReasons,
} from '@mui/x-data-grid-pro'
import { MenuItem, Typography, useTheme } from '@mui/material'
import {
	extractPaymentLimit,
	formatDateToMonth,
	formatFinancePaymentDate,
	getMonthAbbreviation,
	getYear,
	returnAbbreviatedMonth,
	sortDates,
	sortHardcodedDates,
} from '../../utils/helpers/helperFuncs'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import saveAs from 'file-saver'
import { useNavigate } from 'react-router'
import moment from 'moment'
import { TableComponent } from '../components/TableComponents'
import { useDispatch } from 'react-redux'
import { emptyFinance } from '../../state/actions'
import { HeaderFilterComponent } from '../components/TableHelperComponents'

const BalanceOutPage = (props: {
	setLoading: any
	errorMessage: any
	setErrorMessage: any
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isEditorOpened?: any
	isAddEditorOpened?: any
	PopUpVisible: any
	setPopUpVisible: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const theme = useTheme()
	const { login, finance, publisher, users, settings } = useTypedSelector(
		(state) => state,
	)
	// const headers: any = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	const {
		getFinanceData,
		uploadBalanceOut,
		downloadFinance,
		downloadFinanceTipalti,
	} = useFinanceActions()
	const sessionKey = 'filters-finance-balance_out'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [filteredFinance, setFilteredFinance] = useState<any>([])
	const rowCount = 13

	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [fileDownload, setFileDownload] = useState()
	const [tableRows, setTableRows] = useState(
		generateRowsWithIds(
			generateRowsByPublisherForBalanceOut(
				filteredFinance,
				publisher.publisher,
			),
		),
	)
	const [financeFinalConfirmationFilter, setFinanceFinalConfirmationFilter] =
		useState(
			sessionFilters.financeFinalConfirmationFilter
				? sessionFilters.financeFinalConfirmationFilter
				: 0,
		)
	const [openFilter, setOpenFilter] = useState(false)
	const [openDateFilter, setOpenDateFilter] = useState(false)
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [customFilter, setCustomFilter] = useState<any>({
		publisher: [],
		publisher_manager: [],
		due_date: [],
	})
	const dateFilters = ['due_date']
	const [dateFilter, setDateFilter] = useState<any>({ due_date: [] })
	const [applyFilter, setApplyFilter] = useState(false)
	const [currentFilters, setCurrentFilters] = useState(
		sessionSerializedFilters && sessionSerializedFilters.length > 4
			? 'advertiser'
			: '',
	)
	const [filterLabel, setFilterLabel] = useState('')
	const [optionsFilters, setOptionsFilter] = useState<any>({
		publisher: [],
		publisher_manager: [],
	})
	const [sortModel, setSortModel] = useState([{ field: 'month', sort: 'desc' }])
	const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
		{},
	)
	const [monthFilterArray, setMonthFilterArray] = useState<any>([])
	const [yearFilterArray, setYearFilterArray] = useState<any>([])
	const [pinnedBottomRow, setPinnedBottomRow] = useState<any>([])
	const [dataFetched, setDataFetched] = useState(false)
	const dispatch = useDispatch()
	const hasFetched = useRef(false)

	const fetchData = async () => {
		let i = 0
		let result
		do {
			result = await getFinanceData(
				'balanceout',
				props.errorMessage,
				login.user.email,
				500,
				i,
			)
			i++
			setDataFetched(true)
		} while (
			result &&
			result.length > 0 &&
			window.location.pathname.endsWith('balanceout')
		)
	}
	useEffect(() => {
		dispatch(emptyFinance())
		//this needs to be added for the StrictMode. Otherwise data is duplicated.
		if (hasFetched.current) {
			return
		}
		hasFetched.current = true
		fetchData()
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
			sessionFilters.dateFilter && setDateFilter(sessionFilters.dateFilter)
			sessionFilters.monthFilterArray &&
				setMonthFilterArray(sessionFilters.monthFilterArray)
			sessionFilters.yearFilterArray &&
				setYearFilterArray(sessionFilters.yearFilterArray)
			sessionFilters.financeFinalConfirmationFilter &&
				setFinanceFinalConfirmationFilter(
					sessionFilters.financeFinalConfirmationFilter,
				)
		}
	}, [])
	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
				dateFilter,
				monthFilterArray,
				yearFilterArray,
				financeFinalConfirmationFilter,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [
		applyFilter,
		monthFilterArray,
		yearFilterArray,
		financeFinalConfirmationFilter,
	])
	useEffect(() => {
		setFilteredFinance(Array.isArray(finance.finance) ? finance.finance : [])
	}, [finance.finance])
	useEffect(() => {
		if (dataFetched) {
			setOptionsFilter({
				publisher: new Set(
					tableRows
						.map((element: any) => element.publisher)
						.filter((el: any) => el.length > 0),
				),
				publisher_manager: new Set(
					tableRows
						.map((element: any) => element.publisher_manager)
						.filter((el: any) => el && el.length > 0),
				),
			})
		}
	}, [tableRows])

	//same logic for filtering. The main difference is that in this table we will render the tableRow grouped by publishers.
	//we will have only 1 row for each publisher.
	//the main difference is that we check the possibleFinanceFilter INSIDE the function that generates the rows.
	const possibleFinanceFilter = useMemo(
		() => ['All', 'Missing', 'Received', 'Paid', 'Unpaid'],
		[],
	)
	useEffect(() => {
		if (dataFetched) {
			props.setLoading(true)
		}
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, finance.finance, columns)
			if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'all'
			) {
				filtered = searched
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'missing'
			) {
				filtered = searched.filter((el: any) => !el.invoice_number)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'received'
			) {
				filtered = searched.filter((el: any) => el.invoice_number)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'paid'
			) {
				filtered = searched.filter((el: any) => el.payment)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'unpaid'
			) {
				filtered = searched.filter((el: any) => !el.payment)
			}
		} else {
			if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'all'
			) {
				filtered = finance.finance
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'missing'
			) {
				filtered = finance.finance.filter((el: any) => !el.invoice_number)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'received'
			) {
				filtered = finance.finance.filter((el: any) => el.invoice_number)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'paid'
			) {
				filtered = finance.finance.filter((el: any) => el.payment)
			} else if (
				possibleFinanceFilter[financeFinalConfirmationFilter].toLowerCase() ===
				'unpaid'
			) {
				filtered = finance.finance.filter((el: any) => !el.payment)
			}
		}
		let arr = []
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false
			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true
					foundByKey = customFilter[key].includes(data[key])
					if (key === 'publisher_manager') {
						const values = customFilter[key].map((el: string) =>
							el.toLowerCase(),
						)
						const userFound = users.users.find((e: any) => {
							return (
								values.includes(e.name.toLowerCase()) &&
								data[key] &&
								data[key].toLowerCase() === e.name.toLowerCase()
							)
						})
						foundByKey = !!userFound
					}

					if (dateFilters.includes(key)) {
						if (data[key]) {
							const dateFrom = moment(customFilter[key][0]['$d'])
							const dateTo = moment(customFilter[key][1]['$d'])
							let value
							if (typeof data[key] === 'string' && data[key].includes('/')) {
								value = moment(data[key], 'DD/MM/YYYY')
							} else {
								value = moment(data[key])
							}
							if (value.isBetween(dateFrom, dateTo, undefined, '[]')) {
								foundByKey = true
							}
						}
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		if (!anyfilters) {
			arr = filtered
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = additionalFiltersApplied
		} else {
			arr = []
		}

		//this handles the year
		if (yearFilterArray.length !== 0) {
			const filteredData = arr.filter((item: any) => {
				const year = getYear(item.month)
				// console.log(year, yearFilterArray)
				if (
					yearFilterArray.includes('This Year') &&
					year === moment().year().toString()
				) {
					return true
				}
				return yearFilterArray.includes(year)
			})
			arr = filteredData
		}
		//this handles the months
		if (monthFilterArray.length !== 0) {
			const arrToFilter = arr
			const monthAbbrevatedFilteredArray = monthFilterArray.map((el: string) =>
				returnAbbreviatedMonth(el),
			)
			const filteredData = arrToFilter.filter((item: any) => {
				const monthAbbreviation = getMonthAbbreviation(item.month)
				return monthAbbrevatedFilteredArray.includes(monthAbbreviation)
			})
			arr = filteredData
		}
		const publisherRows = generateRowsByPublisherForBalanceOut(
			arr,
			publisher.publisher,
			possibleFinanceFilter[financeFinalConfirmationFilter],
		)
		arr = generateRowsWithIds(publisherRows)
		setTableRows(arr)
		setUnfiltered(arr)
		setPinnedBottomRow(calculatePinnedTotalRow(arr))
		if (dataFetched) {
			props.setLoading(false)
		}
	}, [
		props.search,
		monthFilterArray,
		yearFilterArray,
		applyFilter,
		filteredFinance,
		financeFinalConfirmationFilter,
		finance.finance,
	])
	const handleApplyFilter = useCallback(() => {
		setApplyFilter(!applyFilter)
	}, [applyFilter])

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	//logic behind the edit for the rows.
	const handleEditClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
	}
	const handleSaveClick = (id: any) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
	}
	const handleCancelClick = (id: any) => () => {
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})
	}
	const tableRowsRef = useRef(tableRows)

	useEffect(() => {
		tableRowsRef.current = tableRows
	}, [tableRows])

	const handleDownloadFinance = useCallback(() => {
		downloadFinance(
			tableRowsRef.current,
			'balance-out',
			setFileDownload,
			props.setErrorMessage,
		)
	}, [])
	const handleDownloadTipaltiReport = useCallback(() => {
		downloadFinanceTipalti(
			tableRowsRef.current,
			setFileDownload,
			props.setErrorMessage,
		)
	}, [])

	const downloadOptions = useMemo(
		() => (
			<div>
				<MenuItem key={'menuption_data'} onClick={handleDownloadFinance}>
					Current Data
				</MenuItem>

				<MenuItem
					key={'menuption_finance_tipalti_report'}
					onClick={handleDownloadTipaltiReport}
				>
					Tipalti Report
				</MenuItem>
			</div>
		),
		[handleDownloadFinance, handleDownloadTipaltiReport],
	)

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (
		params,
		event,
	) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true
		}
	}

	const columns: GridColDef[] = [
		{
			field: 'publisher',
			headerName: 'Publisher',
			width: rowWidth,
			editable: false,
			type: 'singleSelect',
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Publisher'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'publisher_manager',
			headerName: 'PM',
			width: rowWidth * 1.6,
			editable: false,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'PM'}
						width={rowWidth * 2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'publisher_manager'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'month',
			headerName: 'Period',
			headerAlign: 'center',
			align: 'center',
			editable: false,
			width: rowWidth * 0.8,
			renderCell: ({ value }) => {
				const date = formatDateToMonth(value)
				return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
			},
			sortComparator: (str1, str2) => {
				return sortHardcodedDates(str1, str2)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'revenue',
			headerName: 'Revenue',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			editable: false,
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'cost',
			headerName: 'Cost',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			editable: false,
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'profit',
			headerName: 'Profit',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			headerClassName: 'finance-final-header',
			editable: false,
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'finance-final-cell is-not-editable'
				}
				return 'finance-final-cell is-editable'
			},
		},
		{
			field: 'cost_with_vat',
			headerName: 'Amount inc. VAT',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			type: 'number',
			align: 'center',
			editable: false,
			renderCell: ({ value }) => {
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'invoice_number',
			headerName: 'Invoice Number',
			width: rowWidth * 0.8,
			align: 'left',
			headerAlign: 'left',
			editable: true,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'due_date',
			headerName: 'Due Date',
			width: rowWidth * 0.8,
			type: 'date',
			align: 'left',
			headerAlign: 'left',
			editable: true,
			valueGetter: ({ value }) => {
				if (!value) {
					return ''
				}
				if (typeof value === 'string' && value.includes('/')) {
					return moment(value, 'DD/MM/YYYY').toDate()
				} else {
					return moment(value).toDate()
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: (row) => {
				if (row.value) {
					const date = formatFinancePaymentDate(row.value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				} else {
					return <></>
				}
			},
			sortComparator: (str1, str2) => {
				return sortDates(str2, str1)
			},
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Due Date'}
						width={rowWidth * 2}
						openFilter={openDateFilter}
						setOpenFilter={setOpenDateFilter}
						field={'due_date'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
		},
		{
			field: 'payment',
			headerName: 'Payment Date',
			width: rowWidth * 0.8,
			align: 'left',
			headerAlign: 'left',
			editable: true,
			type: 'date',
			valueGetter: ({ value }) => new Date(value),
			renderCell: (row) => {
				if (row.value) {
					if (row.row.publisher_manager === 'Creative Team') {
						return <>-</>
					}
					const date = formatFinancePaymentDate(row.value)
					return <Typography sx={{ fontSize: 14 }}>{date}</Typography>
				} else {
					return <></>
				}
			},
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'paid_amount_publisher',
			headerName: 'Paid Amount',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			editable: true,
			type: 'number',
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				if (!value) {
					return <></>
				}
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
		},
		{
			field: 'bank_fees_publisher',
			headerName: 'Bank Fees',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			editable: true,
			type: 'number',
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
			renderCell: ({ value }) => {
				if (!value && value !== 0) {
					return <></>
				}
				return (
					<span>
						{Number(value).toLocaleString('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						})}
					</span>
				)
			},
		},
		{
			field: 'payment_method_publisher',
			headerName: 'Payment Method',
			width: rowWidth,
			align: 'left',
			headerAlign: 'left',
			type: 'singleSelect',
			editable: true,
			valueOptions: settings.settings.paymentMethod,
			cellClassName: (params) => {
				if (params.colDef.editable === false) {
					return 'is-not-editable'
				}
				return 'is-editable'
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 100,
			cellClassName: 'actions',
			getActions: (row) => {
				const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
				if (
					row.id === 9999999999 ||
					row.row.publisher_manager === 'Creative Team'
				) {
					return []
				}
				if (isInEditMode) {
					return [
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.text.titles,
										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<CheckIcon fontSize={'inherit'} />
								</div>
							}
							label='Save'
							sx={{
								color: 'primary.main',
							}}
							onClick={handleSaveClick(row.id)}
						/>,
						<GridActionsCellItem
							icon={
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										border: 'none',
										color: theme.colors.text.titles,
										height: '28px',
										width: '28px',
										fontSize: '16px',
									}}
									className={'finance-action-button'}
								>
									<ClearIcon fontSize={'inherit'} />
								</div>
							}
							label='Cancel'
							className='textPrimary'
							onClick={handleCancelClick(row.id)}
							color='inherit'
						/>,
					]
				}

				return [
					<GridActionsCellItem
						icon={
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									border: 'none',
									cursor: 'pointer',
									height: '28px',
									width: '28px',
									fontSize: '16px',
								}}
								className={'finance-action-button'}
							>
								<EditIcon
									style={{ color: theme.colors.text.titles, scale: '1' }}
									className={'action-button'}
								/>
							</div>
						}
						label='Edit'
						className='textPrimary'
						onClick={handleEditClick(row.id)}
						color='inherit'
					/>,
				]
			},
		},
	]
	//we use this to handle the saving. It will trigger a save that will update all the rows that contain the same publisher and
	//period (month + year).
	const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
		setRowModesModel(newRowModesModel)
	}
	const processRowUpdate = async (newRow: any) => {
		// console.log('nrwRow', newRow)
		if (newRow.invoice_number && !newRow.due_date) {
			let currentDate = new Date()
			let isPrepayment = false
			const selectedPublisher = publisher.publisher.filter(
				(pub: any) =>
					pub.publisher_name.toLowerCase() === newRow.publisher.toLowerCase(),
			)
			const paymentTerm = selectedPublisher[0].payment_term
			if (paymentTerm) {
				if (paymentTerm.startsWith('Net')) {
					const numberOfDays = extractPaymentLimit(paymentTerm)
					//we add 1 because it needs to start counting from the following day of registration.
					currentDate.setDate(
						currentDate.getDate() + parseInt(numberOfDays) + 1,
					)
				} else if (paymentTerm === 'Prepayment') {
					isPrepayment = true
				}
			} else {
				//by default we use Net45 which would be 45 + 1
				currentDate.setDate(currentDate.getDate() + 46)
			}
			const year = currentDate.getFullYear()
			const month = String(currentDate.getMonth() + 1).padStart(2, '0')
			const day = String(currentDate.getDate()).padStart(2, '0')
			if (!newRow.due_date) {
				newRow.due_date = isPrepayment ? '-' : `${day}/${month}/${year}`
			}
		}
		if (!newRow.invoice_number && newRow.due_date) {
			newRow.due_date = ''
		}
		const updatedRow = { ...newRow, isNew: false }
		const failed = await uploadBalanceOut(
			newRow,
			login.user.email,
			props.setErrorMessage,
		)
		if (!failed) {
			const arr = tableRows.map((row: any) =>
				row.uuid === newRow.uuid ? updatedRow : row,
			)
			// console.log(updatedRow, arr)
			setTableRows(arr)
			setPinnedBottomRow(calculatePinnedTotalRow(arr))
		} else {
			const notUpdatedData = [...tableRows]
			setTableRows(notUpdatedData)
			setPinnedBottomRow(calculatePinnedTotalRow(notUpdatedData))
		}
		return updatedRow
	}
	//prevent the editing with double click.
	const handleDoubleCellClick = React.useCallback((params, event) => {
		event.defaultMuiPrevented = true
	}, [])

	// Prevent from rolling back on escape
	const handleCellKeyDown = React.useCallback((params, event) => {
		if (
			['Escape', 'Delete', 'Backspace', 'Enter'].includes(
				(event as React.KeyboardEvent).key,
			)
		) {
			event.defaultMuiPrevented = true
		}
	}, [])

	// Prevent from committing on focus out
	const handleCellFocusOut = React.useCallback((params, event) => {
		if (params.cellMode === 'edit' && event) {
			event.defaultMuiPrevented = true
		}
	}, [])

	const handleRowStyle = (params: any) => {
		const row = params.row
		const isInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit
		if (isInEditMode) {
			return 'edit-mode-on'
		}
		//9999999999 this id is the hardcoded id we use for the bottom pinned row.
		if (row.id === 9999999999) {
			return 'pinned-row'
		}
		return ''
	}

	return (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={financeFinalConfirmationFilter}
						setFilterStatus={setFinanceFinalConfirmationFilter}
						rowHeight={40}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						mainFilterOptions={possibleFinanceFilter}
						rowModesModel={rowModesModel}
						handleRowModesModelChange={handleRowModesModelChange}
						processRowUpdate={processRowUpdate}
						handleRowEditStop={handleRowEditStop}
						handleDoubleCellClick={handleDoubleCellClick}
						handleCellKeyDown={handleCellKeyDown}
						handleCellFocusOut={handleCellFocusOut}
						monthFilterArray={monthFilterArray}
						setMonthFilterArray={setMonthFilterArray}
						yearFilterArray={yearFilterArray}
						setYearFilterArray={setYearFilterArray}
						// downloadTemplate={handleDownloadFinance}
						downloadButtonFolderOptions={downloadOptions}
						getRowClassName={handleRowStyle}
						pinnedBottomRow={pinnedBottomRow}
						customSx={{
							'& .MuiDataGrid-editInputCell': {
								fontSize: '16px',
								marginTop: '3px',
							},
							'& .MuiSelect-select': {
								fontSize: '16px',
							},
							'& ::-webkit-scrollbar': {
								height: '7px',
								width: '10px',
							},
						}}
						dateFilter={dateFilter}
						setDateFilter={setDateFilter}
						dateFilterHandler={openDateFilter}
						setDateFilterHandler={setOpenDateFilter}
					></TableComponent>
				</TableWrapper>
			</PageWrapper>
		</ThemeProvider>
	)
}

//this is the function that generates the rows by publishers. Basically we save the publisher name the first time it's read
//(generating a new row) and if we found the publisher then we just update its values instead of generating a new row.
const generateRowsByPublisherForBalanceOut = (
	inputRows: any,
	publisherList: any,
	filterStatus: string = '',
) => {
	return inputRows
	// const rows = [...inputRows]
	// const result = []
	// const alreadyIn: string[] = []
	// for (const row of rows) {
	// 	if (row.status.toLowerCase() !== 'yes') {
	// 		continue
	// 	}
	// 	let newRow: any = {}
	// 	let period = row.month
	// 	if (row.roll_over && row.roll_over_month) {
	// 		period = row.roll_over_month
	// 	}
	// 	if (
	// 		!alreadyIn.includes(
	// 			row.publisher + moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 		)
	// 	) {
	// 		const currentPublisher = publisherList.filter(
	// 			(pub: any) => pub.publisher_name === row.publisher,
	// 		)
	// 		if (currentPublisher[0]) {
	// 			newRow.publisher = row.publisher
	// 			newRow.publisher_manager = row.publisher_manager
	// 			newRow.platform = ''
	// 			newRow.uuid =
	// 				row.uuid +
	// 				'-supply-' +
	// 				moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY')
	// 			newRow.month = period
	// 			newRow.approved_conversions = row.approved_conversions
	// 			newRow.deduction_amount = row.deduction_amount
	// 			newRow.final_amount = row.final_amount
	// 			newRow.invoice_number = row.invoice_number
	// 			newRow.due_date = row.due_date
	// 			newRow.revenue = row.revenue
	// 			newRow.cost = row.final_amount
	// 			newRow.bank_fees_publisher = row.bank_fees_publisher
	// 			newRow.paid_amount_publisher = row.paid_amount_publisher
	// 			newRow.cost_with_vat =
	// 				Number(row.final_amount) +
	// 				Number(row.final_amount) * (Number(row.publisher_vat) / 100)
	// 			newRow.profit = Number(newRow.revenue) - Number(newRow.cost)
	// 			if (row.bank_fees_publisher) {
	// 				newRow.profit = newRow.profit - Number(row.bank_fees_publisher)
	// 			}
	// 			newRow.payment_method_publisher = row.payment_method_publisher
	// 			if (row.payment) {
	// 				newRow.payment = new Date(row.payment)
	// 			} else {
	// 				newRow.payment = ''
	// 			}
	// 			newRow.number_of_rows = 1
	// 			newRow.number_of_yes = 0
	// 			newRow.number_of_no = 0
	// 			newRow.number_of_on_hold = 0
	// 			if (row.status.toLowerCase() === 'yes') {
	// 				newRow.number_of_yes++
	// 			} else if (row.status.toLowerCase() === 'no') {
	// 				newRow.number_of_no++
	// 			} else if (row.status.toLowerCase() === 'on hold') {
	// 				newRow.number_of_on_hold++
	// 			}
	// 			newRow.status_percentage =
	// 				((newRow.number_of_yes + newRow.number_of_no) * 100) /
	// 				newRow.number_of_rows
	// 			alreadyIn.push(
	// 				row.publisher + moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY'),
	// 			)
	// 			result.push(newRow)
	// 		}
	// 	} else {
	// 		let period = row.month
	// 		if (row.roll_over && row.roll_over_month) {
	// 			// console.log('TEST', period, row.roll_over_month)
	// 			period = row.roll_over_month
	// 		}
	// 		let rowToEdit = -1
	// 		for (let i = 0; i < result.length; i++) {
	// 			if (result[i].publisher === row.publisher) {
	// 				if (
	// 					moment(result[i].month, 'DD/MM/YYYY').format('DD-MM-YYYY') ===
	// 					moment(period, 'DD/MM/YYYY').format('DD-MM-YYYY')
	// 				) {
	// 					rowToEdit = i
	// 					break
	// 				}
	// 			}
	// 		}
	// 		result[rowToEdit].approved_conversions =
	// 			Number(result[rowToEdit].approved_conversions) +
	// 			Number(row.approved_conversions)
	// 		result[rowToEdit].deduction_amount =
	// 			Number(result[rowToEdit].deduction_amount) +
	// 			Number(row.deduction_amount)
	// 		result[rowToEdit].revenue =
	// 			Number(result[rowToEdit].revenue) + Number(row.revenue)
	// 		result[rowToEdit].cost =
	// 			Number(result[rowToEdit].cost) + Number(row.final_amount)
	// 		result[rowToEdit].cost_with_vat =
	// 			Number(result[rowToEdit].cost) +
	// 			Number(row.cost) * (Number(row.publisher_vat) / 100)
	// 		result[rowToEdit].profit =
	// 			Number(result[rowToEdit].revenue) - Number(result[rowToEdit].cost)
	// 		if (result[rowToEdit].bank_fees_publisher) {
	// 			result[rowToEdit].profit =
	// 				result[rowToEdit].profit -
	// 				Number(result[rowToEdit].bank_fees_publisher)
	// 		}
	// 		result[rowToEdit].number_of_rows++
	// 		if (row.status.toLowerCase() === 'yes') {
	// 			result[rowToEdit].number_of_yes++
	// 		} else if (row.status.toLowerCase() === 'no') {
	// 			result[rowToEdit].number_of_no++
	// 		} else if (row.status.toLowerCase() === 'on hold') {
	// 			result[rowToEdit].number_of_on_hold++
	// 		}
	// 		result[rowToEdit].status_percentage = Math.floor(
	// 			((result[rowToEdit].number_of_yes + result[rowToEdit].number_of_no) *
	// 				100) /
	// 				result[rowToEdit].number_of_rows,
	// 		)
	// 	}
	// }
	// if (filterStatus) {
	// 	let filteredArr = []
	// 	if (filterStatus.toLowerCase() === 'all') {
	// 		return result
	// 	} else if (filterStatus.toLowerCase() === 'yes') {
	// 		filteredArr = result.filter((el: any) => el.number_of_on_hold === 0)
	// 	} else if (filterStatus.toLowerCase() === 'on hold') {
	// 		filteredArr = result.filter((el: any) => el.number_of_on_hold > 0)
	// 	}
	// 	return filteredArr
	// }
	// return result
}
const calculatePinnedTotalRow = (rows: any) => {
	const pinnedRow = {
		publisher: 'TOTAL',
		publisher_manager: '',
		month: '',
		revenue: 0,
		cost: 0,
		profit: 0,
		invoice_number: '',
		due_date: '',
		payment: '',
		bank_fees_publisher: 0,
		paid_amount_publisher: 0,
		payment_method_publisher: '',
		cost_with_vat: 0,
		id: 9999999999,
	}
	for (const row of rows) {
		if (row.cost) {
			pinnedRow.cost = Number(pinnedRow.cost) + Number(row.cost)
		}
		if (row.revenue) {
			pinnedRow.revenue = Number(pinnedRow.revenue) + Number(row.revenue)
		}
		if (row.bank_fees_publisher) {
			pinnedRow.bank_fees_publisher =
				Number(pinnedRow.bank_fees_publisher) + Number(row.bank_fees_publisher)
		}
		if (row.paid_amount_publisher) {
			pinnedRow.paid_amount_publisher =
				Number(pinnedRow.paid_amount_publisher) +
				Number(row.paid_amount_publisher)
		}
		if (row.cost_with_vat) {
			pinnedRow.cost_with_vat =
				Number(pinnedRow.cost_with_vat) + Number(row.cost_with_vat)
		}
	}
	pinnedRow.revenue = Number(pinnedRow.revenue).toFixed(2) as any
	pinnedRow.cost = Number(pinnedRow.cost).toFixed(2) as any
	pinnedRow.bank_fees_publisher = Number(pinnedRow.bank_fees_publisher).toFixed(
		2,
	) as any
	pinnedRow.paid_amount_publisher = Number(
		pinnedRow.paid_amount_publisher,
	).toFixed(2) as any
	pinnedRow.cost_with_vat = Number(pinnedRow.cost_with_vat).toFixed(2) as any
	pinnedRow.profit =
		Number(pinnedRow.revenue) -
		Number(pinnedRow.cost) -
		Number(pinnedRow.bank_fees_publisher)
	pinnedRow.profit = Number(pinnedRow.profit).toFixed(2) as any
	return [pinnedRow]
}

export default BalanceOutPage
