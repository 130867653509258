import React, { useEffect, useState } from 'react'
import {
	useActions,
	useLogActions,
	usePublisherActions,
} from '../../hooks/useActions'
import { useNavigate, useParams } from 'react-router'
import {
	Box,
	DialogActions,
	ThemeProvider,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { AllowedPlatform } from './PublisherPopUp'
import _ from 'lodash'
import {
	decryptContacts,
	handleIconUrl,
	isValidImageType,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import {
	checkInputStartsWithHttp,
	shallowEqual,
} from '../../utils/helpers/tableHelper'
import {
	ContactInterface,
	ContactsComponent,
} from '../components/ContactComponent'
import { v4 as uuidv4 } from 'uuid'
import defaultIcon from '../../assets/default-icon.webp'
import EditPageHeader from '../components/EditPageHeader'
import { editPageTopPanelStyle } from '../../utils/helpers/commonStyles'
import CustomModal from '../components/CustomModal'
import { CheckCircle } from '@mui/icons-material'
import CustomFileUploader from '../components/CustomFileUploader'
import { ACCEPTED_FILES } from '../../config'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import CustomDialog from '../components/CustomDialog'
import { PageWrapper } from '../components/reusableComponents'
import { TabPanel } from '../advertisers/AdvertiserPopUp'
import { PublisherCompanyInfoComponent } from './PublisherCompanyInfoComponent'
import { PublisherAllowedPlatformsComponent } from './PublisherAllowedPlatformsComponent'
import { FinanceInfo } from './PublisherFinanceComponent'
import { SubmitButton, UnborderedButton } from '../components/Buttons'

const PublisherCompanyEdit = ({
	companyData,
	setLoading,
	setErrorMessage,
	setIsErrorPromptOpened,
	setIsSuccessPromptOpened,
	setPopUpVisible,
	onSave,
}: any) => {
	const { removeAppP360, uploadIcon, deleteIcon, updateCompanyData } =
		useActions()
	const { insertLog } = useLogActions()
	const { updatePublisherMasterAction } = usePublisherActions()
	const navigate = useNavigate()
	const params = useParams()
	const theme = useTheme()
	const countryData = [
		{ value: 'global', payout: '', volume: '' },
		{ value: 'Antartica', payout: '', volume: '' },
		{ value: 'Africa', payout: '', volume: '' },
		{ value: 'Asia', payout: '', volume: '' },
		{ value: 'Europe', payout: '', volume: '' },
		{ value: 'North America', payout: '', volume: '' },
		{ value: 'Oceania', payout: '', volume: '' },
		{ value: 'South America', payout: '', volume: '' },
	]
	const { login, settings, publisher, company } = useTypedSelector(
		(state) => state,
	)
	const permissions = {
		edit: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		viewFinancePersonal: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		viewFinanceMediaBuying: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		viewAllContacts: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		viewLinks: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		preview: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
		editSkype: useIsAuthorized(permissionNames.EDIT_COMPANY_DATA),
	}
	const [tab, setTab] = useState(0)
	const [name, setName] = useState(companyData?.publisher_name || '')
	const [legalName, setLegalName] = useState<string>(
		companyData?.legal_name || '',
	)
	const [address, setAddress] = useState<string>(companyData?.address || '')
	const [geo, setGeo] = useState<string[]>(companyData?.geo || [])
	// const [type, setType] = useState<string>(companyData?.type || '')
	const [pids, setPIDs] = useState<string[]>(
		companyData?.media_source_pid || [],
	)
	const [contacts, setContacts] = useState<any[]>(companyData?.contacts || [])
	const [badgeGoodQuality, setBadgeGoodQuality] = useState<boolean>(
		companyData?.media_buying_capabilities?.badges?.good_quality || false,
	)
	const [badgeLowFraud, setBadgeLowFraud] = useState<boolean>(
		companyData?.media_buying_capabilities?.badges?.low_fraud || false,
	)
	const [badgeHighVolume, setBadgeHighVolume] = useState<boolean>(
		companyData?.media_buying_capabilities?.badges?.high_volume || false,
	)
	const [badgeHighCR, setBadgeHighCR] = useState<boolean>(
		companyData?.media_buying_capabilities?.badges?.high_cr || false,
	)
	const [skypeGroup, setSkypeGroup] = useState<string>(
		companyData?.skype_group || [],
	)
	const [notes, setNotes] = useState<string>(companyData?.notes || '')
	const [capabilities, setCapabilities] = useState<AllowedPlatform[]>(
		companyData?.capabilities || [],
	)
	const [linkToLinkedin, setLinkToLinkedin] = useState<string>(
		companyData?.link_to_linkedin || '',
	)
	const [linkToWebsite, setLinkToWebsite] = useState<string>(
		companyData?.link_to_website || '',
	)
	const [externalId, setExternalId] = useState<number | null>(
		companyData?.external_id || null,
	)
	const [publicSkype, setPublicSkype] = useState<boolean>(
		companyData?.public_skype || true,
	)
	const [mainCategory, setMainCategory] = useState(
		companyData?.main_category || '',
	)
	const [iconUrl, setIconUrl] = useState<string>(
		companyData?.publisher_icon || '',
	)
	const [id, setId] = useState<number | null>(companyData?.id || null)
	const [deletedContactList, setDeletedContactList] = useState<any>([])
	const [createdBy, setCreatedBy] = useState<string>('')

	// const [financeId, setFinanceId] = useState<string>(
	// 	companyData?.finance_id || '',
	// )
	const [isValid, setIsValid] = useState<boolean>(false)
	const [prevState, setPrevState] = useState<any>(null)
	const [externalIdInUse, setExternalIdInUse] = useState<boolean>(false)
	// const [financeIdInUse, setFinanceIdInUse] = useState<boolean>(false)
	const [mainHeightChange, setMainHeightChange] = useState<boolean>(false)

	const [vertical, setVertical] = useState([])
	const [selectedCountryElements, setSelectedCountryElements] = useState([])
	const [selectedGeneralCountryData, setSelectedGeneralCountryData] =
		useState(countryData)
	const [selectedFraudTools, setSelectedFraudTools] = useState([])
	const [selectedTypeOfPublisher, setSelectedTypeOfPublisher] = useState([])
	const [selectedAdType, setSelectedAdType] = useState([])
	const [selectedDevices, setSelectedDevices] = useState([])
	const [selectedPaymentModel, setSelectedPaymentModel] = useState([])
	const [selectedAvailablePlatforms, setSelectedAvailablePlatforms] = useState(
		[],
	)
	const [selectedAbilities, setSelectedAbitilies] = useState([])
	const [selectedRelevantCategories, setSelectedRelevantCategories] = useState(
		[],
	)
	const [specialRequestList, setSpecialRequestList] = useState('')
	const [selectedTrafficIndicators, setSelectedTrafficIndicators] = useState([])
	const [selectedFormatTypes, setSelectedFormatTypes] = useState([])
	const [selectedDailyCap, setSelectedDailyCap] = useState([])
	const companyAgencyAccess =
		company.companies.find((item: any) => {
			return item.id === companyData.company_id
		})?.agency_access || false

	const [agencyAccess, setAgencyAccess] = useState<boolean>(companyAgencyAccess)

	const publisherCapabilitiesMap = {
		selectedCountryElements,
		setSelectedCountryElements,
		selectedGeneralCountryData,
		setSelectedGeneralCountryData,
		selectedFraudTools,
		setSelectedFraudTools,
		selectedTypeOfPublisher,
		setSelectedTypeOfPublisher,
		selectedAdType,
		setSelectedAdType,
		selectedDevices,
		setSelectedDevices,
		selectedPaymentModel,
		setSelectedPaymentModel,
		selectedAvailablePlatforms,
		setSelectedAvailablePlatforms,
		selectedAbilities,
		setSelectedAbitilies,
		selectedRelevantCategories,
		setSelectedRelevantCategories,
		selectedTrafficIndicators,
		setSelectedTrafficIndicators,
		specialRequestList,
		setSpecialRequestList,
		selectedFormatTypes,
		setSelectedFormatTypes,
		selectedDailyCap,
		setSelectedDailyCap,
		badgeGoodQuality,
		setBadgeGoodQuality,
		badgeLowFraud,
		setBadgeLowFraud,
		badgeHighVolume,
		setBadgeHighVolume,
		badgeHighCR,
		setBadgeHighCR,
	}
	const [dataFilled, setDataFilled] = useState(false)
	const [uploadStatus, setUploadStatus] = useState<{
		message: string
		success: boolean
	} | null>(null)

	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
	const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
	// const [currentPasswords, setCurrentPasswords] = useState([])

	// const [fieldValidations, setFieldValidations] = useState({
	// 	name: false,
	// 	legalName: false,
	// })
	// const fieldValidations = {
	// 	name: true,
	// 	legalName: true,
	// }

	const row = companyData
	const canViewContacts = true

	const [mandatoryFields, setMandatoryFields] = useState({
		name: true,
		legalName: true,
	})

	//we use this to handle the logs.
	const preparePrevState = (prevState: any) => {
		try {
			const copiedState = _.cloneDeep(prevState)
			const { id, createdAt, name_history, ...result } = copiedState
			return result
		} catch (error) {
			return null
		}
	}

	const prepareNewState = (prevState: any) => {
		const { user_login, name_history, ...result } = prevState
		return result
	}

	useEffect(() => {
		const fetchAndDecryptContacts = async () => {
			if (row && row.contacts && canViewContacts) {
				try {
					const decryptedContacts = await decryptContacts(row.contacts)

					setContacts(decryptedContacts)
				} catch (error) {
					setErrorMessage('Failed to decrypt contact information.')
				}
			}
		}
		fetchAndDecryptContacts()
	}, [row])

	const nullifyFields = () => {
		setPIDs([])
		setName('')
		setLegalName('')
		setAddress('')
		// setFinanceId('')
		setGeo([])
		setSkypeGroup('')
		setExternalId(null)
		setSelectedCountryElements([])
		setPublicSkype(true)
		setMainCategory('')
		setId(null)
		setContacts([])
	}

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}
	const handleLog = async (payload: any) => {
		if (prevState && prevState.capabilities) {
			prevState['capabilities'] = capabilitiesFromJson(prevState?.capabilities)
		}
		const shallow = shallowEqual(prevState, prepareNewState(payload))
		if (shallow.length > 0) {
			const log = {
				email: login.user.email,
				object: name! + ' in Publishers',
				change: JSON.stringify(shallow),
			}
			await insertLog(log)
		}
	}
	const trySubmit = async (isContinuing: boolean, data: any) => {
		if (isValid) {
			const mediaBuyingProps = {
				country: selectedCountryElements,
				country_general: selectedGeneralCountryData,
				type_publisher: selectedTypeOfPublisher,
				ad_type: selectedAdType,
				devices: selectedDevices,
				payment_model: selectedPaymentModel,
				available_platforms: selectedAvailablePlatforms,
				fraud_tools: selectedFraudTools,
				abilities: selectedAbilities,
				relevant_categories: selectedRelevantCategories,
				traffic_indicators: selectedTrafficIndicators,
				special_request_list: specialRequestList,
				format_types: selectedFormatTypes,
				daily_cap: selectedDailyCap,
				badges: {
					good_quality: badgeGoodQuality,
					low_fraud: badgeLowFraud,
					high_volume: badgeHighVolume,
					high_cr: badgeHighCR,
				},
			}
			let payload: any = {
				publisher_name: name!,
				id: id,
				user_login: login.user.email,
				media_source_pid: pids!,
				legal_name: legalName,
				address: address,
				company_id: companyData.company_id,
				geo: geo,
				// type: type,
				notes: notes,
				skype_group: skypeGroup,
				contacts: _.cloneDeep(contacts || []),
				capabilities: capabilities,
				// payout_model: '',
				// creative_needs: '',
				// macro_list: '',
				created_by: row ? createdBy : login.user.email,
				external_id: externalId,
				media_buying_capabilities: mediaBuyingProps,
				link_to_linkedin: linkToLinkedin,
				link_to_website: linkToWebsite,
				public_skype: publicSkype,
				main_category: mainCategory,
				//we will asign an owner if there's no owner for the system not to break
				email: companyData.email ? companyData.email : login.user.email,
				// passwords: currentPasswords,
			}
			// const headers = {
			// 	Authorization: `Token ${login.user.token}`,
			// }
			setLoading(true)
			let failed: any
			if (row) {
				console.log(payload)
				failed = await updatePublisherMasterAction(payload, setErrorMessage)
				await updateCompanyData({
					id: companyData.company_id,
					agency_access: agencyAccess,
				})
				if (deletedContactList.length > 0) {
					failed += removeAppP360(
						{ email: deletedContactList, publisher: name, direct: false },
						setErrorMessage,
						setIsSuccessPromptOpened,
					)
				}
			}
			if (!failed) {
				// handleLog(payload)
				setIsSuccessPromptOpened(true)
				if (payload.contact) {
					payload.contacts = contacts
				}
				const obj = _.cloneDeep(payload)
				const prev = preparePrevState(obj)
				setPrevState({ ...prev })
			}
			setLoading(false)
		}
	}
	//same logic that in advertisers in order to create a new contact.
	const handleNewContact = (type: string) => {
		const new_contact: ContactInterface = {
			name: '',
			role: '',
			email: '',
			phone: '',
			skype: '',
			geo: [],
			type: type,
			uuid: uuidv4(),
		}

		let addition = [new_contact]
		if (contacts !== null && contacts !== undefined) {
			addition = [...contacts, new_contact]
		}

		setContacts(addition)
	}

	useEffect(() => {
		const defaultAllowedPlatforms: any = []
		for (const title of settings.settings.capabilities) {
			const obj = {
				title: title,
				value: false,
			}
			defaultAllowedPlatforms.push(obj)
		}
		const fillFields = async (row: any, cap: any) => {
			try {
				setPIDs(row!.media_source_pid)
				setName(row!.publisher_name)
				setLegalName(row!.legal_name)
				setAddress(row!.address)
				// setFinanceId(row!.finance_id)
				setSkypeGroup(row!.skype_group)
				setGeo(row!.geo)

				// setType(row!.type)
				setNotes(row!.notes)

				// console.log('contacts', decryptedContacts)
				// setContacts(decryptedContacts)
				setCapabilities(cap !== null ? cap : defaultAllowedPlatforms)
				// const prev = preparePrevState(row)
				// setPrevState({ ...prev })

				setLinkToLinkedin(row!.link_to_linkedin)
				setLinkToWebsite(row!.link_to_website)

				setExternalId(row!.external_id)
				setSelectedCountryElements(
					row!.media_buying_capabilities?.country || [],
				)

				setSelectedFraudTools(row!.media_buying_capabilities?.fraud_tools || [])
				setSelectedTypeOfPublisher(
					row!.media_buying_capabilities?.type_publisher || [],
				)
				setSelectedAdType(row!.media_buying_capabilities?.ad_type || [])
				setSelectedDevices(row!.media_buying_capabilities?.devices || [])
				setSelectedPaymentModel(
					row!.media_buying_capabilities?.payment_model || [],
				)
				setSelectedAvailablePlatforms(
					row!.media_buying_capabilities?.available_platforms || [],
				)
				setSelectedAbitilies(row!.media_buying_capabilities?.abilities || [])
				setSelectedRelevantCategories(
					row!.media_buying_capabilities?.relevant_categories || [],
				)
				setSelectedTrafficIndicators(
					row!.media_buying_capabilities?.traffic_indicators || [],
				)
				setSpecialRequestList(
					row!.media_buying_capabilities?.special_request_list || '',
				)
				setSelectedFormatTypes(
					row!.media_buying_capabilities?.format_types || [],
				)
				setSelectedDailyCap(row!.media_buying_capabilities?.daily_cap || '')
				setBadgeGoodQuality(
					row!.media_buying_capabilities?.badges?.good_quality || false,
				)
				setBadgeLowFraud(
					row!.media_buying_capabilities?.badges?.low_fraud || false,
				)
				setBadgeHighVolume(
					row!.media_buying_capabilities?.badges?.high_volume || false,
				)
				setBadgeHighCR(row!.media_buying_capabilities?.badges?.high_cr || false)
				setSelectedGeneralCountryData(
					row!.media_buying_capabilities?.country_general || [],
				)
				setPublicSkype(row!.public_skype)
				setMainCategory(row!.main_category)
				// setCurrentPasswords(row!.passwords)
				setId(row!.id)
				setCreatedBy(row!.created_by)
			} catch (err) {
				setErrorMessage('Failed to fill Publisher data.')
			}
		}

		if (row && !dataFilled) {
			const cap = capabilitiesFromJson(row.capabilities)
			fillFields(row, cap)
			const obj = _.cloneDeep(row)
			const prev = preparePrevState(obj)

			setPrevState({ ...prev })
			setDataFilled(true)
		}
	}, [row])

	useEffect(() => {
		const obj = _.cloneDeep(row)
		const prev = preparePrevState(obj)
		if (!prevState) {
			setPrevState({ ...prev })
		}
		// console.log('FIRST STATE CHECK : ', row)
	}, [])

	useEffect(() => {
		const isNameValid = name.trim() !== ''
		const isLegalNameValid = legalName.trim() !== ''
		const isAddressValid = address.trim() !== ''
		const isGeoValid = geo.length > 0

		const mandatoryFieldsValid = isNameValid && isLegalNameValid

		if (
			!mandatoryFieldsValid ||
			(linkToWebsite && !checkInputStartsWithHttp(linkToWebsite)) ||
			(linkToLinkedin && !checkInputStartsWithHttp(linkToLinkedin)) ||
			(name && !stringIsValidName(name))
		) {
			setIsValid(false)
		} else {
			setIsValid(true)
		}
	}, [linkToWebsite, linkToLinkedin, name, legalName, address, geo])

	//we use this in order to save the logs for capabilities.
	const capabilitiesFromJson = (capabilities: any[]) => {
		let result: any[] = []
		capabilities?.forEach((c) => {
			if (typeof c === 'string') {
				return result.push(JSON.parse(c))
			} else result.push(c)
		})
		return result
	}

	useEffect(() => {
		if (externalId) {
			const checkIfRepeat = publisher.publisher.some(
				(pub: any) =>
					pub.external_id !== null &&
					pub.external_id === parseInt(externalId as unknown as string) &&
					pub?.publisher_name !== name,
			)
			setExternalIdInUse(checkIfRepeat)
		}
	}, [externalId])

	const closeDetailedView = () => {
		navigate('/companySettings')
		nullifyFields()
		setPopUpVisible(false)
	}
	const openPreview = () => {
		navigate('/companySettings')
		nullifyFields()
	}

	const handleOpenUploadModal = () => setIsUploadModalOpen(true)

	const handleIconClick = () => {
		if (iconUrl && iconUrl !== defaultIcon) {
			setDeleteModalOpen(true)
		}
	}

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false)
	}

	const handleUploadSuccess = (filename: string) => {
		setUploadStatus({
			message: `${filename} uploaded successfully`,
			success: true,
		})
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 3000)
	}

	const handleUploadError = (filename: string) => {
		setUploadStatus({ message: `Error uploading ${filename}`, success: false })
		setTimeout(() => {
			setIsUploadModalOpen(false)
			setUploadStatus(null)
		}, 3000)
	}

	const handleDeleteIcon = () => {
		if (iconUrl !== defaultIcon) {
			// const headers = { Authorization: `Bearer ${login.user.token}` }
			deleteIcon('PUBLISHER', row.publisher_name, row.id)
			setDeleteModalOpen(false)
			setIconUrl(defaultIcon)
		}
	}

	const handleFileUpload = async (files: any) => {
		if (files.length > 0) {
			const file = files[0]
			if (!isValidImageType(file)) {
				setErrorMessage(
					'Unsupported file type. Please upload JPEG, PNG, or SVG files.',
				)
				return
			}
			// const headers = {
			// 	Authorization: `Bearer ${login.user.token}`,
			// }

			const uploadResult = await uploadIcon(
				'PUBLISHER',
				file,
				row?.publisher_name,
				row?.id,
			)
			setIconUrl(uploadResult.toString())
		}
	}

	const tabOptions = [
		{ label: 'Company Information', tabIndex: 0 },
		{ label: 'Capabilities', tabIndex: 1 },
	]

	return (
		<ThemeProvider theme={theme}>
			<EditPageHeader
				onBack={closeDetailedView}
				onPreview={openPreview}
				onUpload={handleOpenUploadModal}
				tabValue={tab}
				handleChangeTab={handleChangeTab}
				hasPreview={!!(row && permissions.preview && params.id)}
				options={tabOptions}
				customStyles={editPageTopPanelStyle}
				arialabel='basic tabs'
			/>

			<CustomModal
				title='Upload Icon'
				open={isUploadModalOpen}
				onClose={() => setIsUploadModalOpen(false)}
			>
				{uploadStatus ? (
					<Box
						display='flex'
						flexDirection='column'
						alignItems='center'
						justifyContent='center'
						p={2}
					>
						<Typography variant='subtitle1'>{uploadStatus.message}</Typography>
						<CheckCircle style={{ color: 'green', fontSize: 40 }} />
					</Box>
				) : (
					<CustomFileUploader
						onSetFiles={handleFileUpload}
						onSuccess={handleUploadSuccess}
						onError={handleUploadError}
						acceptedFiles={ACCEPTED_FILES.IMAGES_ONLY}
					/>
				)}
			</CustomModal>
			<CompanyInformationHeader
				name={row?.publisher_name}
				iconUrl={handleIconUrl(iconUrl, defaultIcon)}
				onClickIcon={
					iconUrl !== defaultIcon && iconUrl ? handleIconClick : undefined
				}
				tooltipText={
					iconUrl === defaultIcon || !iconUrl
						? 'No icon to delete'
						: 'Click to delete icon'
				}
				cursorStyle={
					iconUrl === defaultIcon || !iconUrl ? 'default' : 'pointer'
				}
				style={{
					display: 'flex',
					alignContent: 'center',
					marginLeft: '6%',
					marginTop: '1%',
				}}
			/>

			<CustomDialog
				open={isDeleteModalOpen}
				onClose={handleCloseDeleteModal}
				onConfirm={handleDeleteIcon}
				imageUrl={handleIconUrl(iconUrl, defaultIcon)}
				message='Click delete to remove the icon.'
			/>

			<PageWrapper
				style={{
					marginTop: '0.1vh',
				}}
			>
				<TabPanel value={tab} index={0}>
					<PublisherCompanyInfoComponent
						name={name}
						setName={setName}
						legalName={legalName}
						setLegalName={setLegalName}
						address={address}
						setAddress={setAddress}
						geo={geo}
						setGeo={setGeo}
						setPIDs={setPIDs}
						pids={pids}
						skypeGroup={skypeGroup}
						setSkypeGroup={setSkypeGroup}
						isValid={isValid}
						settings={settings.settings.vertical ? settings : []}
						// externalId={externalId}
						// setExternalId={setExternalId}
						externalIdInUse={externalIdInUse}
						setMainHeightChange={setMainHeightChange}
						linkToLinkedin={linkToLinkedin}
						setLinkToLinkedin={setLinkToLinkedin}
						linkToWebsite={linkToWebsite}
						setLinkToWebsite={setLinkToWebsite}
						notes={notes}
						setNotes={setNotes}
						publicSkype={publicSkype}
						setPublicSkype={setPublicSkype}
						mainCategory={mainCategory}
						setMainCategory={setMainCategory}
						mandatoryFields={mandatoryFields}
						permissions={permissions}
						agencyAccess={agencyAccess}
						setAgencyAccess={setAgencyAccess}
					/>
					{canViewContacts && (
						<>
							<ContactsComponent
								contacts={contacts}
								setContacts={setContacts}
								handleNewContact={handleNewContact}
								loginRole={login.user.role}
								publisherName={name}
								setErrorMessage={setErrorMessage}
								p360active={false}
								setIsSuccessPromptOpened={setIsSuccessPromptOpened}
								owner={companyData?.ownerEmail}
								ruleSet={{}}
							></ContactsComponent>
						</>
					)}
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<PublisherAllowedPlatformsComponent
						capabilities={capabilities}
						setCapabilities={setCapabilities}
						setMainHeightChange={setMainHeightChange}
						vertical={vertical}
						setVertical={setVertical}
						publisherCapabilitiesMap={publisherCapabilitiesMap}
						notes={notes}
						setNotes={setNotes}
					/>
				</TabPanel>

				<DialogActions
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 160,
					}}
				>
					<UnborderedButton
						onClick={() => {
							nullifyFields()
							navigate('/companySettings')
							setPopUpVisible(false)
						}}
						label={'Cancel'}
					></UnborderedButton>
					{permissions.edit && (
						<Tooltip
							title={
								!isValid
									? 'Please fill in all mandatory fields: Company Name, Legal Name.'
									: ''
							}
							placement='top'
						>
							<span>
								<SubmitButton
									disabled={!isValid}
									onClick={() => trySubmit(true, row)}
								>
									{row ? 'Update' : 'Save'}
								</SubmitButton>
							</span>
						</Tooltip>
					)}
				</DialogActions>
			</PageWrapper>
		</ThemeProvider>
	)
}

export default PublisherCompanyEdit
