import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AxiosCustom from '../utils/Axios'
import { endpoints } from '../config'

const LMSRedirect = () => {
	let newTab: any;
	const navigate = useNavigate(); 
	useEffect(() => {
		const getRedirectUrl = async () => {
			try {
				const res = await AxiosCustom.post(endpoints.REDIRECT_TO_LMS)
				if (res && res.data && res.data.successful) {
					if (!newTab || newTab.closed) {
						newTab = window.open(res.data.payload, "_blank");
					} else {
						newTab.focus(); // Focus the existing tab if already opened
					}
					navigate('/news')
				}
			} catch (err) {
				console.error(err)
				return null
			}
		}
		getRedirectUrl()
	}, [])

	return (
		<div>
			<p>Redirecting to LMS...</p>
		</div>
	)
}

export default LMSRedirect
