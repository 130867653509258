import { Dispatch } from 'redux'
import { ActionType } from '../action-types'
import { Action } from '../actions/index'
import AxiosCustom from '../../utils/Axios'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { endpoints } from '../../config'

export const getLogsAction = () => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const { data } = await AxiosCustom.get(endpoints.LOGS, {
				headers: {
					'x-permission-name': permissionNames.VIEW_LOGS,
				},
			})
			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_LOGS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const getLogsByDateAction = (
	// headers: any,
	from_date: Date,
	to_date: Date,
) => {
	return async (dispatch: Dispatch<Action>) => {
		try {
			const payload = { logs: { from_date, to_date } }
			const { data } = await AxiosCustom.post(endpoints.LOGS, payload, {
				headers: {
					'x-permission-name': permissionNames.VIEW_LOGS,
				},
			})

			if (!data.successful) {
				return
			}

			dispatch({
				type: ActionType.GET_LOGS,
				payload: data.payload,
			})
		} catch (err) {
			console.error(err)
		}
	}
}

export const insertLog = (obj: any) => {
	console.log('called insertLog: ', obj.change)
	return async (dispatch: Dispatch<Action>) => {
		try {
			const log = {
				log: obj,
			}
			const { data } = await AxiosCustom.post(endpoints.LOGS + '/add', log, {
				headers: {
					'x-permission-name': permissionNames.VIEW_LOGS,
				},
			})

			if (!data.successful) {
				return
			}
			dispatch({
				type: ActionType.INSERT_LOG,
				payload: data.payload,
			})
			return
		} catch (err) {
			console.error(err)
		}
	}
}
