import React, { useEffect, useState } from 'react'
import { Tooltip, Typography, useTheme } from '@mui/material'
import EasterEggTag from './EasterEggTag'
interface CompanyInformationHeaderProps {
	name: string | null
	iconUrl?: string | null
	showTooltip?: boolean
	tooltipText?: string
	style?: React.CSSProperties
	onClickIcon?: () => void
	cursorStyle?: 'pointer' | 'default' | 'none'
	easterEggShow?: any
}

const CompanyInformationHeader: React.FC<CompanyInformationHeaderProps> = ({
	name,
	iconUrl,
	showTooltip = true,
	tooltipText,
	style,
	onClickIcon,
	cursorStyle = 'default',
	easterEggShow,
}) => {
	const theme = useTheme()
	const [easterEgg, setEasterEgg] = useState(name === 'Joaquin Escudero')
	const imageStyle = {
		height: '30px',
		width: '30px',
		borderRadius: '8px',
		cursor: cursorStyle,
	}

	return (
		<div
			style={{
				color: theme.colors.text.titles,
				fontSize: '1rem',
				fontWeight: 'bold',
				display: 'flex',
				alignItems: 'center',
				marginBottom: 4,
				gap: '0.7rem',
				...style,
			}}
		>
			{iconUrl ? (
				showTooltip && tooltipText ? (
					<Tooltip title={tooltipText}>
						<img
							alt='icon'
							src={iconUrl}
							style={imageStyle}
							onClick={onClickIcon}
						/>
					</Tooltip>
				) : (
					<img
						alt='icon'
						src={iconUrl}
						style={imageStyle}
						onClick={onClickIcon}
					/>
				)
			) : (
				<div
					style={{
						height: '30px',
						width: '30px',
						backgroundColor: 'grey',
						borderRadius: '50%',
					}}
				/>
			)}
			{easterEggShow && easterEgg ? (
				<EasterEggTag name={name} />
			) : (
				<div style={{ display: 'inline', minHeight: '32px' }}>
					<Typography variant='h6'>{name}</Typography>
				</div>
			)}
		</div>
	)
}

export default CompanyInformationHeader
