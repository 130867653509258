import { ThemeProvider } from '@emotion/react'
import Box from '@mui/material/Box'
import React, {
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react'
import {
	NavigateFunction,
	Outlet,
	useLocation,
	useNavigate,
} from 'react-router-dom'
import logo from '../assets/varys-logo-white.png'
import { FirstLoading, Loading } from '../assets/svg/loading'
import {
	usePublisherActions,
	useCampaignsActions,
	useAdvertiserActions,
	useUserActions,
	useRoleActions,
	useSettingsActions,
	useLogActions,
	useCreatorActions,
} from '../hooks/useActions'
import { CLIENT_VERSION } from '../config'
import { useActions } from '../hooks/useActions'
import { useTypedSelector } from '../hooks/useTypedSelector'
import { UserInterface } from '../models/model.interface'
import { UserNameWithAvatar } from '../pages/components/reusableComponents'
import { TableSearchComponent } from '../pages/components/Search'
import { getVersionAction } from '../state/action-creators'
import { getAdjustedTitle } from '../utils/helpers/helperFuncs'
import { ErrorPrompt } from '../utils/notifications/ErrorPrompt'
import { SuccessPrompt } from '../utils/notifications/SuccessPrompt'
import { WarningPrompt } from '../utils/notifications/WarningPrompt'
import { MenuComponent, NavbarButton } from './NavBarStyled'
import { UserMessagePrompt } from '../utils/notifications/UserMessagePrompt'
import { useTheme } from '@mui/material'
import { useIsAuthorized } from '../hooks/useIsAuthorized'
import { permissionNames } from '../utils/helpers/permissionsHelper'

export function extractRootPath(pathname: string): string {
	return pathname.split('/')[1]
}

export const NavbarContainer = (props: {
	isErrorPromptOpened: any
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	isWarningPromptOpened: any
	setIsWarningPromptOpened: any
	isUserMessagePromptOpened: any
	setIsUserMessagePromptOpened: any
	setUserMessage: any
	errorMessage: any
	setErrorMessage: any
	successMessage: any
	setSuccessMessage: any
	userMessage: any
	PopUpVisible: boolean
	setPopUpVisible: any
	forcedFilterStatus: number
	setForcedFilterStatus: any
	isAuthorized: any
	setFirstLoadingDone: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
	setLoading: Dispatch<SetStateAction<boolean>>
}) => {
	const { users, login, avatars } = useTypedSelector((state) => {
		return state
	})
	const navigate = useNavigate()
	const location = useLocation()
	const theme = useTheme()
	const [currentpage, setCurrentpage] = useState(location.pathname)
	const [searchPlaceholder, setSearchPlaceholder] = useState('')
	const {
		getAppsAction,
		getPasswordsAction,
		getAvatars,
		getDashboardAction,
		getAccountsAction,
		getAppsIcons,
		getp360aggregated,
		getSalesPlanAppsAction,
		getHandshakeAction,
		// getPermissions,
	} = useActions()

	const { getPublisherWishList, getPublisherAction } = usePublisherActions()
	const { getCampaignHistoryAction, getCampaignAction } = useCampaignsActions()
	const { getAdvertiserAction } = useAdvertiserActions()
	const { usersAction, logOutAction, getLoggedInUser } = useUserActions()
	const { fetchRoles } = useRoleActions()
	const { getSettingsAction } = useSettingsActions()
	const { getLogsAction } = useLogActions()
	const { getCreatorsAction } = useCreatorActions()

	// if (!login.user.token) {
	// 	navigate('/')
	// }

	// const checkVersion = async () => {
	// 	// const fheaders = {
	// 	// 	Authorization: `Token ${login.user.token}`,
	// 	// }
	// 	const result = await getVersionAction(CLIENT_VERSION)
	// 	if (
	// 		result !== undefined &&
	// 		(result.data.successful !== true || result.data.payload.version === false)
	// 	) {
	// 		sessionStorage.clear()
	// 		window.location.reload()
	// 	} else if (result?.data.payload.under_maintenance === true) {
	// 		// console.log('testttt')
	// 		// setUnderMaintenance(true)
	// 	}
	// }
	// useEffect(() => {
	// 	checkVersion()
	// }, [])

	useEffect(() => {
		const loggedUser = users?.users?.find(
			(user: UserInterface) => user.email === login.user.email,
		)
		if (loggedUser && loggedUser.blocked) {
			handleLogout()
		}
		const currentPath = '/' + window.location.pathname.split('/')[1]
		if (currentPath !== currentpage) {
			if (
				currentPath !== '/aggregated' &&
				currentPath !== '/network' &&
				currentPath !== '/rawData' &&
				currentPath !== '/invalid' &&
				currentPath !== '/adjust' &&
				currentPath !== '/logs' &&
				currentPath !== '/settings' &&
				currentPath !== '/mediaplan'
			) {
				setCurrentpage(window.location.pathname)
			}
		}
		if (
			window.location.pathname !== '/campaigns' &&
			props.forcedFilterStatus !== 1
		) {
			props.setForcedFilterStatus(1)
		}
	}, [window.location.pathname])

	useEffect(() => {
		const token = login.user.token
		if (token) {
			const currentSubdomain = window.location.hostname.split('.')[0]
			const isProd = window.location.hostname.includes('varys.io')
			const isStage = window.location.hostname.includes('stage.varys.io')
			const expectedSubdomain = login.company.subdomain
			//TODO: for now the landing page by default is still news but we will change it eventually.
			if (currentSubdomain !== expectedSubdomain) {
				window.location.href = `http${
					isProd ? 's' : ''
				}://${expectedSubdomain}.${
					isProd ? (isStage ? 'stage.varys.io' : 'varys.io') : 'localhost:3000'
				}/news`
			}
		}
	}, [])

	useEffect(() => {
		if (props.errorMessage !== null) {
			props.setIsErrorPromptOpened(true)
		}
	}, [props.errorMessage])

	const searchPlaceholderMap = [
		{ endpoint: '/campaigns', placeholder: 'Campaigns' },
		{ endpoint: '/advertisers', placeholder: 'Advertisers' },
		{ endpoint: '/publishers', placeholder: 'Supply' },
		{ endpoint: '/creators', placeholder: 'Creators' },
		{ endpoint: '/apps', placeholder: 'Apps' },
		{ endpoint: '/logs', placeholder: 'Logs' },
		{ endpoint: '/users', placeholder: 'Users' },
		{ endpoint: '/autoswitch', placeholder: 'Autoswitch' },
		{ endpoint: '/passwords', placeholder: 'Passwords' },
		{ endpoint: '/documents', placeholder: 'Documents' },
		{ endpoint: '/finance/control', placeholder: 'Finance Control' },
		{ endpoint: '/finance/personal', placeholder: 'Finance Personal' },
		{ endpoint: '/finance/supply', placeholder: 'Finance Supply' },
		{ endpoint: '/finance/demand', placeholder: 'Finance demand' },
		{ endpoint: '/finance/global', placeholder: 'Finance Global' },
		{ endpoint: '/finance/mediabuying', placeholder: 'Finance Media Buying' },
		{
			endpoint: '/finance/creativesupply',
			placeholder: 'Finance Creative Supply',
		},
		{ endpoint: '/finance/creative', placeholder: 'Finance Creative' },
		{ endpoint: '/finance/balancein', placeholder: 'Finance Balance In' },
		{ endpoint: '/finance/balanceout', placeholder: 'Finance Balance Out' },
		{ endpoint: '/p360apps', placeholder: 'P360 Apps' },
	]
	const disabledSearchMap = ['-demand-']

	const prevPathname = useRef(extractRootPath(location.pathname))

	useEffect(() => {
		const loadCurrentSettingsAndUsers = async () => {
			await getLoggedInUser()
			await getSettingsAction()
			// await usersAction()
			// await getPermissions()
		}
		loadCurrentSettingsAndUsers()
		// props.setSearch(props.search)
		let newPlaceholder = ''
		let searchDisabled = false
		for (const disabled of disabledSearchMap) {
			if (location.pathname.includes(disabled)) {
				searchDisabled = true
				break
			}
		}
		if (!searchDisabled) {
			for (const possiblePlaceholder of searchPlaceholderMap) {
				if (location.pathname.startsWith(possiblePlaceholder.endpoint)) {
					newPlaceholder = possiblePlaceholder.placeholder
					break
				}
			}
		}
		setSearchPlaceholder(newPlaceholder)
		const currentPathnameRoot = extractRootPath(location.pathname)
		if (prevPathname.current !== currentPathnameRoot) {
			// If the root route has changed, reset search to an empty string
			props.setSearch('')
		} else {
			// Otherwise, set it to the props.search value
			props.setSearch(props.search)
		}
		prevPathname.current = currentPathnameRoot
	}, [location.pathname, props.search])

	const handleLogout = async () => {
		try {
			await logOutAction()
		} catch (error) {
			console.error('Error in logout action:', error)
		}
	}
	const [showAvatarMenu, setShowAvatarMenu] = useState(false)
	const [avatarMenuIsSelected, setAvatarMenuIsSelected] = useState(false)
	const [showSubMenu, setShowSubMenu] = useState(false)
	const pathname = window.location.pathname

	const handleAvatarClick = () => {
		setShowAvatarMenu(!showAvatarMenu)
		setShowSubMenu(false)
	}
	const settingsArr = [
		useIsAuthorized(permissionNames.VIEW_SETTINGS),
		useIsAuthorized(permissionNames.ADD_EDIT_USER_PERMISSIONS),
	]
	const settingsPermissions = settingsArr.some(Boolean)
	const map = [
		{
			options: [
				{ endpoint: '/logs', title: 'Logs', rule: 'VIEW_LOGS' },
				{
					endpoint: '/settings',
					title: 'Settings',
					rule: settingsPermissions,
				},
				{ endpoint: '/users', title: 'Users', rule: 'VIEW_USERS' },
				{
					endpoint: '/personalSettings',
					title: 'Personal',
				},
				{
					endpoint: '/companySettings',
					title: 'Company Settings',
					rule: 'EDIT_COMPANY_DATA',
				},
				{ endpoint: handleLogout, title: 'Log Out' },
			],
			name: '_Avatar',
		},
	]

	const companyType = login.company.type
	const avatarRoutesMap = generateRouteMap(useIsAuthorized, map, companyType)
	useEffect(() => {
		let found = false
		if (showAvatarMenu) {
			found = true
		} else {
			for (const option of avatarRoutesMap[0].options) {
				if (option.endpoint === pathname) {
					found = true
					break
				}
			}
		}
		setAvatarMenuIsSelected(found)
	}, [pathname, showAvatarMenu])

	return (
		<ThemeProvider theme={theme}>
			<Box sx={{ display: 'flex' }} style={{ visibility: 'visible' }}>
				<div
					style={{
						display: 'flex',
						height: '15vh',
						width: '100%',
						flexDirection: 'column',
					}}
				>
					<div
						className='gradient-container'
						style={{
							width: '100vw',
							height: 'inherit',
							display: 'flex',
							justifyContent: 'flex-end',
							flexDirection: 'column',
							borderRadius: '0px',
							backgroundImage: theme.colors.gradientMenu,
							backgroundSize: '100% 100%',
							backgroundPosition: '0px 0px',
							zIndex: '12',
							position: 'relative',
							backgroundBlendMode: 'multiply',
						}}
					>
						<div
							style={{
								height: '9vh',
								display: 'flex',
								alignItems: 'center',
								padding: '0rem 10rem 0rem 4rem',
								justifyContent: 'space-between',
							}}
						>
							<img src={logo} alt={'logo'} style={{ height: '90%' }}></img>
							<div
								style={{
									display: 'flex',
									width: '100%',
								}}
							>
								<TableSearchComponent
									style={{
										width: '36%',
										boxShadow: 'none',
										border: '1px solid rgba(0,0,0,0.12)',
										borderRadius: theme.customComponents.input.borderRadius,
										marginTop: '9px',
										marginLeft: '7vw',
										opacity: !searchPlaceholder ? '0.4' : '1',
										transition: '0.6s ease',
										color: theme.colors.base.white,
									}}
									setSearch={props.setSearch}
									search={props.search}
									// filterHandler={filterHandler}
									searchWithTimer={false}
									presetSearch={props.presetSearch}
									placeholder={searchPlaceholder}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									width: 200,
								}}
							>
								{login.user.name && (
									<UserNameWithAvatar
										data-tag={'_navbarButton'}
										// withoutName
										avatars={avatars.avatars}
										value={login.user.name}
										position={login.user.position}
										country={login.user.location}
										onClick={handleAvatarClick}
										selected={avatarMenuIsSelected}
									/>
								)}
							</div>
						</div>
						<NavbarRoutes
							setPopUpVisible={props.setPopUpVisible}
							isAuthorized={props.isAuthorized}
							setCurrentpage={setCurrentpage}
							navigate={navigate}
							avatarMenu={avatarRoutesMap[0]}
							showSubMenu={showSubMenu}
							setShowSubMenu={setShowSubMenu}
							setLoading={props.setLoading}
						/>
						{showAvatarMenu && (
							<SubMenu
								currentSubMenu={avatarRoutesMap[0]}
								subtitlesMap={{}}
								navigate={navigate}
								setShowSubMenu={setShowAvatarMenu}
								style={{ bottom: '-43%', zIndex: '20' }}
								setPopUpVisible={props.setPopUpVisible}
								setLoading={props.setLoading}
							/>
						)}
					</div>
				</div>
			</Box>

			<Box component='main' sx={{ width: '100%', height: '0px' }}>
				{/* <Toolbar  /> */}
				<UserMessagePrompt
					PopUpVisible={props.isUserMessagePromptOpened}
					setPopUpVisible={props.setIsUserMessagePromptOpened}
					userMessage={props.userMessage}
					setUserMessage={props.setUserMessage}
				/>
				<ErrorPrompt
					errorMessage={props.errorMessage}
					setErrorMessage={props.setErrorMessage}
					PopUpVisible={props.isErrorPromptOpened}
					setPopUpVisible={props.setIsErrorPromptOpened}
					handleLogout={handleLogout}
				/>
				<SuccessPrompt
					message={
						props.successMessage ? props.successMessage : 'UPDATE SUCCESSFUL'
					}
					PopUpVisible={props.isSuccessPromptOpened}
					setPopUpVisible={props.setIsSuccessPromptOpened}
					setMessage={props.setSuccessMessage}
				/>
				<WarningPrompt
					message={
						'Refreshing the Data may take some time (approximately 2-15 seconds)'
					}
					PopUpVisible={props.isWarningPromptOpened}
					setPopUpVisible={props.setIsWarningPromptOpened}
				/>

				<Outlet />
			</Box>
		</ThemeProvider>
	)
}
const NavbarRoutes = (props: {
	setPopUpVisible: any
	isAuthorized: any
	setCurrentpage: any
	navigate: any
	avatarMenu: any
	showSubMenu: boolean
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	setLoading: Dispatch<SetStateAction<boolean>>
}) => {
	const navigate = useNavigate()
	const theme = useTheme()
	const pathname = window.location.pathname
	const [currentSubMenu, setCurrentSubMenu] = useState<any>({})

	const { login } = useTypedSelector((state) => state)
	const companyType = login.company.type
	const hasLmsAccess = [!!login.company.lms_company_id, useIsAuthorized(permissionNames.VIEW_SKILLSHOP)].every(Boolean)

	const map = [
		{ options: [{ endpoint: '/news', rule: 'VIEW_NEWS' }], title: 'News' },
		//TODO: add the view_dashboard to permissions and uncomment this
		// {
		// 	options: [{ endpoint: '/dashboard', rule: 'VIEW_DASHBOARD' }],
		// 	title: 'Dashboard',
		// },
		{
			options: [{ endpoint: '/campaigns', rule: 'VIEW_CAMPAIGN' }],
			title: 'Campaigns',
		},
		{
			options: [{ endpoint: '/advertisers', rule: 'VIEW_ADVERTISER' }],
			title: 'Advertisers',
		},
		{
			options: [{ endpoint: '/publishers', rule: 'VIEW_PUBLISHER' }],
			title: 'Supply',
		},
		{
			options: [
				...(companyType === 'agency'
					? [{ endpoint: '/network', title: 'Network', rule: 'VIEW_NETWORK' }]
					: []),
				{
					endpoint: '/aggregated',
					title: 'Aggregated',
					rule: 'VIEW_AGGREGATED',
				},
				{ endpoint: '/invalid', title: 'Invalid', rule: 'VIEW_INVALID' },
			],
			title: 'Analytics',
		},

		{
			options: [
				{
					endpoint: '/finance/control',
					title: 'Control',
					rule: 'VIEW_CONTROL',
				},
				{
					endpoint: '/finance/personal',
					title: 'Personal',
					rule: 'VIEW_PERSONAL_FINANCE',
				},
				{
					endpoint: '/finance/mediabuying',
					title: 'Media Buying',
					rule: 'VIEW_MEDIA_BUYING',
				},
				{
					endpoint: '/finance/creative',
					title: 'Creative',
					rule: 'VIEW_INFLUENCERS',
				},
				{ endpoint: '/finance/global', title: 'Global', rule: 'VIEW_GLOBAL' },
				{ endpoint: '/finance/demand', title: 'Demand', rule: 'VIEW_DEMAND' },
				{ endpoint: '/finance/supply', title: 'Supply', rule: 'VIEW_SUPPLY' },
				{
					endpoint: '/finance/creativesupply',
					title: 'Creative Supply',
					rule: 'VIEW_INFLUENCERS_SUPPLY',
				},
				{
					endpoint: '/finance/balancein',
					title: 'Balance In',
					rule: 'VIEW_BALANCE_IN',
				},
				{
					endpoint: '/finance/balanceout',
					title: 'Balance Out',
					rule: 'VIEW_BALANCE_OUT',
				},
			],
			title: 'Finance',
		},
		{
			options: [
				{
					endpoint: '/autoswitch',
					title: 'Autoswitch',
					rule: 'VIEW_AUTOSWITCH',
				},
				{ endpoint: '/passwords', title: 'Passwords', rule: 'VIEW_PASSWORDS' },
				{ endpoint: '/documents', title: 'Documents', rule: 'VIEW_DOCUMENTS' },
				{ endpoint: '/p360apps', title: 'P360 (Apps)', rule: 'VIEW_APP_P360' },
				{
					endpoint: '/adsplacement',
					title: 'Ads Placement',
					rule: 'VIEW_ADS_PLACEMENT',
				},
				{ endpoint: '/redirectlms', title: 'The SkillShop', rule: hasLmsAccess },
			],
			title: 'Tools',
		},
	]

	const routesMap = generateRouteMap(props.isAuthorized, map, companyType)

	const subtitlesMap = {
		analytics: [
			{ groupName: 'Standard', endpoints: ['/network'] },
			{
				groupName: 'Advanced',
				endpoints: ['/aggregated', '/rawdata', '/invalid'],
			},
		],
	}

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				position: 'relative',
			}}
		>
			<MenuComponent
				theme={theme}
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					height: '5.5vh',
					whiteSpace: 'nowrap',
					position: 'relative',
					background: theme.colors.base.white,
					width: '100%',
					boxShadow: '0px 4px 16px -16px ' + theme.colors.base.grey900,
					borderBottom: '1px solid ' + theme.colors.base.grey50,
					borderTop: '1px solid ' + theme.colors.base.grey50,
					zIndex: '12',
					// backgroundImage: `conic-gradient(from 0deg, ${theme.new_style.primary.secondary}, ${theme.new_style.primary.secondary} 90deg, ${theme.new_style.primary.main} 180deg, ${theme.new_style.primary.main} 270deg, ${theme.new_style.primary.main} 360deg)`,
				}}
			>
				<div
					style={{
						height: 'inherit',
						width: '10rem',
						backgroundColor: 'transparent',
					}}
				></div>
				<NavbarButtonsContainer
					endpointsMap={routesMap}
					showSubMenu={props.showSubMenu}
					setShowSubMenu={props.setShowSubMenu}
					currentSubMenu={currentSubMenu}
					setCurrentSubMenu={setCurrentSubMenu}
					navigate={navigate}
					pathname={pathname}
					avatarMenu={props.avatarMenu}
					setPopUpVisible={props.setPopUpVisible}
				/>
			</MenuComponent>

			{props.showSubMenu && (
				<SubMenu
					currentSubMenu={currentSubMenu}
					subtitlesMap={subtitlesMap}
					navigate={navigate}
					setShowSubMenu={props.setShowSubMenu}
					setPopUpVisible={props.setPopUpVisible}
					setLoading={props.setLoading}
				/>
			)}
		</div>
	)
}
const NavbarButtonsContainer = ({
	endpointsMap,
	showSubMenu,
	setShowSubMenu,
	currentSubMenu,
	setCurrentSubMenu,
	navigate,
	pathname,
	avatarMenu,
	setPopUpVisible,
}: {
	endpointsMap: any
	showSubMenu: boolean
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	currentSubMenu: any
	setCurrentSubMenu: Dispatch<SetStateAction<any>>
	navigate: NavigateFunction
	pathname: string
	avatarMenu: any
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
}) => {
	const [indicatorStyle, setIndicatorStyle] = useState<any>({
		left: '0px',
		width: '0px',
	})
	const theme = useTheme()

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				height: '100%',
				justifyContent: 'space-between',
				marginRight: '10rem',
			}}
		>
			{endpointsMap.map((el: any, index: number) => {
				return (
					<SingleMenuButtonComponent
						endpoint={el}
						navigate={navigate}
						selected={pathname}
						setIndicatorStyle={setIndicatorStyle}
						key={index}
						showSubMenu={showSubMenu}
						setShowSubMenu={setShowSubMenu}
						currentSubMenu={currentSubMenu}
						setCurrentSubMenu={setCurrentSubMenu}
						avatarMenu={avatarMenu}
						setPopUpVisible={setPopUpVisible}
					/>
				)
			})}

			<div
				style={{
					position: 'absolute',
					bottom: 0,
					backgroundColor: theme.customComponents.button.green,
					width: indicatorStyle.width,
					left: 0,
					transform: `translateX(${indicatorStyle.left})`,
					transition:
						'transform 0.5s ease-in-out 0s, width 0.2s ease-in-out 0s',
					height: '18%',
					borderRadius: '20px 20px 0px 0px',
					display: 'flex',
					willChange: 'transform, width',
				}}
			/>
		</div>
	)
}
const SingleMenuButtonComponent = ({
	endpoint,
	navigate,
	selected,
	setIndicatorStyle,
	showSubMenu,
	setShowSubMenu,
	currentSubMenu,
	setCurrentSubMenu,
	avatarMenu,
	setPopUpVisible,
}: {
	endpoint: any
	navigate: NavigateFunction
	selected: string
	setIndicatorStyle: any
	showSubMenu: boolean
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	currentSubMenu: any
	setCurrentSubMenu: Dispatch<SetStateAction<any>>
	avatarMenu: any
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
}) => {
	const handleNavigation = () => {
		if (endpoint.options.length === 1 && buttonRef.current) {
			setShowSubMenu(false)
			navigate(endpoint.options[0].endpoint)
			setPopUpVisible(false)
		} else {
			if (currentSubMenu.name === endpoint.title) {
				setShowSubMenu(!showSubMenu)
			} else {
				setShowSubMenu(true)
			}
			setCurrentSubMenu({ options: endpoint.options, name: endpoint.title })
		}
	}
	const buttonRef = useRef(null)

	useEffect(() => {
		if (
			endpoint.options.length === 1 &&
			selected === endpoint.options[0].endpoint &&
			buttonRef.current
		) {
			const { offsetLeft, offsetWidth } = buttonRef.current
			const newIndication = {
				left: offsetLeft + 'px',
				width: offsetWidth + 'px',
			}
			setIndicatorStyle(newIndication)
		} else if (endpoint.options.length > 1) {
			for (const options of endpoint.options) {
				if (selected === options.endpoint && buttonRef.current) {
					const { offsetLeft, offsetWidth } = buttonRef.current
					const newIndication = {
						left: offsetLeft + 'px',
						width: offsetWidth + 'px',
					}
					setIndicatorStyle(newIndication)
				}
			}
		} else {
			for (const endpoint of avatarMenu.options) {
				if (selected === endpoint.endpoint) {
					const newIndication = {
						left: '0px',
						width: '0px',
					}
					setIndicatorStyle(newIndication)
					break
				}
			}
		}
	}, [selected])

	return (
		<>
			<NavbarButton
				key={endpoint.title}
				onClick={handleNavigation}
				ref={buttonRef}
				data-tag={'_navbarButton'}
			>
				{endpoint.title}
			</NavbarButton>
		</>
	)
}
const SubMenu = ({
	currentSubMenu,
	subtitlesMap,
	navigate,
	setShowSubMenu,
	style,
	setPopUpVisible,
	setLoading,
}: {
	currentSubMenu: any
	subtitlesMap: any
	navigate: NavigateFunction
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	style?: React.CSSProperties
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
	setLoading: Dispatch<SetStateAction<boolean>>
}) => {
	const theme = useTheme()
	const [options, setOptions] = useState<any>([])
	useEffect(() => {
		if (subtitlesMap[currentSubMenu.name.toLowerCase()]) {
			const groups: any = {}
			for (const endpoint of currentSubMenu.options) {
				for (const subtitle of subtitlesMap[
					currentSubMenu.name.toLowerCase()
				]) {
					if (subtitle.endpoints.includes(endpoint.endpoint)) {
						if (groups[subtitle.groupName]) {
							groups[subtitle.groupName].push(endpoint)
						} else {
							groups[subtitle.groupName] = [endpoint]
						}
						continue
					}
				}
			}
			const result = []
			for (const key in groups) {
				const obj = {
					title: key,
					options: groups[key],
				}
				result.push(obj)
			}
			setOptions(result)
		} else {
			setOptions([{ options: currentSubMenu.options }])
		}
	}, [currentSubMenu])

	const filterRef = useRef(null)
	const handleClickOutside = (event: any) => {
		const taggedElement = event.target.closest('[data-tag="_navbarButton"]')
		if (taggedElement) {
			if (
				filterRef.current &&
				!(filterRef.current as any).contains(taggedElement) &&
				taggedElement.dataset.tag !== '_menu' &&
				taggedElement.dataset.tag !== '_navbarButton'
			) {
				setShowSubMenu(false)
			}
		} else {
			if (
				filterRef.current &&
				!(filterRef.current as any).contains(event.target) &&
				event.target.dataset.tag !== '_menu' &&
				event.target.dataset.tag !== '_navbarButton'
			) {
				setShowSubMenu(false)
			}
		}
	}
	useEffect(() => {
		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	return (
		<div
			data-tag={'_menu'}
			style={{
				// backgroundColor: 'blue',
				backgroundColor: theme.colors.base.grey,
				height: '12vh',
				width: '100vw',
				zIndex: '12',
				display: 'flex',
				position: 'absolute',
				flexDirection: 'column',
				gap: '0px',
				boxShadow: '0px 4px 22px -20px rgba(0,0,0,1)',
				bottom: '-240%',
				// paddingLeft: '8rem',
				...style,
			}}
			ref={filterRef}
		>
			<div
				style={{
					display: 'flex',
					padding: '1rem 8rem',
					flexDirection: 'column',
					alignItems: 'flex-start',
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			>
				{options.length > 0 &&
					options.map((el: any, indx: any) => {
						return (
							<SubMenuOptions
								options={el.options}
								label={el.title}
								key={indx}
								navigate={navigate}
								setShowSubMenu={setShowSubMenu}
								setPopUpVisible={setPopUpVisible}
								setLoading={setLoading}
							/>
						)
					})}
			</div>
		</div>
	)
}
const SubMenuOptions = ({
	options,
	label,
	navigate,
	setShowSubMenu,
	setPopUpVisible,
	setLoading,
}: {
	options: any
	label?: string
	navigate: NavigateFunction
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
	setLoading: Dispatch<SetStateAction<boolean>>
}) => {
	const theme = useTheme()
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '14px',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			{label && (
				<span
					style={{
						display: 'flex',
						color: theme.colors.text.grey,
						padding: '8px 14px',
						fontSize: theme.font.size.button,
						fontWeight: theme.font.weight.normal,
						width: '5vw',
					}}
				>
					{label}:
				</span>
			)}
			{options.map((el: any) => {
				return (
					<SubMenuButton
						endpoint={el.endpoint}
						title={el.title}
						key={el.endpoint}
						navigate={navigate}
						setShowSubMenu={setShowSubMenu}
						setPopUpVisible={setPopUpVisible}
					/>
				)
			})}
		</div>
	)
}
const SubMenuButton = ({
	endpoint,
	title,
	navigate,
	setShowSubMenu,
	setPopUpVisible,
}: {
	endpoint: string | Function
	title: string
	navigate: NavigateFunction
	setShowSubMenu: Dispatch<SetStateAction<boolean>>
	setPopUpVisible: Dispatch<SetStateAction<boolean>>
}) => {
	const theme = useTheme()
	return (
		<button
			onClick={() => {
				if (typeof endpoint === 'string') {
					setTimeout(() => navigate(endpoint), 50)
					setPopUpVisible(false)
				} else if (typeof endpoint === 'function') {
					endpoint()
				}
				setShowSubMenu(false)
			}}
			style={{
				padding: '8px 14px',
				border: 'none',
				cursor: 'pointer',
				color: theme.colors.base.grey900,
			}}
		>
			·{' '}
			<span
				style={{
					textDecoration: 'underline',
					fontSize: theme.font.size.buttons,
					fontWeight: theme.font.weight.normal,
					color: theme.colors.base.grey900,
				}}
			>
				{title}
			</span>
		</button>
	)
}

/**
 * Generates a filtered and adjusted route map based on company type and user authorization.
 *
 * @param isAuthorized - A function that checks if a user is authorized for a given rule
 * @param routesMap - The original map of routes and their options
 * @param companyType - The type of company (e.g., 'advertiser', 'publisher', 'creator', 'agency')
 * @returns An adjusted map of routes, filtered based on company type and user authorization
 */
const generateRouteMap = (
	isAuthorized: (rule: string) => boolean,
	routesMap: any[],
	companyType: string,
) => {
	// Define routes to be filtered out for each company type
	const filteredRoutesByCompanyType: Record<string, string[]> = {
		advertiser: ['Advertisers', 'Finance', 'Dashboard'],
		publisher: ['Supply', 'Finance', 'Advertisers', 'Dashboard'],
		creator: ['Supply', 'Finance', 'Advertisers', 'Dashboard'],
		agency: ['Company Settings', 'Dashboard'],
	}

	const validCompanyType =
		companyType as keyof typeof filteredRoutesByCompanyType

	const adjustedMap = routesMap.reduce((acc: any[], item: any) => {
		const itemTitle = item.title || item.name

		// Check if the main route should be included
		const shouldIncludeItem =
			!filteredRoutesByCompanyType[validCompanyType].includes(itemTitle)

		if (shouldIncludeItem) {
			const adjustedItem = {
				...item,
				title: getAdjustedTitle(itemTitle, companyType),
				options: item.options?.reduce((optAcc: any[], option: any) => {
					const optionTitle = option.title || option.name

					// Check if the option should be included
					if (
						!filteredRoutesByCompanyType[validCompanyType].includes(optionTitle)
					) {
						const adjustedOption = {
							...option,
							title: optionTitle
								? getAdjustedTitle(optionTitle, companyType)
								: null,
						}
						// Only include the option if the user is authorized or if no rule is specified
						if (
							[undefined, null].includes(adjustedOption.rule) ||
							isAuthorized(adjustedOption.rule)
						) {
							optAcc.push(adjustedOption)
						}
					}
					return optAcc
				}, []),
			}

			acc.push(adjustedItem)
		}
		return acc
	}, [])
	const sanitazed = adjustedMap.filter((el: any) => {
		return el.options.length > 0
	})
	return sanitazed
}
