import moment from 'moment'
import { itemExists, padTo2Digits } from './tableHelper'
import { ContactInterface } from '../../pages/components/ContactComponent'
import { decryptMessageForDataSplit } from '../../crypto/cryptoUtils'
import { v4 as uuidv4 } from 'uuid'
import { Dayjs } from 'dayjs'
import { ENCRYPTION_PREFIX, THING_OR_TWO_COMPANY_ID } from '../../config'
import Papa from 'papaparse'
import { store } from '../../state'
// import { Order } from '../../pages/reports/common/CustomReportTable'

export const emailToUserConvertor = (email: string): string => {
	const userNamePart = email.replace('@thing02.com', '')
	const capitalized =
		userNamePart.charAt(0).toUpperCase() + userNamePart.slice(1)
	const noDots = capitalized.replace('.', ' ')
	if (noDots.includes(' ')) {
		const arr = noDots.split(' ')
		const result = []
		for (const el of arr) {
			result.push(emailToUserConvertor(el))
		}
		return result.join(' ') + '.'
	}
	return capitalized
}

//main function to generate nodes for the checkboxes. Mainly used for publisher media capabilities.
export const generateNodesFromArray = (arr: string[], structure: any) => {
	try {
		const baseNodes = arr.map((value: string) => {
			const obj = {}
			for (const [key] of Object.entries(structure)) {
				if (key === 'value' || key === 'label') {
					Object.assign(obj, { [key]: value })
				} else {
					Object.assign(obj, { [key]: '' })
				}
			}
			return obj
		})
		return baseNodes
	} catch (error) {
		console.error(error)
		return []
	}
}
interface User {
	email: string
	team_leader: string
	role: string
	name: string
}
export const canAccessResource = (
	user: User,
	owner: string,
	users: any,
	salesRep?: string,
): boolean => {
	const userEmail = user.email
	const userRole = user.role

	if (!userEmail || !owner) return false // no user, return false
	// if (['super'].includes(userRole.toLowerCase())) return true // user is super, return true
	if (owner === userEmail) return true // user is the owner, return true

	if (salesRep) {
		const salesUser = users.users.find((user: User) => {
			return user.name === salesRep
		})
		if (salesUser && salesUser.email === userEmail) return true // user is the sales representative, return true
	}

	const resourceOwner = users.users.find((user: User) => user.email === owner)
	if (resourceOwner && resourceOwner.team_leader === userEmail) return true // the user is the team leader of the owner, return true

	return false
}

export const hasOwnerAccess = (
	entity: any,
	permissions?: any,
	action?: string,
) => {
	const state = store.getState()
	const login = state.login
	const users = state.users
	const company = state.company
	// console.log(login, company)
	if (entity?.company_id === login?.user?.company_id) {
		// If the company IDs match
		const owner = entity.email
		// Owner / Sales Rep / TL access
		if (canAccessResource(login.user, owner, users)) {
			return true
		}
		// console.log(permissions, action)
		// Permission Access
		if (permissions && action && permissions[action]) {
			return true
		}
	}
	// Check if the user is from Thing or Two and the row is not
	const isThingOrTwoAdmin = login?.user?.company_id === THING_OR_TWO_COMPANY_ID
	const isDifferentCompany = entity?.company_id !== THING_OR_TWO_COMPANY_ID

	if (isThingOrTwoAdmin && isDifferentCompany) {
		// Check if the company has agency access
		const entityCompany = company.companies.find((item: any) => {
			return item.id === entity.company_id
		})
		return entityCompany?.agency_access || false
	}
	return false
}

export const filterContactsByType = (arr: any, type: string) => {
	if (!arr || arr.length === 0) return []
	const filtered: string[] = []
	arr.forEach((el: any) => {
		if (el.type === type) filtered.push(el.email)
	})
	return filtered.sort()
}

export function formatDate(date: Date) {
	let dateCopy = new Date(date)
	let offset = 0

	if (date && date.getUTCHours() > 12) {
		offset = 1
		dateCopy.setUTCDate(dateCopy.getUTCDate() + offset)
	}
	return [
		dateCopy.getUTCFullYear(),
		padTo2Digits(dateCopy.getUTCMonth() + 1),
		padTo2Digits(dateCopy.getUTCDate()),
	].join('-')
}

export function stringIsValidName(string: string) {
	if (
		string.includes('/') ||
		string.endsWith(' ') ||
		string.startsWith(' ') ||
		string.includes('%') ||
		string.includes('.') ||
		string.includes(',') ||
		string.length === 0
	) {
		return false
	}
	return true
}
export function checkValidBudgets(daily: number, monthly: number) {
	if (daily > 0 && monthly > 0) {
		return daily > monthly
	}
	return false
}
export function isValidEmail(email: string) {
	return email.includes('@')
}

export const makeDictionaryFromEntries = (object: any) => {
	return Object.assign(
		{},
		...Object.entries(object).map((x) => ({
			[x[0]]: x[1],
		})),
	)
}

export const makeDictionaryByKey = (array: any, key: string) => {
	const result: Record<string, any[]> = Object.assign(
		{},
		...array.map((x: any) => ({
			[x[key]]: [],
		})),
	)
	for (var i = 0; i < array.length; i++) {
		if (array[i][key]) {
			if (!itemExists(Object.values(result), array[i])) {
				for (const [keys, values] of Object.entries(result)) {
					if (keys === array[i][key] && result[array[i][key]]) {
						result[array[i][key]].push(array[i])
					}
				}
			}
		}
	}
	return result
}

export const formatDateToMonth = (inputDate: string) => {
	if (!inputDate) return null
	return moment(inputDate, 'DD/MM/YYYY').format('MMM YYYY')
}

export const getMonthAbbreviation = (dateStr: string) => {
	if (!dateStr) return null
	const month = moment(dateStr, 'DD/MM/YYYY').format('MMM')
	return month
}
export const getMonth = (dateStr: string) => {
	if (!dateStr) return null

	const month = moment(dateStr, 'DD/MM/YYYY').format('MMMM')
	return month
}
export const getYear = (dateStr: string) => {
	if (!dateStr) return null
	const year = moment(dateStr, 'DD/MM/YYYY').format('YYYY')
	return year
}

export const handleFinanceStatusColor = (value: string, theme: any) => {
	if (!value) {
		return ''
	}
	const valueToCheck = value.toLowerCase()
	if (valueToCheck === 'yes') {
		return theme.colors.base.green500
		// return 'linear-gradient(90deg, rgba(0, 255, 44, 1) 0%, rgba(0, 204, 38, 1) 100%)'
	} else if (valueToCheck === 'on hold') {
		// return 'linear-gradient(90deg, rgba(230, 230, 230, 1) 0%, rgba(179, 179, 179, 1) 100%)'
		// return 'linear-gradient(90deg, rgba(252, 238, 33, 1) 0%, rgba(251, 176, 59, 1) 100%)'
		return theme.colors.base.yellow400
	} else if (valueToCheck === 'on hold yellow') {
		// return 'linear-gradient(90deg, rgba(252, 238, 33, 1) 0%, rgba(251, 176, 59, 1) 100%)'
		return theme.colors.base.yellow400
	} else if (valueToCheck === 'na') {
		// return 'linear-gradient(90deg, rgba(72, 76, 255, 1) 0%, rgba(0, 175, 255, 1) 100%)'
		return theme.colors.base.blue400
	} else {
		// return 'linear-gradient(90deg, rgba(227, 120, 131, 1) 0%, rgba(233, 43, 74, 1) 100%)'
		return theme.colors.base.red400
	}
}

export const returnAbbreviatedMonth = (month: string) => {
	const map = {
		January: 'Jan',
		February: 'Feb',
		March: 'Mar',
		April: 'Apr',
		May: 'May',
		June: 'Jun',
		July: 'Jul',
		August: 'Aug',
		September: 'Sep',
		October: 'Oct',
		November: 'Nov',
		December: 'Dec',
	}
	return map[month as keyof typeof map]
}
export const findPublisherFromPid = (pid: string, publishers: any) => {
	const found = []
	for (const pub of publishers) {
		if (pub.media_source_pid.includes(pid)) {
			found.push(pub.publisher_name)
		}
	}
	if (found.length === 0) {
		return publishers
			.filter((pub: any) => pub.status === true)
			.map((pub: any) => pub.publisher_name)
			.sort((a: string, b: string) => a.localeCompare(b))
	}
	return found.sort((a: string, b: string) => a.localeCompare(b))
}
export const findCreatorFromId = (id: string, creators: any) => {
	const found = []
	for (const creator of creators) {
		if (creator.creator_id === id) {
			found.push(creator.name)
		}
	}
	if (found.length === 0) {
		return creators
			.map((creator: any) => creator.name)
			.sort((a: string, b: string) => a.localeCompare(b))
	}
	return found.sort((a: string, b: string) => a.localeCompare(b))
}
export const findAdvertiserByOwner = (owner: string, advertiser: any) => {
	const found = []
	for (const pub of advertiser) {
		if (pub.email === owner) {
			found.push(pub.advertiser_name)
		}
	}
	if (found.length === 0) {
		return advertiser
			.filter((adv: any) => adv.status === true)
			.map((adv: any) => adv.advertiser_name)
			.sort((a: string, b: string) => a.localeCompare(b))
	}
	return found.sort((a: string, b: string) => a.localeCompare(b))
}
//function used for global finance. It generates the hierarchy for the rows based on the given key value (campaign by default).
export const createHierarchy = (array: any, key: string = 'campaign') => {
	const hierarchyIndexesMap: any = {}
	for (const indx in array) {
		if (array[indx].hierarchy.length === 1) {
			hierarchyIndexesMap[array[indx].hierarchy[0]] = indx
		}
	}
	for (const indx in array) {
		if (
			array[indx].hierarchy[0].length >= 2 &&
			hierarchyIndexesMap[array[indx].hierarchy[0]] === undefined
		) {
			// console.log(array[indx])
			const differentKeysMap = [
				'month',
				'owner',
				'pid',
				'platform',
				'hierarchy',
			]

			const obj: any = {}
			for (const key in array[indx]) {
				if (!differentKeysMap.includes(key)) {
					obj[key] = array[indx][key]
				} else if (key === 'hierarchy') {
					obj[key] = [array[indx].hierarchy[0]]
				} else {
					obj[key] = ''
				}
			}
			// const newMain = {
			// 	advertiser: array[indx].advertiser,
			// 	approved_conversions: array[indx].approved_conversions,
			// 	campaign: array[indx].campaign,
			// 	cost: array[indx].cost,
			// 	deduction_amount: array[indx].deduction_amount,
			// 	final_amount: array[indx].final_amount,
			// 	is_creator_row: array[indx].is_creator_row,
			// 	month: '',
			// 	no_status_values: array[indx].no_status_values,
			// 	on_hold_values: array[indx].on_hold_values,
			// 	yes_status_value: array[indx].yes_status_value,
			// 	revenue: array[indx].revenue,
			// 	row_status: array[indx].row_status,
			// 	uuid: uuidv4(),
			// 	owner: '',
			// 	pid: '',
			// 	platform: '',
			// 	hierarchy: [array[indx].hierarchy[0]],
			// }
			hierarchyIndexesMap[array[indx].hierarchy[0]] = indx
			array.splice(indx, 0, obj)
		}
	}
	return array
	// console.log(array, key)
	// const arrayCopy = [...array]
	// const alreadyRegistered: any = []
	// const result = []
	// for (const element of array) {
	// 	let row = element
	// 	if (alreadyRegistered.includes(element[key])) {
	// 		row.hierarchy = [element[key], element.uuid]
	// 		row.row_status = row.status
	// 	} else {
	// 		let mainRow: any = {}
	// 		mainRow.campaign = element[key]
	// 		mainRow.advertiser = element.advertiser
	// 		mainRow.platform = ''
	// 		mainRow.uuid = element.uuid + 'main'
	// 		mainRow.pid = ''
	// 		mainRow.month = ''
	// 		mainRow.notes = ''
	// 		mainRow.owner = ''
	// 		mainRow.row_status = ''
	// 		mainRow.revenue = 0
	// 		mainRow.cost = 0
	// 		mainRow.approved_conversions = 0
	// 		mainRow.deduction_amount = 0
	// 		mainRow.final_amount = 0

	// 		const summableKeys = [
	// 			'revenue',
	// 			'cost',
	// 			'approved_conversions',
	// 			'deduction_amount',
	// 			'final_amount',
	// 		]
	// 		const statusCostMap = {
	// 			yes: 0,
	// 			no: 0,
	// 			on_hold: 0,
	// 		}
	// 		let multipleRows = false
	// 		for (const savedElement of arrayCopy) {
	// 			if (element[key] === savedElement[key]) {
	// 				if (element.uuid !== savedElement.uuid) {
	// 					multipleRows = true
	// 				}
	// 				for (const key in savedElement) {
	// 					if (summableKeys.includes(key)) {
	// 						if (savedElement[key]) {
	// 							if (key === 'final_amount') {
	// 								if (savedElement.status.toLowerCase() === 'no') {
	// 									mainRow[key] = Number(mainRow[key]) + 0
	// 								} else {
	// 									mainRow[key] =
	// 										Number(mainRow[key]) + Number(savedElement[key])
	// 								}
	// 							} else {
	// 								mainRow[key] =
	// 									Number(mainRow[key]) + Number(savedElement[key])
	// 							}
	// 						}
	// 					}
	// 				}

	// 				if (savedElement.cost) {
	// 					if (savedElement.status.toLowerCase() === 'yes') {
	// 						statusCostMap.yes =
	// 							statusCostMap.yes + parseInt(savedElement.cost)
	// 					} else if (savedElement.status.toLowerCase() === 'no') {
	// 						statusCostMap.no = statusCostMap.no + parseInt(savedElement.cost)
	// 					} else if (savedElement.status.toLowerCase() === 'on hold') {
	// 						statusCostMap.on_hold =
	// 							statusCostMap.on_hold + parseInt(savedElement.cost)
	// 					}
	// 				}
	// 			}
	// 		}
	// 		mainRow.yes_status_values = Math.floor(
	// 			(100 * statusCostMap.yes) / mainRow.cost,
	// 		)
	// 		mainRow.no_status_values = Math.floor(
	// 			(100 * statusCostMap.no) / mainRow.cost,
	// 		)
	// 		mainRow.on_hold_status_values = Math.floor(
	// 			(100 * statusCostMap.on_hold) / mainRow.cost,
	// 		)
	// 		mainRow.row_status = row.status.toLowerCase()
	// 		row.row_status = row.status
	// 		if (multipleRows) {
	// 			mainRow.hierarchy = [element[key]]
	// 			alreadyRegistered.push(element[key])
	// 			result.push(mainRow)
	// 			row.hierarchy = [element[key], element.uuid]
	// 		} else {
	// 			row.hierarchy = [element[key]]
	// 		}
	// 	}
	// 	result.push(row)
	// }
	// if (result[0] && !result[0].campaign) {
	// 	result.shift()
	// }
	// return result
}
export const filterStatusByProperty = (
	el: any,
	index: number,
	arr: any,
	property: any,
	//if value is boolean we are just going to check if there's any value registered at all.
	value: string | boolean,
) => {
	let looped = false
	let result = false
	for (let i = 0; i < arr.length; i++) {
		//if the element is "main" row, the next element is not undefined and the next elemenet has the same "main".
		if (
			el.hierarchy.length === 1 &&
			el.hierarchy[0] === arr[i].hierarchy[0] &&
			i !== index
		) {
			looped = true
			if (property === 'year') {
				const yearOfRow = getYear(arr[i].month)
				if (typeof value === 'string') {
					if (value === 'This Year') {
						result = yearOfRow === moment().year().toString()
					} else {
						result = yearOfRow === value
					}
				} else {
					result = true
				}
			} else if (property === 'month') {
				if (typeof value === 'string') {
					const abbreviatedMonth = getMonth(arr[i].month)
					result = abbreviatedMonth?.toLowerCase() === value.toLowerCase()
				} else {
					result = true
				}
			} else {
				if (typeof value === 'boolean') {
					result = arr[i][property] !== undefined && arr[i][property] !== ''
					if (value === false && result === false) {
						result = true
					} else if (value === false && result === true) {
						result = false
					}
				} else {
					result = arr[i][property]?.toLowerCase() === value
				}
			}
		}
		if (result) {
			break
		}
	}
	//if we looped then we will take the result. Otherwise we will just evaluate the element.
	if (looped) {
		return result
	} else {
		if (property === 'year') {
			const yearOfRow = getYear(el.month)
			if (value === 'This Year') {
				result = yearOfRow === moment().year().toString()
			} else {
				if (typeof value === 'string') {
					return yearOfRow === value
				} else {
					//if we just check if the value exists then it will always be true for month and year.
					result = true
				}
			}
		} else if (property === 'month') {
			if (typeof value === 'string') {
				const abbreviatedMonth = getMonth(el.month)
				result = abbreviatedMonth?.toLowerCase() === value.toLowerCase()
			} else {
				//if we just check if the value exists then it will always be true for month and year.
				result = true
			}
		} else {
			if (typeof value === 'boolean') {
				result = el[property] !== undefined && el[property] !== ''
				if (value === false && result === false) {
					result = true
				} else if (value === false && result === true) {
					result = false
				}
			} else {
				result = el[property]?.toLowerCase() === value
			}
		}
		return result
	}
	// return looped ? result : el[property] && el[property].toLowerCase() === value
}
export function formatDateToDdMmYyyy(isoDate: string) {
	const date = new Date(isoDate)
	const day = String(date.getUTCDate()).padStart(2, '0')
	const month = String(date.getUTCMonth() + 1).padStart(2, '0')
	const year = date.getUTCFullYear()
	return `${day}/${month}/${year}`
}
export function formatFinancePaymentDate(
	date: Date,
	noOffset: boolean = false,
) {
	if (!date || isNaN(date.getTime())) {
		return ''
	}
	let dateCopy = new Date(date)
	let offset = 0
	if (date && new Date(date).getUTCHours() > 12) {
		if (!noOffset) {
			offset = 1
		}
		dateCopy.setUTCDate(dateCopy.getUTCDate() + offset)
	}
	const result = [
		padTo2Digits(dateCopy.getUTCDate()),
		padTo2Digits(dateCopy.getUTCMonth() + 1),
		dateCopy.getUTCFullYear(),
	].join('/')
	return result
}

export function stringToColor(string: string) {
	let hash = 0
	let i

	/* eslint-disable no-bitwise */
	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash)
	}

	let color = '#'

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff
		color += `00${value.toString(16)}`.slice(-2)
	}
	/* eslint-enable no-bitwise */

	return color
}

export const stringAvatar = (name: string) => {
	const secondLetter =
		name.split(' ').length > 1 ? name.split(' ')[1][0] : name[1]
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(' ')[0][0]}${secondLetter}`,
	}
}

export function formatDateMonthYear(inputStr: any, entireMonthName = true) {
	// Parse the input string to a Date object
	const date = moment(inputStr, 'DD/MM/YYYY').format(
		entireMonthName ? 'MMMM-YYYY' : 'MMM-YYYY',
	)

	return date
}

export const extractPaymentLimit = (input: string) => {
	if (!input) return ''
	const lowerCaseInput = input.toLowerCase()
	return lowerCaseInput.slice(3)
}

export const sortDates = (value1: any, value2: any) => {
	if (!value1 || isNaN(value1)) {
		return -1
	}
	if (!value2 || isNaN(value2)) {
		return 1
	}
	const date1 = new Date(value1)
	const date2 = new Date(value2)
	if (date1 < date2) return -1
	if (date1 > date2) return 1
	return 0
}

export const sortDatesStrings = (str1: string, str2: string) => {
	if (!str1 && !str2) {
		return 0
	}
	if (!str1) {
		return -1
	}
	if (!str2) {
		return 1
	}

	// Check and format the dates to 'MMM YYYY'
	const formattedStr1 = moment(str1, ['DD/MM/YYYY', 'MMM YYYY']).format(
		'MMM YYYY',
	)
	const formattedStr2 = moment(str2, ['DD/MM/YYYY', 'MMM YYYY']).format(
		'MMM YYYY',
	)

	const date1 = moment(formattedStr1, 'MMM YYYY')
	const date2 = moment(formattedStr2, 'MMM YYYY')

	if (date1.isBefore(date2)) return -1
	if (date1.isAfter(date2)) return 1
	return 0
}

export const sortHardcodedDates = (str1: string, str2: string) => {
	if (!str1) {
		return -1
	}
	if (!str2) {
		return 1
	}
	const date1 = moment(str1)
	const date2 = moment(str2)
	if (date1.isBefore(date2)) return -1
	if (date1.isAfter(date2)) return 1
	return 0
}

export const findDifferences = (obj1: any, obj2: any) => {
	const changes = []
	for (const key in obj1) {
		if (obj1[key] !== obj2[key]) {
			changes.push(key)
		}
	}
	return changes
}
export function getApplicableRules(
	users: any,
	login: any,
	settings: any,
	page: string,
) {
	console.error(
		'calling getApplicableRules - we should not use this function anymore',
	)
	if (users.users.length === 0 || settings.settings.length === 0) return false
	let user = users.users.find((user: any) => user.email === login.user.email)
	const userType = user.user_type
	const userRole = user.role

	const perms = settings.settings.permissionGroups[userType][userRole]
	if (!perms) {
		console.error(
			'Permissions for the given userType and userRole are undefined.',
		)
		return {}
	}
	let ruleSet = []

	if (perms) {
		ruleSet = perms.find((e: any) => e.endpoint === page)
	}

	return ruleSet
}

export const allRouteNames = [
	'News',
	'Dashboard',
	'Campaigns',
	'Advertisers',
	'Supply',
	'Analytics',
	'Network',
	'Aggregated',
	'Invalid',
	'Finance',
	'Control',
	'Personal',
	'Media Buying',
	'Creative',
	'Global',
	'Demand',
	'Creative Supply',
	'Balance In',
	'Balance Out',
	'Tools',
	'Autoswitch',
	'Passwords',
	'Documents',
	'P360 (Apps)',
	'Ads Placement',
	'Logs',
	'Settings',
	'Users',
	'Personal Settings',
	'Company Settings',
	'Log Out',
]

export const getAdjustedTitle = (
	originalTitle: string,
	companyType: string,
): string => {
	switch (companyType) {
		case 'advertiser':
			switch (originalTitle) {
				case 'Campaigns':
					return 'My Campaigns'
				case 'Supply':
					return 'Marketplace'
				default:
					return originalTitle
			}
		case 'publisher':
			switch (originalTitle) {
				case 'Campaigns':
					return 'Marketplace'
				default:
					return originalTitle
			}
		case 'agency':
			return originalTitle
			switch (
				originalTitle // can be used later to change the agency route names
			) {
				case 'Campaigns':
					return 'Campaigns'
				case 'Advertisers':
					return 'Advertisers'
				case 'Supply':
					return 'Supply'
				case 'Finance':
					return 'Finance'
				default:
					return originalTitle
			}
		case 'creator':
			switch (originalTitle) {
				case 'Campaigns':
					return 'Publisher Marketplace'
				default:
					return originalTitle
			}
		default:
			return originalTitle
	}
}

export function convertPathName(pathname: string) {
	const map = {
		'/campaigns': 'Campaign',
		'/advertisers': 'Advertiser',
		'/p360apps': 'Schedule',
		'/users': 'User',
		'/publishers': 'Publisher',
		'/creators': 'Creator',
	}
	if (map[pathname as keyof typeof map]) {
		return ' ' + map[pathname as keyof typeof map]
	}
	return ''
}

export function isValidImageType(file: File): boolean {
	const validTypes = ['image/jpeg', 'image/png', 'image/svg+xml']
	return validTypes.includes(file.type)
}

export const handleIconUrl = (iconUrl: string, defaultIcon: string) => {
	return !iconUrl || iconUrl === '' ? defaultIcon : iconUrl
}

export const stringIsValidEmail = (email: string): boolean => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return emailRegex.test(email)
}

export const stringIsValidGender = (gender: string): boolean => {
	const validGenders = ['Male', 'Female', 'Binary', 'None']
	return validGenders.includes(gender)
}

export const stringIsValidType = (type: string): boolean => {
	const validTypes = ['IM', 'UGC']
	return validTypes.includes(type)
}

export const stringIsValidCreatorId = (creatorId: string): boolean => {
	return creatorId.trim() !== ''
}

export const stringIsValidAddress = (address: string): boolean => {
	return address.trim() !== ''
}

export const getRandomColor = (theme: any) => {
	const colors = [
		theme.colors.base.green,
		theme.colors.base.grey80,
		theme.colors.base.grey100,
		theme.colors.base.grey300,
		theme.colors.base.grey700,
		theme.colors.base.green50,
		theme.colors.base.green200,
		theme.colors.base.green400,
		theme.colors.base.green500,
	]

	if (!colors || colors.length === 0) {
		console.error('Theme colors are not defined correctly')
		return theme.colors.green // fallback color
	}

	const randomIndex = Math.floor(Math.random() * colors.length)

	return colors[randomIndex]
}

export const generateRandomColors = (numColors: number = 4, theme: any) => {
	const colorsSet = new Set<string>()
	while (colorsSet.size < numColors) {
		colorsSet.add(getRandomColor(theme))
	}
	return Array.from(colorsSet)
}

export const convertToCSV = (objArray: any[]) => {
	const array = [Object.keys(objArray[0])].concat(objArray)
	// console.log(array)
	return array
		.map((it) => {
			// console.log(it)
			let result = []
			for (const obj in it) {
				result.push(`"${it[obj].toString()}"`)
			}
			result.join(',')
			return result
			// return `"${Object.values(it).toString()}"`
		})
		.join('\n')
}

// export const parseCSV = (csvData: string): any[] => {
// 	const lines = csvData.split('\n')
// 	const headers = lines[0].split(',')
// 	const data = lines.slice(1).map((line) => {
// 		const values = line.split(',')
// 		return headers.reduce((acc, header, index) => {
// 			acc[header] = values[index]
// 			return acc
// 		}, {} as Record<string, string>)
// 	})
// 	return data
// }

export const parseCSV = (csvText: string): any[] => {
	const result = Papa.parse(csvText, {
		header: true,
		skipEmptyLines: true,
		dynamicTyping: true, // Automatically types numbers and booleans
	})
	return result.data
}

export const extractLast5Chars = (url: string) => {
	const filename = url.split('/').pop() // Get the file name from the URL
	const defaultFileName = '00000'
	if (filename) {
		const nameWithoutExtension = filename.split('.').slice(0, -1).join('.') // Remove the .csv extension
		return nameWithoutExtension.slice(-5) // Get the last 5 characters
	}
	return defaultFileName
}

// Utility function to check if a string is encrypted (starts with "ENC:")
export const isEncrypted = (value: string): boolean => {
	return typeof value === 'string' && value.startsWith(ENCRYPTION_PREFIX)
}

// Helper function to decrypt values if they are encrypted, or return as-is if not
export const decryptIfNeeded = async (
	value: string | null,
): Promise<string | null> => {
	if (!value) return value // Return null if value is null

	// Check if the value starts with "ENC:"
	if (isEncrypted(value)) {
		try {
			const cleanedValue = value.replace(ENCRYPTION_PREFIX, '').trim() // Ensure ENC is removed and trimmed

			return await decryptMessageForDataSplit(cleanedValue)
		} catch (error) {
			return value // Return the original value if decryption fails
		}
	}
	return value // Return the value as-is if it's not encrypted
}

export const decryptContacts = async (
	contacts: ContactInterface[],
): Promise<ContactInterface[]> => {
	return await Promise.all(
		contacts.map(async (contact) => {
			try {
				const decryptedName = await decryptIfNeeded(contact.name)
				const decryptedEmail = await decryptIfNeeded(contact.email)
				const decryptedSkype = await decryptIfNeeded(contact.skype)
				const decryptedPhone = await decryptIfNeeded(contact.phone)
				const decryptedRole = await decryptIfNeeded(contact.role)
				const decryptedType = await decryptIfNeeded(contact.type)

				// Decrypt the `geo` array, where each value may be encrypted
				let decryptedGeo: string[] = []
				if (Array.isArray(contact.geo)) {
					// Decrypt each `geo` entry, and filter out any null values
					decryptedGeo = (
						await Promise.all(
							contact.geo.map(async (geo) => {
								const decryptedGeoValue =
									geo && geo.startsWith(ENCRYPTION_PREFIX)
										? await decryptIfNeeded(geo)
										: geo
								return decryptedGeoValue
							}),
						)
					).filter((geo): geo is string => geo !== null) // Filter out null values
				} else if (typeof contact.geo === 'string') {
					// Handle string `geo` if it exists
					const decryptedGeoString = await decryptIfNeeded(contact.geo)
					decryptedGeo = decryptedGeoString
						? JSON.parse(decryptedGeoString)
						: []
				}

				return {
					...contact,
					name: decryptedName || contact.name,
					email: decryptedEmail || contact.email,
					skype: decryptedSkype || contact.skype,
					phone: decryptedPhone || contact.phone,
					role: decryptedRole || contact.role,
					type: decryptedType || contact.type,
					geo: decryptedGeo.length > 0 ? decryptedGeo : contact.geo, // Use decrypted geo array
				}
			} catch (error) {
				console.error('Error decrypting contact:', error)
				throw new Error('Failed to decrypt contact data')
			}
		}),
	)
}

export const handleAdjustedDateChange = (
	startDate: Dayjs | null,
	endDate: Dayjs | null,
	setStartDay: (date: string) => void,
	setEndDay: (date: string) => void,
	setReportFields: (fields: any) => void,
) => {
	if (startDate) {
		// Convert Dayjs to Date
		const startDateObj = startDate.toDate()
		const startOfDayUTC = new Date(
			startDateObj.getTime() - startDateObj.getTimezoneOffset() * 60000,
		)
		setStartDay(startOfDayUTC.toISOString())

		setReportFields((prevState: any) => ({
			...prevState,
			from_date: startOfDayUTC.toISOString(),
			to_date: endDate ? prevState.to_date : '',
		}))
	}

	if (endDate) {
		const endDateObj = endDate.toDate()
		const endOfDayUTC = new Date(
			endDateObj.getTime() - endDateObj.getTimezoneOffset() * 60000,
		)
		setEndDay(endOfDayUTC.toISOString())

		setReportFields((prevState: any) => ({
			...prevState,
			from_date: prevState.from_date,
			to_date: endOfDayUTC.toISOString(),
		}))
	}
}

export const roundSpecificKeyValues = (
	data: any,
	keyToRound: any,
	decimalPlaces = 2,
) => {
	return data.map((item: any) => {
		const roundedItem = { ...item } // Copy the item to avoid mutation
		if (item[keyToRound] !== undefined && item[keyToRound] !== null) {
			const factor = Math.pow(10, decimalPlaces)
			roundedItem[keyToRound] = Math.round(item[keyToRound] * factor) / factor // Round to the specified decimal places
		}
		return roundedItem
	})
}

/**
 * Formats specific column values based on their type (integer or float).
 *
 * @param key - The column key.
 * @param value - The value to format.
 * @param integerColumns - Array of column keys that should be treated as integers.
 * @param floatColumns - Array of column keys that should be treated as floats.
 * @returns The formatted value.
 */
export const roundColumnValuesNumbers = (
	key: string,
	value: any,
	integerColumns: string[],
	floatColumns: string[] = [],
): string | number => {
	const lowerCaseKey = key.toLowerCase()

	// Check if the column is in the integerColumns array
	if (
		integerColumns.map((column) => column.toLowerCase()).includes(lowerCaseKey)
	) {
		const numericValue = typeof value === 'string' ? parseInt(value, 10) : value

		// Ensure integer value without decimals
		if (!isNaN(numericValue)) {
			return formatNumber(Math.floor(numericValue))
		}
	}

	// Check if the column is in the floatColumns array
	if (
		floatColumns.map((column) => column.toLowerCase()).includes(lowerCaseKey)
	) {
		const numericValue = parseFloat(value)

		// Return the formatted float value
		if (!isNaN(numericValue)) {
			// Append the '%' symbol for the 'cr' column
			if (lowerCaseKey === 'cr') {
				return formatFloatNumber(numericValue, 3, true) // 3 decimal places with '%'
			}
			return formatFloatNumber(numericValue, 2) // 2 decimal places
		}
	}

	// Return the original value if it's not part of the integer or float columns
	return value
}

/**
 * Formats a float number with comma separators and appropriate decimal places.
 * - For the 'cr' column, appends a '%' sign and uses three decimal places.
 * - For other float columns, uses two decimal places.
 *
 * @param number - The number to format.
 * @param decimalPlaces - The number of decimal places (default is 2).
 * @param appendPercent - Whether to append a '%' sign (default is false).
 * @returns The formatted number as a string.
 */
export const formatFloatNumber = (
	number: number,
	decimalPlaces: number = 2,
	appendPercent: boolean = false,
): string => {
	const formatted = formatNumber(number, decimalPlaces)
	return appendPercent ? `${formatted}%` : formatted
}

export const formatColumnName = (columnName: string): string => {
	// Special case for 'Cr' to be displayed as 'CR'
	if (columnName === 'cr') {
		return columnName.toUpperCase()
	}

	// Default formatting logic for other column names
	return columnName
		.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(' ')
}

// Function to get columns dynamically based on reportColumnMap
/**
 * Classifies columns as integers or floats based on the provided column map.
 *
 * @param columnMap - An object mapping display names to internal column keys.
 * @returns An object containing arrays of integer and float column keys.
 */
export const getIntegerAndFloatColumns = (
	columnMap: Record<string, string>,
) => {
	// Define rules to classify columns as integers or floats
	const integerColumnsList = [
		'impressions',
		'clicks',
		'installs',
		're-attributions',
		're-engagements',
		'total_revenue',
		'level2_event',
		'level3_event',
		'level4_event',
		'level5_event',
		'total',
		'level_2_event',
		'level_3_event',
		'level_4_event',
		'level_5_event',
	]

	const floatColumnsList = [
		'cr',
		'profit',
		'cost',
		'revenue',
		// Add any other float columns here
	]

	// Extract the actual column keys used in the table (values of columnMap)
	const actualColumnKeys = Object.values(columnMap).map((key) =>
		key.toLowerCase(),
	)

	// Classify columns as integers
	const integerColumns = Object.values(columnMap).filter((key) =>
		integerColumnsList.includes(key.toLowerCase()),
	)

	// Classify columns as floats
	const floatColumns = Object.values(columnMap).filter((key) =>
		floatColumnsList.includes(key.toLowerCase()),
	)

	return { integerColumns, floatColumns }
}

export const stableStringify = (obj: any): string => {
	const allKeys: string[] = []

	// Collect all keys from the object, including nested objects
	JSON.stringify(obj, (key, value) => {
		if (key !== '') {
			allKeys.push(key)
		}
		return value
	})

	// Sort keys to ensure consistent order
	allKeys.sort()

	// Create an ordered object with sorted keys
	const orderedObj: any = {}
	for (const key of allKeys) {
		orderedObj[key] = obj[key]
	}

	// Stringify the ordered object
	return JSON.stringify(orderedObj)
}

/**
 * Merges multiple refs into a single ref callback.
 *
 * This utility function is useful when you need to pass multiple refs to a single
 * DOM element or component. It allows multiple ref objects or ref callback functions
 * to be combined into a single ref callback, ensuring that each ref receives the
 * reference to the DOM element or component.
 *
 * @param {...any[]} refs - An array of refs which can be either ref objects
 *                          (e.g., `React.RefObject`) or ref callback functions.
 *
 * @returns {function} - A ref callback function that can be passed to the `ref`
 *                       attribute of a DOM element or React component. This function
 *                       will assign the element to each of the provided refs.
 *
 * @example
 * const ref1 = useRef(null);
 * const ref2 = useRef(null);
 *
 * <div ref={mergeRefs(ref1, ref2)} />
 *
 * In this example, both `ref1` and `ref2` will point to the same DOM element.
 *
 */
export const mergeRefs = (...refs: any[]) => {
	return (node: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				// If the ref is a callback function, call it with the node
				ref(node)
			} else if (ref != null) {
				// If the ref is an object (e.g., React.RefObject), set its current property
				ref.current = node
			}
		})
	}
}

export const handleDateChange = (
	startDate: Dayjs | null,
	endDate: Dayjs | null,
	setDataObj: (callback: (prevState: any) => any) => void,
	onDateChange?: (startDate: Date | null, endDate: Date | null) => void,
) => {
	const convertedStartDate = startDate ? startDate.toDate() : null
	const convertedEndDate = endDate ? endDate.toDate() : null

	// Update your data object with the new date range
	setDataObj((prevState: any) => ({
		...prevState,
		from_date: convertedStartDate?.toISOString() || '',
		to_date: convertedEndDate?.toISOString() || '',
	}))

	// Call the onDateChange prop with Date objects
	onDateChange?.(convertedStartDate, convertedEndDate)
}

export const formatDisplayValue = (
	value: string | null | undefined,
	isHttpLink: boolean = false, // Optional parameter, defaults to false
): string => {
	const trimmedValue = value ? value.trim() : ''

	if (
		trimmedValue === '' ||
		trimmedValue === '""' ||
		trimmedValue === null ||
		trimmedValue === undefined
	) {
		// Return empty string for HTTP links, 'N/A' for other cases
		return isHttpLink ? '' : 'N/A'
	} else {
		return trimmedValue
	}
}

// Sorting Utility Functions
export const descendingComparator = <T>(
	a: T,
	b: T,
	orderBy: keyof T,
): number => {
	const aValue = a[orderBy]
	const bValue = b[orderBy]

	if (bValue === null || bValue === undefined) return -1
	if (aValue === null || aValue === undefined) return 1

	if (typeof bValue === 'number' && typeof aValue === 'number') {
		return bValue - aValue
	}

	return String(bValue).localeCompare(String(aValue))
}

export const getComparator = <Key extends keyof any>(
	order: 'asc' | 'desc',
	orderBy: Key,
): ((a: { [key in Key]: any }, b: { [key in Key]: any }) => number) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

export const stableSort = <T>(
	array: T[],
	comparator: (a: T, b: T) => number,
): T[] => {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
	stabilizedThis.sort((a, b) => {
		const cmp = comparator(a[0], b[0])
		if (cmp !== 0) return cmp
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

/**
 * Formats a number with comma separators.
 * - If the number is an integer, it displays without decimals.
 * - If the number has decimal places, it displays up to two decimal places.
 *
 * @param number - The number to format.
 * @param decimalPlaces - The number of decimal places to display (default is 2).
 * @returns The formatted number as a string.
 */
export const formatNumber = (
	number: number,
	decimalPlaces: number = 2,
): string => {
	if (Number.isInteger(number)) {
		// Format as integer with comma separators
		return new Intl.NumberFormat('en-US').format(number)
	} else {
		// Format with comma separators and fixed decimal places
		return new Intl.NumberFormat('en-US', {
			minimumFractionDigits: decimalPlaces,
			maximumFractionDigits: decimalPlaces,
		}).format(number)
	}
}
