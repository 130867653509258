import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import {
	useTheme,
	IconButton,
	Popover,
	TextField,
	Box,
	Tooltip,
	TableCell,
	TableRow,
	TableCellProps,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import styled from 'styled-components'
import { debounce } from '@mui/material/utils'
import {
	formatColumnName,
	getIntegerAndFloatColumns,
	roundColumnValuesNumbers,
} from '../../../utils/helpers/helperFuncs'
import {
	reorderReportColumns,
	reportColumnMap,
	reportNonNumericColumns,
	sanitizeReason,
} from '../../../utils/helpers/reportHelperFuncs'
import { TableComponent } from '../../components/TableComponents'
import { HeaderFilterComponent } from '../../components/TableHelperComponents'
import useDebounce from '../../../hooks/useDebounce'

interface CustomReportTableProps {
	reports: any[]
	totalRow?: { [key: string]: string | number | null }
	reportNames: string[]
	reportType: string
}

export type Order = 'asc' | 'desc'

interface StyledTableCellProps extends TableCellProps {
	isTotalRevenue?: boolean
	isTotalRow?: boolean
}

export const StyledTableBodyCell: React.FC<StyledTableCellProps> = ({
	isTotalRevenue,
	isTotalRow,
	...props
}) => {
	const theme = useTheme()
	const cellRef = React.useRef<HTMLDivElement>(null)
	const [isTruncated, setIsTruncated] = useState(false)

	useEffect(() => {
		if (cellRef.current) {
			setIsTruncated(cellRef.current.scrollWidth > cellRef.current.clientWidth)
		}
	}, [props.children])

	const style: React.CSSProperties = {
		backgroundColor: isTotalRow
			? theme.colors.base.grey300
			: isTotalRevenue
			? theme.colors.base.grey200
			: 'inherit',
		color: theme.colors.text.titles,
		fontWeight: isTotalRow ? 'bold' : 'normal',
		width: 'auto',
		textAlign: 'left',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		padding: '8px',
		boxSizing: 'border-box',
		transition: 'width 0.2s',
	}

	return (
		<Tooltip title={isTruncated ? props.children : ''} arrow>
			<TableCell style={style} {...props}>
				<div
					ref={cellRef}
					style={{
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						whiteSpace: 'nowrap',
					}}
				>
					{props.children}
				</div>
			</TableCell>
		</Tooltip>
	)
}

export const StyledTableRow = styled(TableRow)<{ isTotalRow?: boolean }>`
	&& {
		font-weight: ${({ isTotalRow }) => (isTotalRow ? 'bold' : 'normal')};
		font-size: ${({ isTotalRow }) => (isTotalRow ? '1rem' : 'inherit')};
		width: 100%;
	}
`

const CustomReportTable: React.FC<CustomReportTableProps> = ({
	reports,
	totalRow,
	reportType,
}) => {
	const theme = useTheme()

	const COLUMN_MIN_WIDTH = 150
	const FIXED_TABLE_HEIGHT = 70
	const COLUMN_HEADER_HEIGHT = 60

	const [order, setOrder] = useState<Order>('asc')
	const [orderBy, setOrderBy] = useState<string>(
		reports[0] ? Object.keys(reports[0])[0] : '',
	)
	const [rowsPerPage, setRowsPerPage] = useState<number>(30)
	const [loading, setLoading] = useState<boolean>(false)
	const [filterColumn, setFilterColumn] = useState<string | null>(null)

	const sortedColumns = useMemo(() => {
		const columns = Object.keys(reports[0] || {})
		return reorderReportColumns(columns, reportType)
	}, [reports, reportType])
	// State to hold the filter input value
	const [openFilter, setOpenFilter] = useState(false)
	const [currentFilters, setCurrentFilters] = useState('')
	const [filterLabel, setFilterLabel] = useState('')
	const filterRef = useRef(null)
	const [customFilter, setCustomFilter] = useState<{ [key: string]: any[] }>(
		() => {
			const filters: { [key: string]: any[] } = {}
			sortedColumns.forEach((columnKey) => {
				filters[columnKey] = []
			})
			return filters
		},
	)

	const debouncedCustomFilter = useDebounce(customFilter, 300) // 300ms delay

	const reportsWithIds = useMemo(() => {
		return reports.map((report, index) => ({
			id: report.id || index,
			...report,
		}))
	}, [reports])

	// State for filterModel
	const [filterModel, setFilterModel] = useState<{
		items: Array<{
			id: string
			field: string
			operator: string
			value: string
		}>
		linkOperator?: 'and' | 'or'
	}>({
		items: [],
		linkOperator: 'and',
	})

	const getColumnWidth = (headerText: string) => {
		const maxWidth = 400
		const minWidth = 150
		const calculatedWidth = Math.min(
			Math.max(headerText.length * 10 + 50, minWidth),
			maxWidth,
		)
		return calculatedWidth
	}

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (
				filterRef.current &&
				!(filterRef.current as any).contains(event.target)
			) {
				setOpenFilter(false)
			}
		}
		document.addEventListener('click', handleClickOutside)
		return () => {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	const optionsFilters = useMemo(() => {
		const options: { [key: string]: any[] } = {}
		sortedColumns.forEach((columnKey) => {
			const uniqueValues = Array.from(
				new Set(reports.map((report) => report[columnKey]?.toString() || '')),
			).sort()
			options[columnKey] = uniqueValues
		})
		return options
	}, [reports, sortedColumns])

	const handleFilterIconClick = (
		event: React.MouseEvent<HTMLButtonElement>,
		columnKey: string,
	) => {
		event.stopPropagation()
		setOpenFilter((prevOpen) => !prevOpen)
		setCurrentFilters(columnKey)
		setFilterLabel(formatColumnName(columnKey))
	}

	const { integerColumns, floatColumns } =
		getIntegerAndFloatColumns(reportColumnMap)

	const memoizedColumns: GridColDef[] = useMemo(() => {
		return sortedColumns.map((columnKey) => {
			const isNonNumeric = reportNonNumericColumns.includes(columnKey)

			const columnWidth = getColumnWidth(formatColumnName(columnKey))

			return {
				field: columnKey,
				headerName: formatColumnName(columnKey),
				flex: 1,
				minWidth: COLUMN_MIN_WIDTH,
				sortable: true,
				filterable: isNonNumeric,
				resizable: true,
				renderCell: (params: any) => {
					const value = params.value
					const formattedValue =
						columnKey === reportColumnMap.reason
							? sanitizeReason(value)
							: columnKey === reportColumnMap.cr
							? `${roundColumnValuesNumbers(
									reportColumnMap.cr,
									value,
									integerColumns,
									floatColumns,
							  )}%`
							: roundColumnValuesNumbers(
									columnKey,
									value,
									integerColumns,
									floatColumns,
							  )

					return (
						<Tooltip title={String(formattedValue)} arrow>
							<div
								style={{
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									whiteSpace: 'nowrap',
								}}
							>
								{formattedValue}
							</div>
						</Tooltip>
					)
				},
				renderHeader: () => {
					if (isNonNumeric) {
						return (
							<HeaderFilterComponent
								label={formatColumnName(columnKey)}
								width={columnWidth}
								openFilter={openFilter}
								setOpenFilter={setOpenFilter}
								field={columnKey}
								customFilter={customFilter}
								currentFilters={currentFilters}
								setCurrentFilters={setCurrentFilters}
								setFilterLabel={setFilterLabel}
								optionsFilters={optionsFilters}
							/>
						)
					}
				},
			}
		})
	}, [
		sortedColumns,
		integerColumns,
		floatColumns,
		reportColumnMap,
		handleFilterIconClick,
		filterColumn,
		filterModel.items,
	])

	// Apply filter logic to rows
	const filteredRows = useMemo(() => {
		if (filterModel.items.length === 0) {
			// No filters applied, return all rows with unique IDs
			return reportsWithIds
		}

		// Apply all filter conditions in a single pass
		const filteredData = reportsWithIds.filter((row) =>
			filterModel.items.every((filter) => {
				const fieldValue = row[filter.field]?.toString().toLowerCase() || ''
				const filterValue = filter.value.toString().toLowerCase()

				switch (filter.operator) {
					case 'contains':
						return fieldValue.includes(filterValue)
					case 'equals':
						return fieldValue === filterValue
					// Add more operators as needed
					default:
						return true
				}
			}),
		)

		return filteredData
	}, [reportsWithIds, filterModel])

	// Prepare total row
	const totalRowWithId = totalRow
		? {
				id: 'total-row',
				...totalRow,
		  }
		: null

	// Handle sorting
	const [sortModel, setSortModel] = useState([
		{
			field: orderBy || sortedColumns[0],
			sort: order,
		},
	])

	const handleSortModelChange = (newSortModel: any) => {
		setSortModel(newSortModel)
		if (newSortModel.length > 0) {
			setOrder(newSortModel[0].sort)
			setOrderBy(newSortModel[0].field)
		} else {
			setOrder('asc')
			setOrderBy('')
		}
	}

	const handleApplyFilter = useCallback(() => {
		// Apply custom filters based on the customFilter state
		setFilterModel((prevFilterModel) => {
			const newFilterItems = Object.entries(customFilter).flatMap(
				([field, values]) =>
					values.map((value) => ({
						id: `${field}-${value}`,
						field, // Correct field name
						operator: 'contains', // Use 'operator' instead of 'operatorValue'
						value: value.toString(),
					})),
			)

			return {
				...prevFilterModel,
				items: newFilterItems,
			}
		})
	}, [customFilter])

	useEffect(() => {
		handleApplyFilter()
	}, [debouncedCustomFilter, handleApplyFilter])

	// Handle page size change
	const handlePageSizeChange = (newPageSize: number) => {
		setRowsPerPage(newPageSize)
	}

	const pageSizeOptions = useMemo(() => {
		const totalRows = reports.length
		const options = [5, 10, 25, 50, 100]

		if (totalRows > 100) {
			options.push(200)
		}

		if (totalRows > 0) {
			options.push(totalRows)
		}

		return options
	}, [reports.length])

	return (
		<div style={{ width: '100%', overflowX: 'auto' }}>
			<TableComponent
				columns={memoizedColumns}
				rows={filteredRows}
				setPageSize={handlePageSizeChange}
				pageSize={rowsPerPage}
				rowsPerPageOptions={pageSizeOptions}
				sortModel={sortModel}
				onSortModelChange={handleSortModelChange}
				pinnedBottomRow={totalRowWithId ? [totalRowWithId] : []}
				setLoading={setLoading}
				loading={loading}
				filterModel={filterModel}
				onFilterModelChange={(newFilterModel: any) => {
					setFilterModel(newFilterModel)
				}}
				paginationMode='client'
				pagination
				disableColumnMenu={true}
				columnHeaderHeight={COLUMN_HEADER_HEIGHT}
				style={{
					height: `${FIXED_TABLE_HEIGHT}vh`,
					width: '100%',
					marginTop: '0.4rem',
				}}
				filterHandler={openFilter}
				setFilterHander={setOpenFilter}
				customFilter={customFilter}
				setCustomFilter={setCustomFilter}
				currentFilters={currentFilters}
				applyFilter={handleApplyFilter}
				filterLabel={filterLabel}
				optionsFilters={optionsFilters}
			/>
		</div>
	)
}

export default CustomReportTable
