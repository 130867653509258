import { Dialog, DialogContent, DialogTitle, useTheme } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { StyledNameField } from '../components/reusableComponents'
import { getLongAndShortArray } from '../../utils/helpers/tableHelper'

const DetailedLogs = (props: {
	row?: any
	PopUpVisible: any
	setPopUpVisible: any
	handleClose: any
	setLoading: any
}) => {
	const theme = useTheme()
	function extractValues(string: string) {
		const pattern = /(.+?) changed from (.+?) to (.+)/
		const match = string.match(pattern)
		if (match) {
			const name = match[1]
			const oldValue = match[2]
			const newValue = match[3]
			return { name, oldValue, newValue }
		} else {
			return null
		}
	}
	function extractContactValues(input: string) {
		// console.log('Input string:', input)

		// Improved regular expression to capture changes
		const pattern = /^(.+?) changed from (.*) to (.*)$/
		const match = input.match(pattern)

		if (!match) {
			console.error('String does not match the expected pattern.')
			return null
		}

		let oldData: any = 'empty'
		let newData: any = 'empty'

		// Safely parse JSON
		try {
			if (match[2] !== 'empty' && match[2] !== "'empty'")
				oldData = JSON.parse(match[2])
			if (match[3] !== 'empty' && match[3] !== "'empty'")
				newData = JSON.parse(match[3])
		} catch (error) {
			console.error('JSON parsing error:', error)
			return null
		}

		// Ensure data is an array
		if (!Array.isArray(oldData)) oldData = []
		if (!Array.isArray(newData)) newData = []

		const result = []

		const normalizeGeo = (geo: any) => {
			try {
				return Array.isArray(geo)
					? geo
					: typeof geo === 'string'
					? JSON.parse(geo)
					: geo
			} catch {
				return geo // Return as-is if parsing fails
			}
		}

		const [longArr, shortArr, sameOrder] = getLongAndShortArray(
			newData,
			oldData,
		)

		for (const longArrContact of longArr) {
			let found = false

			for (const shortArrContact of shortArr) {
				if (longArrContact.uuid === shortArrContact.uuid) {
					found = true

					for (const key in longArrContact) {
						const normalizedLongGeo = normalizeGeo(longArrContact.geo)
						const normalizedShortGeo = normalizeGeo(shortArrContact.geo)

						if (
							key === 'geo' &&
							JSON.stringify(normalizedLongGeo) !==
								JSON.stringify(normalizedShortGeo)
						) {
							result.push({
								name: 'contacts',
								oldValue: [
									shortArrContact.name,
									shortArrContact.email,
									normalizedShortGeo,
									shortArrContact.role,
									shortArrContact.skype,
									shortArrContact.type,
								].join(' - '),
								newValue: [
									longArrContact.name,
									longArrContact.email,
									normalizedLongGeo,
									longArrContact.role,
									longArrContact.skype,
									longArrContact.type,
								].join(' - '),
							})
							break
						} else if (longArrContact[key] !== shortArrContact[key]) {
							result.push({
								name: 'contacts',
								oldValue: [
									shortArrContact.name,
									shortArrContact.email,
									shortArrContact.geo,
									shortArrContact.role,
									shortArrContact.skype,
									shortArrContact.type,
								].join(' - '),
								newValue: [
									longArrContact.name,
									longArrContact.email,
									longArrContact.geo,
									longArrContact.role,
									longArrContact.skype,
									longArrContact.type,
								].join(' - '),
							})
							break
						}
					}
					break
				}
			}

			if (!found) {
				if (sameOrder) {
					result.push({
						name: 'contacts',
						oldValue: 'empty',
						newValue: [
							longArrContact.name,
							longArrContact.email,
							longArrContact.geo,
							longArrContact.role,
							longArrContact.skype,
							longArrContact.type,
						].join(' - '),
					})
				} else {
					result.push({
						name: 'contacts',
						oldValue: [
							longArrContact.name,
							longArrContact.email,
							longArrContact.geo,
							longArrContact.role,
							longArrContact.skype,
							longArrContact.type,
						].join(' - '),
						newValue: 'empty',
					})
				}
			}
		}
		return result
	}
	let items: any = []
	if (props.row) {
		if (props.row.change === 'Login') {
			items = [[{ name: 'Login', oldValue: '', newValue: '' }]]
		} else if (props.row.object === 'Finance upload') {
			items = [[{ name: props.row.change, oldValue: '', newValue: '' }]]
		} else if (props.row.change === 'removed campaign') {
			items = [[{ name: 'Removed campaign', oldValue: '', newValue: '' }]]
		} else {
			// console.log(props.row.change)
			const changes = JSON.parse(props.row.change)
			// console.log(changes)
			items = changes.map((el: string) => {
				// console.log(el)
				let values: any = []
				if (el.startsWith('contacts')) {
					values.push(extractContactValues(el))
				} else {
					values.push(extractValues(el))
				}
				// console.log(values)
				if (values.length === 0) {
					return null
				}
				return values.flat()
			})
		}
	}
	return (
		<Dialog
			open={props.PopUpVisible}
			sx={{
				'& .MuiDialog-paper': {
					width: '95%',
					maxHeight: '90%',
					background: theme.colors.base.white,
					color: theme.colors.text.titles,
				},
			}}
			fullWidth={true}
			maxWidth={'xl'}
		>
			<button
				onClick={props.handleClose}
				style={{
					display: 'flex',
					width: '40px',
					border: 'none',
					cursor: 'pointer',
					margin: '4px 4px 0px 4px',
					alignItems: 'center',
					justifyContent: 'center',
					alignSelf: 'flex-end',
				}}
			>
				<CloseIcon style={{ color: theme.colors.text.titles }} />
			</button>
			{/* <DialogTitle
				style={{
					display: 'flex',
					justifyContent: 'row',
					placeContent: 'space-between',
				}}
			>
				<span>{addingUser ? 'Add user' : 'Edit user'}</span>
				{isBlocked && (
					<span
						style={{
							fontSize: '16px',
							color: theme.colors.red,
							marginTop: '4px',
						}}
					>
						User is blocked
					</span>
				)}
			</DialogTitle> */}
			<DialogContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '18px',
					marginBottom: '10px',
				}}
			>
				<div
					style={{
						fontWeight: 'bold',
						margin: 'auto',
						marginBottom: '20px',
						display: 'flex',
						flexDirection: 'row',
						// justifyContent: 'space-evenly',
						width: '100%',
					}}
				>
					<span style={{ fontSize: '14px', fontWeight: '500' }}>
						Change done by: {props.row.email} at {props.row.time} | GMT +3
					</span>
					<span style={{ margin: 'auto' }}>{props.row.object}</span>
				</div>
				<table
					style={{
						// display: 'flex',

						maxWidth: '100%',
						width: 'inherit',
					}}
				>
					<thead
						style={{
							display: 'flex',
							flexDirection: 'column',
							marginBottom: '20px',
							width: '70vw',
						}}
					>
						<tr
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								width: '100%',
							}}
						>
							<th
								style={{
									width: '20%',
									textAlign: 'start',
									textDecoration: 'underline 2px',
									textUnderlineOffset: '6px',
								}}
							>
								Field
							</th>
							<th
								style={{
									width: '40%',
									textAlign: 'start',
									textDecoration: 'underline 2px',
									textUnderlineOffset: '6px',
								}}
							>
								Old Value
							</th>
							<th
								style={{
									width: '40%',
									textAlign: 'start',
									textDecoration: 'underline 2px',
									textUnderlineOffset: '6px',
								}}
							>
								New Value
							</th>
						</tr>
					</thead>
					{items && (
						<tbody
							style={{
								display: 'flex',
								gap: '12px',
								flexDirection: 'column',
								width: '70vw',
							}}
						>
							{items.map((item: any, index: number) => {
								// console.log(item)
								if (item.length === 0 || item[0] === null) {
									return <></>
								}
								return (
									<tr
										key={index}
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											width: '100%',
										}}
									>
										<td
											style={{
												width: '20%',
												textAlign: 'start',
												wordWrap: 'break-word',
											}}
										>
											{item[0].name}
										</td>
										<td
											style={{
												width: '40%',
												textAlign: 'start',
												wordWrap: 'break-word',
											}}
										>
											{item[0].oldValue}
										</td>
										<td
											style={{
												width: '40%',
												textAlign: 'start',
												wordWrap: 'break-word',
											}}
										>
											{item[0].newValue}
										</td>
									</tr>
								)
							})}
						</tbody>
					)}
				</table>
			</DialogContent>
		</Dialog>
	)
}

export default DetailedLogs
