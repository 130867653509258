import { ThemeProvider, useTheme } from '@mui/material'
import '@tremor/react/dist/esm/tremor.css'
import { saveAs } from 'file-saver'
import { useEffect, useRef, useState, useCallback, useMemo } from 'react'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import ReportsPageLayout from '../layouts/ReportsPageLayout'
import ReportTableSectionLayout from '../layouts/ReportTableSectionLayout'
import { handleAdjustedDateChange } from '../../../utils/helpers/helperFuncs'
import { useSearchParams } from 'react-router-dom'
import { FilterComponentList } from '../common/FilterComponentList'
import { ReportType } from '../../../models/reports.interface'
import { Dayjs } from 'dayjs'

const ReportPage = (props: {
	reportType: ReportType
	initialReportFields: any
	filtersToShow: string[]
	breakdownOptions: string[]
	statisticOptions: string[]
	getReportAction: any
	saveAction: any
	refreshAction?: any
	reportSelectors: (reports: any, email: string) => any[]
	ReportGrid: any
	ReportStyles: {
		StyledBox: any
		ReportItem: any
		ReportItemHeading: any
	}
	children?: any
	setLoading?: any
	errorMessage: string | null
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsWarningPromptOpened: any
	setViewRecord: any
}) => {
	const theme = useTheme()
	const [reportFields, setReportFields] = useState(props.initialReportFields)
	const [breakdownFields, setBreakdownFields] = useState<string[]>([])
	const [statisticsFields, setStatisticsFields] = useState<string[]>([])
	const [filters, setFilters] = useState<any>(
		props.initialReportFields.filters || {},
	)

	const [smallLoading, setSmallLoading] = useState(false)
	const [isReportOpen, setIsReportOpen] = useState<boolean>(false)
	const [fileDownload, setFileDownload] = useState<any>()
	const [presetVisible, setPresetVisible] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [chartData, setChartData] = useState<any>(null)
	const [loading, setLoading] = useState(false)
	const downloadButtonRef = useRef<HTMLButtonElement | null>(null)
	const [selectedReportData, setSelectedReportData] = useState<any>(null)

	const { login, reports } = useTypedSelector((state) => state)
	const [urlQuery] = useSearchParams()
	const [startDay, setStartDay] = useState<string>('')
	const [endDay, setEndDay] = useState<string>('')
	const reportTableRef = useRef<HTMLDivElement>(null)

	const handleDateChange = useCallback(
		(startDate: Dayjs | null, endDate: Dayjs | null) => {
			handleAdjustedDateChange(
				startDate,
				endDate,
				setStartDay,
				setEndDay,
				setReportFields,
			)
		},
		[setStartDay, setEndDay, setReportFields],
	)

	useEffect(() => {
		if (fileDownload) {
			saveAs(fileDownload, '*.csv')
		}
	}, [fileDownload])

	useEffect(() => {
		if (urlQuery.get('report')) {
			setIsReportOpen(true)
		}
	}, [urlQuery])

	useEffect(() => {
		props.setLoading(false)
	}, [props.setLoading])

	useEffect(() => {
		const data = {
			...reportFields,
			from_date: startDay,
			to_date: endDay,
			breakdowns: breakdownFields,
			statistics: statisticsFields,
			filters: filters,
		}
		setReportFields(data)
	}, [startDay, endDay, breakdownFields, statisticsFields, filters])

	const email = login.user?.email

	const reportData = useMemo(
		() => props.reportSelectors(reports, email),
		[reports, email],
	)

	useEffect(() => {
		if (isReportOpen && reportData.length > 0 && reportTableRef.current) {
			reportTableRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [isReportOpen, reportData.length])

	useEffect(() => {
		if (email && reportData.length > 0) {
			props.setViewRecord(reportData)
		}
	}, [reports, login.user.email, email, reportData, props])

	return (
		<ThemeProvider theme={theme}>
			<ReportsPageLayout
				filtersComponentList={
					<FilterComponentList
						filtersToShow={props.filtersToShow}
						filters={filters}
						setFilters={setFilters}
						onDateChange={handleDateChange}
					/>
				}
				breakdownOptions={props.breakdownOptions}
				statisticOptions={props.statisticOptions}
				reportFields={reportFields}
				setReportFields={setReportFields}
				getReportAction={props.getReportAction}
				saveAction={props.saveAction}
				refreshAction={props.refreshAction}
				setLoading={setLoading}
				setErrorMessage={props.setErrorMessage}
				isErrorPromptOpened={props.isErrorPromptOpened}
				setIsErrorPromptOpened={props.setIsErrorPromptOpened}
				setIsWarningPromptOpened={props.setIsWarningPromptOpened}
				setReport={props.setViewRecord}
				viewRecord={props.setViewRecord}
				setViewRecord={props.setViewRecord}
				breakdownFields={breakdownFields}
				setBreakdownFields={setBreakdownFields}
				statisticsFields={statisticsFields}
				setStatisticsFields={setStatisticsFields}
				presetVisible={presetVisible}
				setPresetVisible={setPresetVisible}
				isSaving={isSaving}
				setIsSaving={setIsSaving}
				isReportOpen={isReportOpen}
				setIsReportOpen={setIsReportOpen}
				downloadButtonRef={downloadButtonRef}
				ReportGrid={props.ReportGrid}
				ReportStyles={props.ReportStyles}
				fileDownload={fileDownload}
				setFileDownload={setFileDownload}
				login={login}
				report={reportData}
				loading={loading}
				reportType={props.reportType}
				userEmail={login.user.email}
				selectedReport={selectedReportData}
			/>

			{reportData.length > 0 && (
				<div ref={reportTableRef}>
					<ReportTableSectionLayout
						report={reportData}
						chartData={chartData}
						reportFields={reportFields}
						isReportOpen={isReportOpen}
						setIsReportOpen={setIsReportOpen}
						customReportFields={props.statisticOptions}
						reportType={props.reportType}
						setSelectedReportData={setSelectedReportData}
						smallLoading={smallLoading}
						setSmallLoading={setSmallLoading}
					/>
				</div>
			)}
		</ThemeProvider>
	)
}

export default ReportPage
