import { ThemeProvider } from '@emotion/react'
import '@inovua/reactdatagrid-community/index.css'
import {
	Grid,
	LinearProgress,
	MenuItem,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { format } from 'date-fns'
import saveAs from 'file-saver'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
	useActions,
	useAdvertiserActions,
	useLogActions,
} from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchData } from '../../utils/helpers/navigationHelper'
import { hasOwnerAccess } from '../../utils/helpers/helperFuncs'
// import PreviewIcon from '../../assets/svg/preview-icon.svg'
import { ReactComponent as PreviewIcon } from '../../assets/svg/preview-icon.svg'
import { ReactComponent as AndroidIcon } from '../../assets/svg/android-icon.svg'
import AppleIcon from '@mui/icons-material/Apple'
import { ReactComponent as EditIcon } from '../../assets/svg/edit-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/svg/delete-icon.svg'
import {
	capitalizeFirstLetter,
	findUsernameByEmail,
	generateRowsWithIds,
	searchFor,
} from '../../utils/helpers/tableHelper'
import { WarningPrompt } from '../../utils/notifications/WarningPrompt'
import { PageWrapper, TableWrapper } from '../components/reusableComponents'
import { ActiveChip, DisabledChip } from '../publishers/PublisherComponents'
import AdvertiserPopUp from './AdvertiserPopUp'
import { StatusTag } from '../components/Informative'
import { TableComponent } from '../components/TableComponents'
import { ActionsButton, SimpleActionsButton } from '../components/Buttons'
import defaultIcon from '../../assets/default-icon.webp'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { HeaderFilterComponent } from '../components/TableHelperComponents'
import { THING_OR_TWO_COMPANY_ID } from '../../config'

const AdvertiserPage = (props: {
	children?: any
	setLoading?: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	isSuccessPromptOpened: any
	setIsSuccessPromptOpened: any
	viewRecord: any
	setViewRecord: any
	popUpClear: boolean
	PopUpVisible: boolean
	setPopUpVisible: any
	setForcedFilterStatus: any
	search: string
	setSearch: Dispatch<SetStateAction<string>>
	presetSearch: string
	setPresetSearch: Dispatch<SetStateAction<string>>
}) => {
	const navigate = useNavigate()
	const params = useParams()

	const rowCount = 7
	const { advertiser, login, users, app, settings, company } = useTypedSelector(
		(state) => state,
	)
	const { getAppsAction, presearchAction } = useActions()
	const {
		deleteAdvertiser,
		getAdvertiserReport,
		getAdvertiserContacts,
		getAdvertiserAction,
	} = useAdvertiserActions()
	const { insertLog } = useLogActions()

	const theme = useTheme()
	const sessionKey = 'filters-advertisers-table'
	const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
	let sessionFilters: any = {}
	if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
		sessionFilters = JSON.parse(sessionSerializedFilters)
	}
	const [pageSize, setPageSize] = useState(15)
	const [isNewAdvertiser, SetIsNewAdvertiser] = useState(false)
	// const [PopUpVisible, setPopUpVisible] = useState(false)
	// const [search, setSearch] = useState(
	// 	sessionFilters.search ? sessionFilters.search : '',
	// )
	// const [presetSearch, setPresetSearch] = useState(
	// 	sessionFilters.search ? sessionFilters.search : '',
	// )
	const [update, setUpdate] = useState(false)
	// const [found, setFound] = useState<any[]>([])
	const [row, setRow] = useState()
	const [downloadFile, setDownloadFile] = useState<string | null>(null)
	// const [isRowClickable, setIsRowClickable] = useState(false)
	const [isWarningPromptOpened, setIsWarningPromptOpened] = useState(false)
	const [rowWidth, setRowWidth] = useState(
		Math.floor((window.innerWidth - theme.dimentions.drawerWidth) / rowCount),
	)
	const [advertiserFilterStatus, setAdvertiserFilterStatus] = useState(1)
	const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'desc' }])
	//by default, the first advertiser list must be of only actives ones.
	const [filteredAdvertiser, setFilteredAdvertiser] = useState(
		Array.isArray(advertiser.advertiser)
			? advertiser.advertiser.filter((el: any) => el.status === true)
			: [],
	)
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_ADVERTISER),
		preview: useIsAuthorized(permissionNames.PREVIEW_ADVERTISERS),
		delete: useIsAuthorized(permissionNames.DELETE_ADVERTISER),
		downloadContacts: useIsAuthorized(permissionNames.DOWNLOAD_CONTACT_LIST),
		downloadAdvertiserList: useIsAuthorized(
			permissionNames.DOWNLOAD_ADVERTISERS_LIST,
		),
		accessApps: useIsAuthorized(permissionNames.ACCESS_TO_APPS),
		editOwnerOnly: useIsAuthorized(permissionNames.EDIT_ADVERTISER_OWNER_ONLY),
	}
	const [tableRows, setTableRows] = useState(
		generateRowsWithIds(filteredAdvertiser),
	)

	const [customFilter, setCustomFilter] = useState<any>(
		sessionFilters.customFilter
			? sessionFilters.customFilter
			: {
					advertiser_name: [],
					email: [],
					platform: [],
			  },
	)
	const activeUsersEmails = new Set(
		tableRows.map((element: any) => element.email),
	)
	const getActiveUsers = (emailsSet: any) => {
		const result = []
		for (const email of emailsSet) {
			for (const user of users.users) {
				if (email === user.email) {
					result.push(user)
					break
				}
			}
		}
		return result
	}
	const [optionsFilters, setOptionsFilter] = useState({
		email: getActiveUsers(activeUsersEmails),
		platform: new Set(
			tableRows
				.map((element: any) => element.platform)
				.filter((el) => el.length > 0),
		),
	})

	const [currentFilters, setCurrentFilters] = useState('')
	const [filterLabel, setFilterLabel] = useState('')
	const [unfiltered, setUnfiltered] = useState<any>([])
	const [openFilter, setOpenFilter] = useState(false)
	const [applyFilter, setApplyFilter] = useState(false)
	const possibleAdvertiserFilter = ['both', 'active', 'disabled']

	//this useEffect is used to handle the status button and the search bar of the main table.
	useEffect(() => {
		let filtered: any
		if (props.search.length > 0) {
			let searched = searchFor(props.search, advertiser.advertiser, columns)
			if (possibleAdvertiserFilter[advertiserFilterStatus] === 'both') {
				filtered = searched
			} else if (
				possibleAdvertiserFilter[advertiserFilterStatus] === 'active'
			) {
				filtered = searched.filter((el: any) => el.status === true)
			} else if (
				possibleAdvertiserFilter[advertiserFilterStatus] === 'disabled'
			) {
				filtered = searched.filter((el: any) => el.status === false)
			}
		} else {
			if (possibleAdvertiserFilter[advertiserFilterStatus] === 'both') {
				filtered = advertiser.advertiser
			} else if (
				possibleAdvertiserFilter[advertiserFilterStatus] === 'active'
			) {
				filtered = advertiser.advertiser.filter((el: any) => el.status === true)
			} else if (
				possibleAdvertiserFilter[advertiserFilterStatus] === 'disabled'
			) {
				filtered = advertiser.advertiser.filter(
					(el: any) => el.status === false,
				)
			}
		}
		let anyfilters = false
		let additionalFiltersApplied = []
		//this is used to filter by the buttons of the columns headers.
		for (let [index, data] of filtered.entries()) {
			// let found = false
			let foundWrong = false

			for (const key in customFilter) {
				// let wrongKey = false
				let foundByKey = false

				if (customFilter[key].length > 0) {
					anyfilters = true

					foundByKey = customFilter[key].includes(data[key])
					if (key === 'platform') {
						for (const checked of customFilter[key]) {
							foundByKey = data[key].includes(checked)
							if (foundByKey === true) {
								break
							}
						}
					}
					if (key === 'email') {
						const userFound = users.users.find(
							(e: any) =>
								customFilter[key].includes(e.name) && data.email === e.email,
						)
						foundByKey = !!userFound
					}
					if (!foundByKey) {
						foundWrong = true
						break
					}
				}
			}
			if (!foundWrong) {
				additionalFiltersApplied.push(data)

				continue
			}
		}
		let arr = []
		if (!anyfilters) {
			arr = generateRowsWithIds(filtered)
		} else if (additionalFiltersApplied.length > 0 && anyfilters) {
			arr = generateRowsWithIds(additionalFiltersApplied)
		} else {
			arr = generateRowsWithIds([])
		}
		setTableRows(arr)
	}, [advertiserFilterStatus, props.search, applyFilter, advertiser.advertiser])

	const handleLoading = async () => {
		props.setLoading(true)
		// await fetchData(getAdvertiserAction, advertiser.advertiser)
		// await fetchData(getAppsAction, app.app)
		const sessionSerializedFilters = sessionStorage.getItem(sessionKey)
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			const sessionFilters = JSON.parse(sessionSerializedFilters)
			setCurrentFilters('advertiser')
			sessionFilters.customFilter &&
				setCustomFilter(sessionFilters.customFilter)
		}
		props.setLoading(false)
	}

	useEffect(() => {
		handleLoading()
	}, [])

	useEffect(() => {
		if (filterLabel) {
			const filters = {
				customFilter,
			}
			const serializedFilters = JSON.stringify(filters)
			sessionStorage.setItem(sessionKey, serializedFilters)
		}
	}, [applyFilter])

	useEffect(() => {
		if (!props.PopUpVisible && params.id) {
			handleEdit(params.id)
		}
	}, [])

	const sortingCustomModel = (a: any, b: any) => {
		return a - b
	}

	const showPlatform = (value: any) => {
		if (value.length > 1) {
			return 'Multiple Platforms'
		} else if (value.length === 1) {
			if (value[0]) {
				return capitalizeFirstLetter(value[0])
			}
		}
		return 'No Platform'
	}
	//here we define the columns that are going to be rendered and their properties.
	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: rowWidth * 0.3,
		},
		{
			field: 'advertiser_name',
			headerName: 'Name',
			width: rowWidth * 1.4,
			renderCell: ({ row }) => (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<img
						src={row.advertiser_icon || defaultIcon}
						onError={(e) => {
							e.currentTarget.onerror = null
							e.currentTarget.src = defaultIcon
						}}
						alt={row.advertiser_name}
						style={{
							width: 28,
							height: 28,
							borderRadius: '50%',
							marginRight: 8,
						}}
					/>
					<Typography sx={{ fontWeight: 600, fontSize: 14 }}>
						{row.advertiser_name}
					</Typography>
				</div>
			),
		},
		{
			field: 'status',
			headerName: 'Status',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 0.8,
			renderCell: ({ value }) =>
				value ? (
					// <ActiveChip label='Active' />
					<StatusTag
						label={'Active'}
						style={{
							color: theme.colors.text.colored,
							fontWeight: theme.font.weight.normal,
						}}
					/>
				) : (
					// <DisabledChip label='Disabled' style={{ marginLeft: '10px' }} />
					<StatusTag label={'Disabled'} />
				),
		},
		{
			field: 'email',
			headerName: 'Owner',
			width: rowWidth * 0.6,
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Owner'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'email'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			//we want to render the name of the user, not his email.
			renderCell: ({ value }) =>
				value ? findUsernameByEmail(value.toString(), users.users) : 'User',
		},

		{
			field: 'count',
			headerName: 'Campaigns',
			align: 'center',
			headerAlign: 'center',
			width: rowWidth * 1,
			renderCell: ({ row, value }) => {
				return (
					<Typography
						style={{ cursor: 'pointer' }}
						onClick={() => {
							handleCampaignsLink(row)
						}}
					>
						{'' + parseInt(value?.toString() || '')?.toString() || '0'}
					</Typography>
				)
			},
		},

		{
			field: 'platform',
			headerName: 'Platforms',
			width: rowWidth * 0.7,
			//platform is not mandatory so we need to render a custom string if we miss the platform.
			renderCell: ({ value }) => showPlatform(value),
			renderHeader: () => {
				return (
					<HeaderFilterComponent
						label={'Platforms'}
						width={rowWidth * 1.2}
						openFilter={openFilter}
						setOpenFilter={setOpenFilter}
						field={'platform'}
						customFilter={customFilter}
						currentFilters={currentFilters}
						setCurrentFilters={setCurrentFilters}
						setFilterLabel={setFilterLabel}
						optionsFilters={optionsFilters}
					/>
				)
			},
			sortComparator: (v1, v2) => {
				return v1.join('').localeCompare(v2.join(''))
			},
		},
		{
			field: 'system',
			headerName: 'Operated System',
			align: 'center',
			headerAlign: 'center',
			width: rowWidth * 0.8,
			renderCell: ({ row }) => {
				let androidActive = false
				let iOSActive = false
				for (const app_id of app.app) {
					if (row.advertiser_name === app_id.advertiser_name) {
						if (app_id.app_id.startsWith('id')) {
							iOSActive = true
						} else {
							androidActive = true
						}
					}
					if (iOSActive === true && androidActive === true) {
						break
					}
				}

				return (
					<div style={{ display: 'flex', justifyContent: 'center', gap: 6 }}>
						<AppleIcon
							style={{
								color: iOSActive
									? theme.colors.status.green
									: theme.colors.base.grey300,
								scale: '1.3',
							}}
						/>
						<AndroidIcon
							style={{
								color: androidActive
									? theme.colors.status.green
									: theme.colors.base.grey300,
								scale: '0.9',
							}}
						/>
					</div>
				)
			},
		},
		// {
		// 	field: 'createdAt',
		// 	headerAlign: 'center',
		// 	headerName: 'Created at',
		// 	width: rowWidth * 0.7,
		// 	align: 'center',
		// 	renderCell: ({ value }) =>
		// 		value ? format(new Date(value.toString()), 'MM.dd.yyyy') : 'Now',
		// },
		// {
		// 	field: 'progress',
		// 	headerAlign: 'center',
		// 	align: 'center',
		// 	headerName: 'Progress',
		// 	width: rowWidth,
		// 	valueGetter: (row) => getProgress(row),
		// 	//variables _1,_2 and _3 are undefined.
		// 	sortComparator: sortingCustomModel,

		// 	renderCell: (row) => <RenderProgress row={row} />,
		// },
		// {
		// 	field: 'daily_budget_limit',
		// 	headerAlign: 'center',
		// 	align: 'center',
		// 	headerName: 'Budget',
		// 	width: rowWidth,
		// 	sortComparator: sortingCustomModel,
		// 	renderCell: (row) => {
		// 		return (
		// 			<div
		// 				style={{
		// 					display: 'flex',
		// 					flexDirection: 'column',
		// 					height: '90%',
		// 					justifyContent: 'center',
		// 				}}
		// 			>
		// 				{row.row.daily_budget_limit && row.row.daily_budget_limit > '0' && (
		// 					<RenderBudget
		// 						budget_limit={row.row.daily_budget_limit}
		// 						revenue={row.row.daily_revenue}
		// 						title={'Daily'}
		// 					/>
		// 				)}
		// 				{row.row.monthly_budget_limit &&
		// 					row.row.monthly_budget_limit > '0' && (
		// 						<RenderBudget
		// 							budget_limit={row.row.monthly_budget_limit}
		// 							revenue={row.row.monthly_revenue}
		// 							title={'Monthly'}
		// 						/>
		// 					)}
		// 				{row.row.total_budget_limit && row.row.total_budget_limit > '0' && (
		// 					<RenderBudget
		// 						budget_limit={row.row.total_budget_limit}
		// 						revenue={row.row.total_revenue}
		// 						title={'Total'}
		// 					/>
		// 				)}
		// 			</div>
		// 		)
		// 	},
		// },
		(permissions.edit ||
			permissions.preview ||
			permissions.editOwnerOnly ||
			(permissions.delete as any)) && {
			field: 'actions',
			headerName: 'Actions',
			headerAlign: 'center',
			align: 'center',
			width: rowWidth * 1.2,
			sortable: false,
			renderCell: (row) => <ActionsButton options={actionOptions(row)} />,
		},
	]

	//this is the function we use to calculate the % of progress of filled data in the advertiser.
	const getProgress = (row: any) => {
		const obj = Object.entries(row.row)
		const empty = obj.filter((a) => {
			return a[1] !== ''
		})
		let result: any[] = []
		obj
			.filter((a) => {
				return a[1] === ''
			})
			.forEach((a, i) => result.push(i + 1, ') ', a[0], ', '))
		const entries = 100 / obj.length
		const progress = Math.floor(empty.length * entries)

		return progress
	}
	//this is the function we use to render the % of progress of filled data in the advertiser.
	const RenderProgress = (row: any) => {
		const obj = Object.entries(row.row.row)
		const empty = obj.filter((a) => {
			return a[1] !== ''
		})
		let result: any[] = []
		obj
			.filter((a) => {
				return a[1] === ''
			})
			.forEach((a, i) => result.push(i + 1, ') ', a[0], ', '))
		//we remove 6 from the total length because we have things saved that doesn't apply to the user data (like daily revenue which comes from CRON)
		const entries = 100 / (obj.length - 6)
		//If everything is fetched and CRON did everything in our advertiser then we will have more than 100 so we hotfix it:
		const progress =
			Math.floor(empty.length * entries) > 100
				? 100
				: Math.floor(empty.length * entries)

		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					// marginBottom: '8px',
				}}
			>
				<Tooltip title={result}>
					<LinearProgress
						color={
							progress > 70 ? (progress < 85 ? 'warning' : 'success') : 'error'
						}
						style={{
							width: '100px',
							height: '14px',
							borderRadius: '4px',
							zIndex: 3,
							position: 'relative',
							top: '10px',
						}}
						variant='determinate'
						value={progress}
					/>
				</Tooltip>
				<Typography
					fontSize={'14px'}
					fontWeight={600}
					style={{
						position: 'relative',
						top: '-7px',
						zIndex: 4,
						color: 'white',
					}}
				>
					{progress.toString() + '%'}
				</Typography>
			</div>
		)
	}
	//we use this in order to a int number. We treat it like this because it's actually a string and also because we don't care about
	//the floating part. This is used to render the budget which is an estimation.
	function getStringBeforePeriod(inputString: string) {
		if (inputString && inputString.includes('.')) {
			return inputString.split('.')[0]
		} else {
			return inputString
		}
	}
	const RenderBudget = (data: any) => {
		const budget_limit = data.budget_limit
		const revenue = data.revenue > 0 ? data.revenue : 0
		let currentPoint = 0
		if (budget_limit) {
			if (parseInt(budget_limit) < parseInt(revenue)) {
				currentPoint = 100
			} else {
				currentPoint = Math.floor((parseInt(revenue) * 100) / budget_limit)
			}
		}

		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: '5px',
					justifyContent: 'space-between',
					alignItems: 'center',
					minWidth: '200px',
				}}
			>
				<div>
					<Typography
						fontSize={'12px'}
						fontWeight={600}
						style={{
							position: 'relative',
							top: '-1px',
							alignSelf: 'flex-start',
							zIndex: 4,
							color: 'black',
						}}
					>
						{data.title + ':'}
					</Typography>
				</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<div>
						<LinearProgress
							color={
								currentPoint > 90
									? 'success'
									: currentPoint > 70
									? 'warning'
									: 'error'
							}
							style={{
								width: '140px',
								height: '14px',
								borderRadius: '4px',
								zIndex: 3,
								position: 'relative',
								top: '6px',
							}}
							variant='determinate'
							value={currentPoint}
						/>
					</div>
					<Typography
						fontSize={'11px'}
						fontWeight={600}
						style={{
							position: 'relative',
							top: '-9px',
							zIndex: 4,
							color: 'white',
						}}
					>
						{parseInt(budget_limit) > parseInt(revenue)
							? getStringBeforePeriod(revenue) + ' / ' + budget_limit + ' $'
							: 'Over budget (' + getStringBeforePeriod(revenue) + ' $)'}
					</Typography>
				</div>
			</div>
		)
	}

	// const headers: userParams = {
	// 	Authorization: `Token ${login.user.token}`,
	// }
	//we use this to download the excel file from the table.
	useEffect(() => {
		if (downloadFile) {
			saveAs(downloadFile, '*.csv')
		}
	}, [downloadFile])

	const getAdvertiserNameArray = (rows: any) => {
		return rows.map((el: any) => "'" + el.advertiser_name + "'")
	}
	const handleDownloadReport = async () => {
		try {
			await getAdvertiserReport(
				setDownloadFile,
				getAdvertiserNameArray(tableRows),
			)
			await fetchData(getAdvertiserAction, advertiser.advertiser)
		} catch (error) {
			console.error(error)
		}
	}
	const handleDownloadContacts = async () => {
		try {
			await getAdvertiserContacts(setDownloadFile)
			await fetchData(getAdvertiserAction, advertiser.advertiser)
		} catch (error) {
			console.error(error)
		}
	}
	//this is basically what we use to handle the screen change to create a new advertiser.
	const handlePopUp = async () => {
		props.setPopUpVisible(!props.PopUpVisible)
		SetIsNewAdvertiser(true)
		if (props.PopUpVisible) {
			await fetchData(getAdvertiserAction, advertiser.advertiser)
			await fetchData(getAppsAction, app.app)
			setUpdate(!update)
			setRow(undefined)
		}
	}

	//we use this logic in order to instantly open preview when clicking in a row. We also want to NOT open it if actions or campaigns number are clicked
	const handleCellClick = (params: any, ev: React.MouseEvent) => {
		if (params.field !== 'actions' && params.field !== 'count') {
			// const advertiserName = params.row.advertiser_name
			const advertiserId = params.row.id
			if (ev.ctrlKey || ev.metaKey) {
				// opening in a new tab if the user did ctrl+click
				window.open('/advertisers/' + advertiserId + '/preview', '_blank')
				return
			}
			handlePreview(advertiserId)
		}
	}
	//navigating to preview page
	const handlePreview = (advertiser_id: any) => {
		navigate('/advertisers/' + advertiser_id + '/preview')
		props.setPopUpVisible(!props.PopUpVisible)
	}
	//navigating to edit page
	const handleEdit = (advertiser_id: any) => {
		navigate('/advertisers/' + advertiser_id)
		props.setPopUpVisible(!props.PopUpVisible)
	}
	//this is legacy code. We probably could refactor this. //TODO: refactor ?
	useEffect(() => {
		if (props.popUpClear) {
			props.setPopUpVisible(false)
		}
	}, [props.popUpClear])

	const handleLogDelete = async (advertiser: any) => {
		const log = {
			email: login.user.email,
			object: advertiser! + ' in Advertisers',
			change: 'removed advertiser',
		}
		await insertLog(log)
	}

	const handleDelete = async (row: any) => {
		props.setLoading(true)
		const payload: any = {
			advertiser_name: row.advertiser_name,
			email: login.user.email,
			id: row.id,
			//TODO: change the logic behind this.
			rule_set: {},
			// rule_set: ruleSet,
		}
		// const headers = {
		// 	Authorization: `Token ${login.user.token}`,
		// }
		const failed = await deleteAdvertiser(payload, props.setErrorMessage)
		if (!failed) {
			props.setIsSuccessPromptOpened(true)
		}
		// handleLogDelete(row.advertiser_name)
		await fetchData(getAdvertiserAction, advertiser.advertiser)
		setUpdate(!update)
		props.setLoading(false)
	}
	//the function we use to search for the campaings when clicking in the campaign number.
	const handleCampaignsLink = (row: any) => {
		const campaignSessionKey = 'filters-campaigns-table'
		const sessionSerializedFilters = sessionStorage.getItem(campaignSessionKey)
		let sessionFilters: any = {}
		if (sessionSerializedFilters && sessionSerializedFilters.length > 4) {
			sessionFilters = JSON.parse(sessionSerializedFilters)
		}
		let newFilters = { ...sessionFilters }
		newFilters.search = row.advertiser_name
		const serializedFilters = JSON.stringify(newFilters)
		sessionStorage.setItem(campaignSessionKey, serializedFilters)
		props.setForcedFilterStatus(0)
		navigate('/campaigns')
	}
	const permissionsCheck = (row: any) => {
		return (
			(row.row.email === login.user.email ||
				row.row.created_by === login.user.email) &&
			permissions.editOwnerOnly
		)
	}

	const actionOptions = (row: any) => {
		// const sameCompanyId = row.row.company_id === login.company.id
		const hasOwnerAccessToRow = hasOwnerAccess(row.row)
		const canDelete = hasOwnerAccess(row.row, permissions, 'delete')
		const canEdit = hasOwnerAccess(row.row, permissions, 'edit')
		return [
			{
				label: '',
				icon: (
					<PreviewIcon
						style={{ color: theme.colors.text.titles, scale: '10' }}
						className={'action-button'}
					/>
				),
				onClick: () => handlePreview(row.row.id),
				rule: permissionsCheck(row) || permissions.preview,
				key: 'preview',
			},
			{
				label: '',
				icon: (
					<EditIcon
						style={{ color: theme.colors.text.titles, scale: '7.2' }}
						className={'action-button'}
					/>
				),
				onClick: () => handleEdit(row.row.id),
				rule: canEdit,
				key: 'edit',
			},
			{
				label: '',
				icon: (
					<DeleteIcon
						style={{ color: theme.colors.text.titles, scale: '7.4' }}
						className={'action-button'}
					/>
				),
				onClick: () => {
					setRow(row.row)
					setIsWarningPromptOpened(true)
				},
				rule: canDelete,
				key: 'delete',
			},
		]
	}
	const downloadButtonoptions = (
		<div>
			{permissions.downloadAdvertiserList ? (
				<MenuItem onClick={() => handleDownloadReport()}>
					Advertisers list
				</MenuItem>
			) : undefined}
			{permissions.downloadContacts ? (
				<MenuItem onClick={() => handleDownloadContacts()}>
					Contacts list
				</MenuItem>
			) : undefined}
		</div>
	)
	const handleApplyFilter = () => {
		setApplyFilter(!applyFilter)
	}
	//the basic idea is that if a row is selected we will render AdvertiserPopUp. Otherwise we render the table.
	return !props.PopUpVisible ? (
		<ThemeProvider theme={theme}>
			<PageWrapper>
				<Grid
					container
					spacing={0}
					direction={'row'}
					alignItems={'center'}
					width={'100%'}
				></Grid>
				<TableWrapper
					theme={theme}
					rowCount={rowCount}
					setRowWidth={setRowWidth}
					style={{ paddingBottom: '60px', width: '90%' }}
				>
					<TableComponent
						columns={columns}
						rows={tableRows}
						setPageSize={setPageSize}
						pageSize={pageSize}
						rowsPerPageOptions={[15, 25, 50]}
						setLoading={props.setLoading}
						filterStatus={advertiserFilterStatus}
						setFilterStatus={setAdvertiserFilterStatus}
						handlePopUp={permissions.edit ? handlePopUp : undefined}
						downloadButtonOptions={
							permissions.downloadAdvertiserList || permissions.downloadContacts
								? downloadButtonoptions
								: undefined
						}
						onCellClick={permissions.preview ? handleCellClick : undefined}
						sortModel={sortModel}
						onSortModelChange={(model: any) => setSortModel(model)}
						filterHandler={openFilter}
						setFilterHander={setOpenFilter}
						customFilter={customFilter}
						setCustomFilter={setCustomFilter}
						currentFilters={currentFilters}
						applyFilter={handleApplyFilter}
						filterLabel={filterLabel}
						optionsFilters={optionsFilters}
						selector={navigate}
						selectorProp={permissions.accessApps ? '/apps' : undefined}
						// presetSearch={presetSearch}
					></TableComponent>
				</TableWrapper>
				<WarningPrompt
					message={'Deleting the row now?'}
					PopUpVisible={isWarningPromptOpened}
					setPopUpVisible={setIsWarningPromptOpened}
					onAgree={() => handleDelete(row)}
					onCancel={() => props.setLoading(false)}
					obj={row}
				/>
			</PageWrapper>
		</ThemeProvider>
	) : (
		<AdvertiserPopUp
			PopUpVisible={props.PopUpVisible}
			setPopUpVisible={handlePopUp}
			setLoading={props.setLoading}
			row={row}
			errorMessage={props.errorMessage}
			setErrorMessage={props.setErrorMessage}
			isErrorPromptOpened={props.isErrorPromptOpened}
			setIsErrorPromptOpened={props.setIsErrorPromptOpened}
			isSuccessPromptOpened={props.isSuccessPromptOpened}
			setIsSuccessPromptOpened={props.setIsSuccessPromptOpened}
			isNewAdvertiser={isNewAdvertiser}
			setIsNewAdvertiser={SetIsNewAdvertiser}
		/>
	)
}

export default AdvertiserPage
