import { FormGroup } from '@mui/material'
import { useState, useCallback, useMemo, useEffect } from 'react'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import CustomDateRangePicker from '../../components/dateInputs/CustomDateRangePicker'
import { generatePidListCompanyBased } from '../../../utils/helpers/reportHelperFuncs'
import { companyTypeConfig } from '../../../config'
import CheckedFilter from '../../components/CheckedFilter'
import { countryList } from '../../components/countryList'
import { Dayjs } from 'dayjs'

export const FilterComponentList = (props: {
	filtersToShow: string[]
	filters: any
	setFilters: any
	onDateChange?: (startDate: Dayjs | null, endDate: Dayjs | null) => void
}) => {
	const {
		advertiser,
		users,
		publisher,
		campaign,
		app,
		accounts,
		login,
		networks,
	} = useTypedSelector((state) => state)

	const [checkedState, setCheckedState] = useState<{ [key: string]: boolean }>(
		{},
	)

	const [appIdList, setAppIdList] = useState<string[]>([])

	const makeList = useCallback(
		(field: string) => {
			const unfiltered = advertiser.advertiser.map((e: any) => e[field])
			return [...new Set(unfiltered)]
		},
		[advertiser.advertiser],
	)

	const companyType = login.company.type
	const companyId = login.company.id

	// Build a mapping from advertiser_name to app_ids
	const advertiserToAppIds = useMemo(() => {
		const map: { [key: string]: string[] } = {}
		app.app.forEach((appItem: any) => {
			if (appItem.company_id === companyId) {
				const advertiserName = appItem.advertiser_name
				if (!map[advertiserName]) {
					map[advertiserName] = []
				}
				map[advertiserName].push(appItem.app_id)
			}
		})
		return map
	}, [app.app, companyId])

	useEffect(() => {
		if (!app.app || app.app.length === 0) {
			// Data not yet loaded
			return
		}

		// Sanitize advertiser names
		const advertiserNames = (props.filters.advertiser_name || []).filter(
			(name: string) => name && name.trim() !== '',
		)

		if (advertiserNames.length > 0) {
			const selectedAppIds = advertiserNames.flatMap((advertiserName: any) => {
				return advertiserToAppIds[advertiserName] || []
			})
			setAppIdList(Array.from(new Set(selectedAppIds)))
		} else {
			// No advertiser selected, include all app IDs
			const appIds = app.app
				.filter(
					(appItem: any) => String(appItem.company_id) === String(companyId),
				)
				.map((e: any) => e.app_id)
			setAppIdList(appIds)
		}
	}, [advertiserToAppIds, props.filters.advertiser_name, app.app, companyId])

	const pidList = useMemo(() => {
		return generatePidListCompanyBased(
			publisher.publisher,
			companyType,
			companyId,
			companyTypeConfig,
		)
	}, [publisher.publisher, companyType, companyId])

	const publisherList = useMemo(() => {
		return publisher.publisher.map((e: any) => e.publisher_name)
	}, [publisher.publisher])

	const userList = useMemo(() => {
		return users.users
			.filter((user: any) => !user.blocked)
			.map((e: any) => e.email)
	}, [users.users])

	const campaignNameList = useMemo(() => {
		return campaign.campaign.map((e: any) => e.campaign_name)
	}, [campaign.campaign])

	const agencyAccountsList = useMemo(() => {
		return accounts.accounts.map((e: any) => e.identifier)
	}, [accounts.accounts])

	const platformList = useMemo(() => ['ios', 'android'], [])

	const agencyList = useMemo(() => {
		return networks.networks ? [...new Set(networks.networks)] : []
	}, [networks.networks])

	const filterConfigs: { [key: string]: any } = useMemo(
		() => ({
			date_range: {
				label: 'Date Range',
				component: (
					<CustomDateRangePicker
						startDate={null}
						endDate={null}
						onDateChange={(startDate, endDate) => {
							if (props.onDateChange) {
								props.onDateChange(startDate, endDate)
							}
						}}
					/>
				),
			},
			advertiser_name: {
				label: 'Advertiser',
				array: makeList('advertiser_name'),
				value: props.filters.advertiser_name,
				condition: companyType === 'agency',
			},
			publisher_name: {
				label: 'Publisher',
				array: publisherList,
				value: props.filters.publisher_name,
				condition: companyType !== 'publisher',
			},
			email: {
				label: 'User',
				array: userList,
				value: props.filters.email,
			},
			app_id: {
				label: 'App Id',
				array: appIdList,
				value: props.filters.app_id || [],
				condition: companyType !== 'publisher',
			},
			media_source_pid: {
				label: 'PID',
				array: pidList,
				value: props.filters.media_source_pid,
				condition: companyType !== 'advertiser',
			},
			country: {
				label: 'Country',
				array: countryList,
				value: props.filters.country,
			},
			campaign_name: {
				label: 'Campaign Name',
				array: campaignNameList,
				value: props.filters.campaign_name,
			},
			agencyAccounts: {
				label: 'Agency Accounts',
				array: agencyAccountsList,
				value: props.filters.agencyAccounts,
			},
			platform: {
				label: 'Platform',
				array: platformList,
				value: props.filters.platform,
			},
			agency: {
				label: 'Agency',
				array: agencyList,
				value: props.filters.agency,
			},
		}),
		[
			makeList,
			props.filters,
			companyType,
			publisherList,
			userList,
			appIdList,
			pidList,
			campaignNameList,
			agencyAccountsList,
			platformList,
			agencyList,
		],
	)

	const handleCheckedChange = useCallback(
		(filterId: string) => (checked: boolean) => {
			setCheckedState((prevState) => ({ ...prevState, [filterId]: checked }))
		},
		[],
	)

	const handleFilterChange = (filterId: string, values: string[]) => {
		props.setFilters((prevFilters: any) => ({
			...prevFilters,
			[filterId]: values,
		}))
	}

	return (
		<FormGroup
			key={'form-group-2'}
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '18px',
				flexWrap: 'wrap',
				width: '100%',
			}}
		>
			{props.filtersToShow.map((filterId) => {
				const config = filterConfigs[filterId]
				if (config.condition === false) {
					return null
				}
				return (
					<CheckedFilter
						key={filterId}
						id={filterId}
						onChange={handleCheckedChange(filterId)}
						checked={checkedState[filterId] !== false}
						label={config.label}
						arrayOnChange={(values: string[]) =>
							handleFilterChange(filterId, values)
						}
						array={config.array || []}
						disabled={config.value === undefined}
						value={config.value || []}
					>
						{filterId === 'date_range' ? config.component : null}
					</CheckedFilter>
				)
			})}
		</FormGroup>
	)
}
