import VisibilityIcon from '@mui/icons-material/Visibility'
import AddIcon from '@mui/icons-material/Add'
import defaultIcon from '../../assets/default-icon.webp'

import {
	Box,
	Card,
	Grid,
	Paper,
	ThemeProvider,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import {
	useActions,
	useCampaignsActions,
	useLogActions,
} from '../../hooks/useActions'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
	CampaignInterface,
	PublisherInterface,
	TrackedEvent,
} from '../../models/model.interface'
import { getAccountsAction } from '../../state/action-creators'
import {
	generateNodesFromArray,
	handleIconUrl,
	stringIsValidName,
} from '../../utils/helpers/helperFuncs'
import { fetchData } from '../../utils/helpers/navigationHelper'
import {
	capitalizeFirstLetter,
	checkInputStartsWithHttp,
	formatDate,
	shallowEqual,
} from '../../utils/helpers/tableHelper'
import { PageWrapper } from '../components/reusableComponents'
import { isUuid, TabPanel } from './CampaignComponents'
import { ButtonBar } from './CampaignStyled'
import { TabContainer } from '../components/TabComponent'
import { SubmitButton, UnborderedButton } from '../components/Buttons'
import {
	InputFieldText,
	RadioChipInput,
	StyledTextAreaField,
} from '../components/Inputs'
import { DropList } from '../components/SelectableInputs'
import { ChipInputList } from '../components/ChipInput'
import { DatePicker } from '../components/dateInputs/DateInputs'
import { SwitchComponent } from '../components/ToggleComponents'
import { GridContainer } from '../components/CustomContainers'
import CompanyInformationHeader from '../components/CompanyInformationHeader'
import { useIsAuthorized } from '../../hooks/useIsAuthorized'
import { permissionNames } from '../../utils/helpers/permissionsHelper'
import { DatePickerComponent } from '../components/datepickers/DatePickerComponent'
import { CampaignUnit } from './CampaignUnit'
import {
	dublicatedPidComponentsList,
	prepareNewState,
	preparePrevState,
} from './CampaignHelper'
import {
	formUniqueDictionary,
	formUniqueDictionaryFromHistory,
} from './CampaignHelper'
import { updateCampaignName } from './CampaignHelper'
import { getCampaignStates } from './CampaignHelper'
import { Unique } from '../../models/campaign.interface'

const CampaignAdd = (props: {
	PopUpVisible: boolean
	setPopUpVisible: any
	setRow: any
	row?: CampaignInterface
	setLoading: any
	errorMessage: null | string
	setErrorMessage: any
	isErrorPromptOpened: boolean
	setIsErrorPromptOpened: any
	setIsSuccessPromptOpened: any
	isSuccessPromptOpened: any
	setDuplicating: any
	duplicating: any
	uuidOfDuped: any
	isAdding?: any
	fetchData?: any
	campaignsGroup?: any
	setCampaignsGroup?: any
	handleDelete?: any
	isNewCampaign?: boolean
	setIsNewCampaign?: any
}): JSX.Element => {
	const { getEventsByAppsAction, getAccountsAction } = useActions()
	const {
		getCampaignHistoryByMainDtoAction,
		insertCampaignAction,
		duplicateCampaignAction,
		deleteCampaign,
		getCampaignAction,
		getCampaignPids,
		saveCampaignHistoryAction,
	} = useCampaignsActions()
	const { insertLog } = useLogActions()

	const {
		login,
		campaign,
		publisher,
		advertiser,
		app,
		campaign_history,
		settings,
		accounts,
		users,
	} = useTypedSelector((state) => state)
	const theme = useTheme()
	const navigate = useNavigate()
	const params = useParams()
	const [tab, setTab] = useState<number>(0)
	const [campaignName, setCampaignName] = useState<string | null>(null)
	const [previewUrl, setPreviewUrl] = useState<string | undefined | null>(null)
	const [advertiserName, setAdvertiserName] = useState<string>('')
	const [role, setRole] = useState<string>('M')
	const [cParam, setCParam] = useState<string>('All')
	const [status, setStatus] = useState<boolean>(false)
	const [appId, setAppId] = useState<string>('')
	const [trackedEvent, setTrackedEvent] = useState<TrackedEvent[]>()
	const [cap, setCap] = useState<string | undefined>('')
	const [isValid, setIsValid] = useState<boolean>(false)
	const [datePicked, setDatePicked] = useState<any>(undefined)
	const [available, setAvailable] = useState<boolean>(false)
	const [eventList, setEventList] = useState<string[]>([])
	const [cParamValid, setCParamValid] = useState<boolean>(true)
	const [advertiserTrafficRestrictions, setAdvertiserTrafficRestrictions] =
		useState<string[]>([])

	const [trackedEventChanged, setTrackedEventChanged] = useState<any[]>([])
	const [prevState, setPrevState] = useState<any>(undefined)
	const [uniqueTabState, setUniqueTabState] = useState<any[]>([])
	const [pointer, setPointer] = useState<number>(0)
	const [alignment, setAlignment] = useState<number>(0)
	const [popUpVisible, setPopUpVisible] = useState<boolean>(false)
	const [isRevenueShare, setIsRevenueShare] = useState<boolean>(false)
	const [createdBy, setCreatedBy] = useState<string>('')
	const [fixPrice, setFixPrice] = useState<string>('')
	const [iconUrl, setIconUrl] = useState<string>('')
	const [fixPriceSelected, setFixPriceSelected] = useState<boolean>(false)
	const [kpi, setKpi] = useState<string>('')
	const [softKpi, setSoftKpi] = useState<string>('')
	const [hardKpi, setHardKpi] = useState<string>('')
	const [traffic, setTraffic] = useState<any>([])
	const [usedExistingChannels, setUsedExistingChannels] = useState<any>([])
	const [eventsFlow, setEventsFlow] = useState<string>('')
	const [targetAudience, setTargetAudience] = useState<string>('')
	const [targetAudienceGender, setTargetAudienceGender] = useState<any>([])
	const [targetAudienceInterest, setTargetAudienceInterest] = useState<any>([])
	const [targetAudienceOthers, setTargetAudienceOthers] = useState<any>([])
	const [targetAudienceAgeFrom, setTargetAudienceAgeFrom] = useState<any>('')
	const [targetAudienceAgeTo, setTargetAudienceAgeTo] = useState<any>('')
	const [campaignGoals, setCampaignGoals] = useState<any>([])
	const [goal, setGoal] = useState<string>('')
	const [note, setNote] = useState<string>('')
	const [vtaAllowed, setVtaAllowed] = useState<boolean>(false)
	const [retargeting, setRetargeting] = useState<boolean>(false)
	const [probVta, setProbVta] = useState<boolean>(false)
	const [revenueNeeded, setRevenueNeeded] = useState<boolean>(false)
	const [organicNeeded, setOrganicNeeded] = useState<boolean>(false)
	const [advfileLink, setAdvfileLink] = useState<string>('')
	const [appName, setAppName] = useState<string>('')
	const [additionalTag, setAdditionalTag] = useState<string>('')
	const [creativesLink, setCreativesLink] = useState<string>('')
	const [deleteUnique, setDeleteUnique] = useState<any>([])
	const [budgetAmount, setBudgetAmount] = useState<string>('')
	const [timeFrame, setTimeFrame] = useState<any>('Monthly')
	const [endDate, setEndDate] = useState<Date>(new Date())
	const [kpiFocus, setKpiFocus] = useState<boolean>(true)
	const [trafficFocus, setTrafficFocus] = useState<boolean>(true)
	const [targetFocus, setTargetFocus] = useState<boolean>(true)
	const [goalFocus, setGoalFocus] = useState<boolean>(true)
	const [notesFocus, setNotesFocus] = useState<boolean>(true)
	const [rawdata, setRawdata] = useState<any>(false)
	const [pidList, setPidList] = useState<any>({})
	const [datePickerOpened, setDatePickerOpened] = useState(false)
	const [dateMoverOpened, setDateMoverOpened] = useState(false)
	const [uniques, setUniques] = useState<Record<string, Unique>>({})
	const [campaignFlow, setCampaignFlow] = useState('')
	const [creativesFocus, setCreativesFocus] = useState(true)
	const [pidConflictResolver, setPidConflictResolver] = useState<any>([])
	const [advFileFocus, setAdvFileFocus] = useState(true)
	const [eventNamesChosen, setEventNamesChosen] = useState<any[]>()
	const urlSplit = window.location.href.split('/')
	const [historyIsLoading, setHistoryIsLoading] = useState(true)
	const [conflicts, setConflicts] = useState<JSX.Element[]>([])
	const [privacyLevel, setPrivacyLevel] = useState('Open')
	const [campaignOwner, setCampaignOwner] = useState('')
	const [newUuid, setNewUuid] = useState('')
	const [fraudTools, setFraudTools] = useState<any>('')
	const [geoArray, setGeoArray] = useState<any>([])
	const [isEditingHistory, setIsEditingHistory] = useState(false)
	const [dateAdded, setDateAdded] = useState(undefined)
	const [historyUuid, setHistoryUuid] = useState(undefined)
	const [chosenHistoryEntry, setChosenHistoryEntry] = useState(0)
	const [networkAccount, setNetworkAccount] = useState([])
	const [gender, setGender] = useState('Both')
	const [incentive, setIncentive] = useState(false)
	const [campaignRules, setCampaignRules] = useState<any>({})
	const [appList, setAppList] = useState<string[]>([])
	const [dummyPids, setDummyPids] = useState<boolean>(false)
	const [appNameTouched, setAppNameTouched] = useState(false)
	const [campaignAdded, setCampaignAdded] = useState(false)
	const [subCampaignGeos, setSubCampaignGeos] = useState<{
		[key: string]: string[]
	}>({})

	const mspidList = useMemo(
		() =>
			publisher.publisher
				.map((p: PublisherInterface) => p.media_source_pid)
				.flat()
				.filter((e: string) =>
					isNaN(parseInt(e.split('_')[e.split('_').length - 1])),
				),
		[],
	)
	const companyType = login.company.type
	const listOfHooks: any = []
	const advertiserList: any = advertiser.advertiser
		.sort((adv1: any, adv2: any) =>
			adv1.advertiser_name.localeCompare(adv2.advertiser_name),
		)
		.filter((adv: any) => adv.company_id === login.company.id)
		.map((adv: any, key: number) => adv.advertiser_name)

	useEffect(() => {
		if (companyType === 'advertiser' && advertiserList.length > 0) {
			// console.log(advertiserList[0])
			setAdvertiserName(advertiserList[0])
		}
	}, [companyType, advertiserList])

	const uuidInUrl = props.duplicating
		? props.uuidOfDuped
		: urlSplit[urlSplit.length - 1]

	// const headers = {
	// 	Authorization: `Token ${login.user.token}`,
	// }

	//finding a campaign record for opened page
	const foundCampaign: any = campaign.campaign.find(
		(e: any) => e?.uuid === uuidInUrl && e?.role === 'M',
	)
	let filteredHistory =
		campaign_history.campaign_history?.filter(
			(e: any) => e?.uuid === uuidInUrl && e?.role === 'M',
		) || []
	filteredHistory.push(foundCampaign)

	filteredHistory.sort(
		(a: any, b: any) =>
			new Date(a?.date_added).getTime() - new Date(b?.date_added).getTime(),
	)

	// helper function to find a appropriate width for the history cells
	let widthArray: number[] = []
	if (filteredHistory[0]) {
		// console.log(filteredHistory)
		let offset = new Date(filteredHistory[0].date_added).getTime()
		let elapsedTimeArray = []
		let timeelapsedSum =
			new Date().getTime() - new Date(filteredHistory[0]?.date_added).getTime()

		for (let entity of filteredHistory) {
			elapsedTimeArray.push(new Date(entity?.date_added).getTime() - offset)

			offset = new Date(entity?.date_added).getTime()
		}

		elapsedTimeArray.push()
		let percent = Math.floor(timeelapsedSum / 100)

		let min = 100
		let max = 0
		let sum = 0
		for (let time of elapsedTimeArray) {
			let calculatedWidth = Math.floor(time / percent)

			widthArray.push(calculatedWidth === 0 ? 1 : calculatedWidth)
			if (max < calculatedWidth) {
				max = calculatedWidth
			}
			if (min > calculatedWidth) {
				min = calculatedWidth
			}
			sum += calculatedWidth
		}

		// const median = Math.floor(min + max / 2)
		const median = Math.floor(sum / elapsedTimeArray.length)

		for (let [i, width] of Object.entries(widthArray)) {
			widthArray[parseInt(i)] = Math.floor(median + 0.5 * (width - median))
		}
	}

	// creating all necessary components for history selector
	const historyArray = filteredHistory
		.map((e: any, i: number) => {
			if (e) {
				let res = [
					<Grid
						item
						style={{
							marginRight: '10px',
							width: '10px',
							textAlign: 'center',
							borderRadius: '10px',
							padding: 0,
							zIndex: 1,
						}}
					>
						<Tooltip title={formatDate(new Date(e.date_added))} placement='top'>
							<SubmitButton
								onClick={() => {
									setDateAdded(e.date_added)
									setDateMoverOpened(true)
								}}
								style={{
									background: '#808080',
									width: '10px',
									height: '50px',
									margin: 0,
									padding: 0,
									minWidth: 0,
									borderRadius: '30px',
									clipPath:
										'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
									position: 'relative',
									border: 'none',
								}}
							></SubmitButton>
						</Tooltip>
					</Grid>,
					<Grid
						item
						xs={widthArray[i + 1]}
						style={{
							// background: 'grey',
							// alignContent: 'center',
							marginRight: '10px',
							borderRadius: '30px',
							textAlign: 'center',
							// width: widthArray[i] + 'px',
						}}
					>
						<SubmitButton
							onClick={() => {
								fillCampaignStatesFromHistory(e)
								setChosenHistoryEntry(filteredHistory.length - 1 - i)
							}}
							style={{
								borderRadius: '30px',
								width: '100%',
								// right: '10%',
								height: '25px',
								borderBlockColor: 'transparent',

								background:
									chosenHistoryEntry === filteredHistory.length - 1 - i
										? theme.colors.base.green200
										: i % 2 === 0
										? '#eeeeee'
										: '#dddddd',
								margin: 0,
								position: 'relative',
								minWidth: 0,
								zIndex: 1,
								border: 'none',
							}}
						></SubmitButton>
					</Grid>,
				]
				if (i === filteredHistory.length - 1) {
					res = [
						<Grid
							item
							xs={widthArray[i + 1]}
							style={{
								// background: 'black',
								marginRight: '10px',
								width: '10px',
								textAlign: 'center',
								borderRadius: '30px',
								padding: 0,
							}}
						>
							<Tooltip
								title={formatDate(new Date(e.date_added))}
								placement='top'
							>
								<SubmitButton
									onClick={() => {
										setDateAdded(e.date_added)
										setDateMoverOpened(true)
									}}
									style={{
										background: '#808080',
										width: '10px',
										height: '50px',
										margin: 0,
										padding: 0,
										minWidth: 0,
										borderRadius: '30px',
										clipPath:
											'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)',
										position: 'relative',
										marginRight: '10px',
										border: 'none',
									}}
								></SubmitButton>
							</Tooltip>
						</Grid>,
						<Grid
							item
							style={{
								// background: 'red',
								//

								borderRadius: '30px',
								textAlign: 'center',
							}}
						>
							<SubmitButton
								onClick={() => {
									fillCampaignStates()
									setChosenHistoryEntry(filteredHistory.length - 1 - i)
								}}
								style={{
									borderRadius: '30px',
									width: '100%',
									// right: '10%',
									height: '25px',
									borderBlockColor: 'transparent',
									boxShadow: 'none',
									background:
										chosenHistoryEntry === filteredHistory.length - 1 - i
											? theme.colors.base.green100
											: 'grey',
									margin: 0,
									position: 'relative',
									minWidth: 0,
									clipPath:
										'polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)',
									border: 'none',
								}}
							></SubmitButton>
						</Grid>,
					]
				}
				return res
			} else return undefined
		})
		.flat()

	// forms the log object for storing and sends it to the server
	const handleLog = async (payload: any) => {
		// console.log('save logs with old', prevState)
		const shallow = shallowEqual(prevState, prepareNewState(payload), true)
		if (shallow.length > 0) {
			const log = {
				email: login.user.email,
				object: campaignName! + ' in Campaigns',
				change: JSON.stringify(shallow),
			}
			await insertLog(log)
		}
	}

	const updateSubCampaignGeo = (role: string, geos: string[]) => {
		setSubCampaignGeos((prevState) => ({
			...prevState,
			[role]: geos,
		}))
	}

	// use found data to fill the campaign states

	const fillCampaignStates = () => {
		if (foundCampaign !== undefined) {
			const dict = formUniqueDictionary(
				campaign.campaign,
				uuidInUrl,
				props.duplicating,
			)
			const newStates = getCampaignStates(foundCampaign, dict)
			// Update iconUrl only if it exists
			if (newStates.iconUrl) {
				setIconUrl(newStates.iconUrl)
			}

			// Update other state variables
			setCampaignOwner(newStates.campaignOwner)
			setPreviewUrl(newStates.previewUrl)
			setCap(newStates.cap)
			setStatus(newStates.status)
			setCampaignName(newStates.campaignName)
			setCParam(newStates.cParam)
			setIsRevenueShare(newStates.isRevenueShare)
			setGoal(newStates.goal)
			setTargetAudience(newStates.targetAudience)
			setKpi(newStates.kpi)
			setNote(newStates.note)
			setVtaAllowed(newStates.vtaAllowed)
			setRetargeting(newStates.retargeting)
			setTraffic(newStates.traffic)
			setProbVta(newStates.probVta)
			setRevenueNeeded(newStates.revenueNeeded)
			setOrganicNeeded(newStates.organicNeeded)
			setAdvfileLink(newStates.advfileLink)
			setCreativesLink(newStates.creativesLink)
			setTimeFrame(newStates.timeFrame)
			setEndDate(newStates.endDate)
			setRawdata(newStates.rawdata)
			setBudgetAmount(newStates.budgetAmount)
			setFixPrice(newStates.fixPrice)
			setCreatedBy(newStates.createdBy)
			setFixPriceSelected(newStates.fixPriceSelected)
			setPidConflictResolver(newStates.pidConflictResolver)
			setPrivacyLevel(newStates.privacyLevel)
			setAdvertiserName(newStates.advertiserName)
			setAppId(newStates.appId)
			// setTrackedEvent(newStates.orderedTrackedEvent)
			setIncentive(newStates.incentive)
			setAppName(newStates.appName ?? '')
			setCampaignFlow(newStates.campaignFlow ?? '')
			setAdditionalTag(newStates.additionalTag ?? '')
			setNetworkAccount(newStates.networkAccount)
			setFraudTools(newStates.fraudTools)
			setUniques(newStates.uniques)
			setEventNamesChosen(newStates.eventNamesChosen)
			setHistoryUuid(newStates.historyUuid)
			setDateAdded(newStates.dateAdded)
			setIsEditingHistory(newStates.isEditingHistory ?? false)
			setGender(newStates.gender)
			setTargetAudienceAgeFrom(newStates.targetAudienceAgeFrom)
			setTargetAudienceAgeTo(newStates.targetAudienceAgeTo)
			setUsedExistingChannels(newStates.usedExistingChannels)
			setNetworkAccount(newStates.networkAccountList)
			setTargetAudienceInterest(newStates.targetAudienceInterest)
			setCampaignGoals(newStates.campaignGoals)
			setTargetAudienceOthers(newStates.targetAudienceOthers)
			setSoftKpi(newStates.softKpi)
			setHardKpi(newStates.hardKpi)
			setEventsFlow(newStates.eventsFlow)
			setCampaignRules(newStates.campaignRules)
			setDummyPids(newStates.dummyPids)
		}
	}

	// if the campaign is of advertiser that is not in apps flyer
	// we remove the agency account from the campaign (it is not needed)
	const checkAdvertiserPlatform = () => {
		const curAdvertiser = advertiser.advertiser.find(
			(a: any) => a.advertiser_name === campaignObject.advertiser_name,
		)
		if (curAdvertiser?.platform?.includes('Appsflyer')) {
			return
		} else {
			let emptyArr: string[] = []
			campaignObject.network_account = emptyArr
		}
	}

	// use found historical data to fill the campaign states
	const fillCampaignStatesFromHistory = (historyRecord: any) => {
		if (historyRecord !== undefined) {
			// const orderedTrackedEvent =
			//  foundCampaign !== undefined && foundCampaign.tracked_event !== undefined
			//      ? foundCampaign.tracked_event.sort(
			//              (a: any, b: any) => a.order! - b.order!,
			//        )
			//      : []
			const campaignNameSplit = historyRecord.campaign_name.split('-')
			if (foundCampaign.icon) {
				setIconUrl(foundCampaign.icon)
			}
			setCampaignOwner(historyRecord.email)
			setPreviewUrl(historyRecord.preview_url)
			setCap(historyRecord.cap)
			setStatus(historyRecord.status)
			setCampaignName(historyRecord.campaign_name)
			setCParam(historyRecord.c_parameter)
			// setTrackedEvent(orderedTrackedEvent)
			setIsRevenueShare(historyRecord.is_revenue_share)
			setGoal(historyRecord.goal)
			setTargetAudience(historyRecord.target_audience)
			setKpi(historyRecord.kpi)
			setNote(historyRecord.note)
			setVtaAllowed(historyRecord.vta_allowed)
			setRetargeting(historyRecord.retargeting)
			setTraffic(historyRecord.traffic || [])
			setProbVta(historyRecord.prob_vta)
			setRevenueNeeded(historyRecord.revenue_needed)
			setOrganicNeeded(historyRecord.organic_needed)
			setAdvfileLink(historyRecord.adv_file_link)
			setCreativesLink(historyRecord.creatives)
			setTimeFrame(historyRecord.timeframe)
			setEndDate(historyRecord.end_date)
			setRawdata(historyRecord.rawdata)
			setBudgetAmount(historyRecord.budget_amount)
			setFixPrice(historyRecord.fix_price)
			setCreatedBy(historyRecord.created_by)
			setFixPriceSelected(historyRecord.fix_price)
			setPidConflictResolver(historyRecord.pidConflicts)
			setPrivacyLevel(historyRecord.privacy_level)
			setAdvertiserName(historyRecord.advertiser_name)
			setAppId(historyRecord.app_id)
			const dict = formUniqueDictionaryFromHistory(
				campaign_history.campaign_history,
				historyRecord.date_added,
				props.duplicating,
			)

			setUniques(dict)
			if (dict && dict['M']) {
				setEventNamesChosen(
					Object.values(dict['M']).map((event: any) => event?.name),
				)
			}
			setDateAdded(historyRecord.date_added)
			setIsEditingHistory(true)
			setHistoryUuid(historyRecord.history_uuid)
			setGender(historyRecord.gender)
			setTargetAudienceAgeFrom(historyRecord.age_from)
			setTargetAudienceAgeTo(historyRecord.age_to)
			setUsedExistingChannels(historyRecord.channels || [])
			setNetworkAccount(historyRecord.network_account || [])
			setTargetAudienceInterest(historyRecord.interests || [])
			setCampaignGoals(historyRecord.campaign_goals || [])
			setTargetAudienceOthers(historyRecord.other_caricaturists || [])
			setIncentive(historyRecord.incentive)
			setSoftKpi(historyRecord.soft_kpi)
			setHardKpi(historyRecord.hard_kpi)
			setEventsFlow(historyRecord.campaign_flow)
			setCampaignRules(
				historyRecord.campaign_rules
					? JSON.parse(historyRecord.campaign_rules)
					: {},
			)
			setDummyPids(historyRecord.dummy_pids)
			setFraudTools(historyRecord.fraud_tool)
			if (campaignNameSplit.length > 0) {
				setAppName(campaignNameSplit[0])

				let tag = ['CPI', 'CPA', 'CPC', 'CPM'].includes(
					campaignNameSplit[campaignNameSplit.length - 1]?.trim(),
				)
					? ''
					: campaignNameSplit[campaignNameSplit.length - 1]?.trim()
				// console.log(
				//  ['CPI', 'CPA', 'CPC', 'CPM'].includes(
				//      campaignNameSplit[campaignNameSplit.length - 1]?.trim(),
				//  )
				//      ? campaignNameSplit[campaignNameSplit.length - 1]?.trim()
				//      : campaignNameSplit[campaignNameSplit.length - 2]?.trim(),
				// )
				setCampaignFlow(
					['CPI', 'CPA', 'CPC', 'CPM'].includes(
						campaignNameSplit[campaignNameSplit.length - 1]?.trim(),
					)
						? campaignNameSplit[campaignNameSplit.length - 1]?.trim()
						: campaignNameSplit[campaignNameSplit.length - 2]?.trim(),
				)
				setAdditionalTag(tag)
			}
		}
	}
	const externalFunction = (gotRole: string) => {}

	// forms an object to send to the backend from all hooks on the page
	const campaignObject: any = {
		uuid:
			uuidInUrl !== 'campaigns'
				? props.duplicating
					? undefined
					: uuidInUrl
				: undefined,
		app_id: appId,
		campaign_name: campaignName,
		email:
			advertiser.advertiser.find(
				(e: any) => e.advertiser_name === advertiserName,
			)?.email || '',
		c_parameter: cParam,
		advertiser_name: advertiserName,
		status: status,
		role: role,
		is_revenue_share: isRevenueShare,
		cap: cap,
		preview_url: previewUrl,
		goal: goal,
		target_audience: targetAudience,
		kpi: kpi,
		note: note,
		vta_allowed: vtaAllowed,
		retargeting: retargeting,
		traffic: traffic,
		prob_vta: probVta,
		revenue_needed: revenueNeeded,
		organic_needed: organicNeeded,
		adv_file_link: advfileLink,
		creatives: creativesLink,
		timeframe: timeFrame,
		end_date: endDate,
		budget_amount: budgetAmount,
		rawdata: rawdata,
		fix_price: fixPrice,
		created_by: createdBy,
		pidConflicts: conflicts,
		privacy_level: privacyLevel,
		priority: 'media_source_pid',
		uniques: uniques,
		campaign_goals: campaignGoals,
		gender: gender,
		age_from: targetAudienceAgeFrom,
		age_to: targetAudienceAgeTo,
		interests: targetAudienceInterest,
		other_caricaturists: targetAudienceOthers,
		channels: usedExistingChannels,
		incentive: incentive,
		network_account: networkAccount,
		hard_kpi: hardKpi,
		soft_kpi: softKpi,
		campaign_flow: eventsFlow,
		fraud_tool: fraudTools,
		campaign_rules: campaignRules,
		dummy_pids: dummyPids,
	}

	// clones object for comparison and forming a log record
	let deepCloneCampaignObject = _.cloneDeep(campaignObject)

	const [mandatoryFields, setMandatoryFields] = useState({
		appId: true,
		advertiser: true,
		campaignFlow: true,
		uniqueCampaigns: true,
	})

	const [fieldValidations, setFieldValidations] = useState({
		appId: false,
		advertiser: false,
		campaignFlow: false,
		uniqueCampaigns: false,
	})

	if (!foundCampaign && !prevState) {
		const prev = preparePrevState({ ...deepCloneCampaignObject })
		setPrevState({ ...prev })
	}

	if (!prevState && campaignObject && campaignObject.campaign_name) {
		const prev = preparePrevState({ ...deepCloneCampaignObject })
		setPrevState({ ...prev })
	}
	const handlePidResolver = (key: string, value: string) => {
		pidConflictResolver[key] = value
		const new_enry = {}
		Object.assign(new_enry, pidConflictResolver)
		setPidConflictResolver(new_enry)
	}
	useEffect(() => {
		setConflicts(
			dublicatedPidComponentsList(
				pidList,
				handlePidResolver,
				pidConflictResolver,
			),
		)
	}, [pidConflictResolver, pidList])

	useEffect(() => {
		if (advertiserName) {
			// Find the current advertiser object
			const currentAdvertiser = advertiser.advertiser.find(
				(adv: any) => adv.advertiser_name === advertiserName,
			)

			if (currentAdvertiser) {
				try {
					// Parse the traffic_restrictions JSON string
					const parsedRestrictions = JSON.parse(
						currentAdvertiser.traffic_restrictions,
					)

					// Assuming parsedRestrictions is an array of objects with 'value' and 'label'
					const restrictionValues = parsedRestrictions.map(
						(item: any) => item.value,
					)

					setAdvertiserTrafficRestrictions(restrictionValues)
				} catch (error) {
					console.error('Error parsing traffic_restrictions:', error)
					setAdvertiserTrafficRestrictions([])
				}
			} else {
				setAdvertiserTrafficRestrictions([])
			}
		} else {
			setAdvertiserTrafficRestrictions([])
		}
	}, [advertiserName, advertiser.advertiser])

	const filteredTrafficRestrictions = useMemo(() => {
		if (!settings.settings.trafficRestrictions) return []

		const uniqueRestrictionsSet = new Set(settings.settings.trafficRestrictions)
		const uniqueRestrictionsArray = Array.from(
			uniqueRestrictionsSet,
		) as string[]

		const filtered = uniqueRestrictionsArray.filter(
			(restriction: string) =>
				!advertiserTrafficRestrictions.includes(restriction),
		)

		const finalFiltered = Array.from(new Set(filtered))

		return finalFiltered
	}, [settings.settings.trafficRestrictions, advertiserTrafficRestrictions])

	useEffect(() => {
		// Combine all geos from sub-campaigns
		const allGeos = Object.values(subCampaignGeos).flat()
		const uniqueGeos = [...new Set(allGeos.map((e) => e.toUpperCase()))]

		// Update geoArray state
		if (uniqueGeos.includes('')) {
			setGeoArray(['GLOBAL'])
		} else {
			setGeoArray(uniqueGeos.filter((e) => e !== '' && e !== 'GLOBAL').sort())
		}

		// Update campaign name using the helper function
		const name = updateCampaignName(
			appName,
			uniqueGeos,
			campaignFlow,
			additionalTag,
		)
		setCampaignName(name)
	}, [subCampaignGeos, appName, campaignFlow, additionalTag])

	// defaults all the hooks to their initial state
	const nullifyCompaignStates = () => {
		setCampaignName('')

		setAdvertiserName('')
		setCParam('All')
		setStatus(false)
		setAppId('')
		setTrackedEvent([])
		setIsValid(false)
		setIsRevenueShare(false)
		setUniqueTabState([])
		setGoal('')
		setTargetAudience('')
		setKpi('')
		setSoftKpi('')
		setHardKpi('')
		setNote('')
		setVtaAllowed(false)
		setRetargeting(false)
		setTraffic([])
		setUsedExistingChannels([])
		setEventsFlow('')
		setProbVta(false)
		setRevenueNeeded(false)
		setOrganicNeeded(false)
		setAdvfileLink('')
		setCreativesLink('')
		setBudgetAmount('')
		setEndDate(new Date())
		setTimeFrame('')
		setPreviewUrl('')
		setCap('')
		setDeleteUnique([])
		setFixPrice('')
		setCreatedBy('')
		setFixPriceSelected(false)
		setPrivacyLevel('Open')
		setUniques({})
		setDatePicked(undefined)
		setGender('')
		setTargetAudienceAgeFrom('')
		setTargetAudienceAgeTo('')
		setNetworkAccount([])
		setTargetAudienceInterest('')
		setCampaignGoals('')
		setTargetAudienceOthers('')
		setIncentive(false)
		setAdditionalTag('')
		setFraudTools('')
		setCampaignRules({})
		setDummyPids(false)
	}

	// form and send everything to backend
	const trySubmit = async () => {
		props.setLoading(true)
		let payloadArray = []
		if (Object.keys(campaignObject.uniques).length > 0) {
			payloadArray = Object.keys(campaignObject.uniques).map((e: any) => {
				delete campaignObject.uuid
				if (e === 'M' && isUuid(uuidInUrl)) {
					return {
						...campaignObject,
						...campaignObject.uniques[e],
						role: e,
						main_uuid: uuidInUrl,
						uuid: uuidInUrl,
						company_id: login.company.id,
						date_toSaveFrom: datePicked,
						interests: targetAudienceInterest
							? targetAudienceInterest.map((e: any) => e?.label ?? '')
							: [],
						other_caricaturists: targetAudienceOthers
							? targetAudienceOthers.map((e: any) => e?.label ?? '')
							: [],
						campaign_goals: campaignGoals
							? campaignGoals?.map((e: any) => e?.label ?? '')
							: [],
					}
				} else if (isUuid(uuidInUrl)) {
					return {
						...campaignObject,
						...campaignObject.uniques[e],
						role: e,
						main_uuid: uuidInUrl,
						date_toSaveFrom: datePicked,
						company_id: login.company.id,
						interests: targetAudienceInterest
							? targetAudienceInterest.map((e: any) => e?.label ?? '')
							: [],
						other_caricaturists: targetAudienceOthers
							? targetAudienceOthers.map((e: any) => e?.label ?? '')
							: [],
						campaign_goals: campaignGoals
							? campaignGoals?.map((e: any) => e?.label ?? '')
							: [],
						tracked_event: campaignObject.uniques[e].tracked_event.map(
							(elem: any, i: number) => {
								return {
									...elem,
									eventName:
										campaignObject.uniques['M'].tracked_event[i]?.eventName,
								}
							},
						),
					}
				}
				return {
					...campaignObject,
					...campaignObject.uniques[e],
					role: e,
					date_toSaveFrom: datePicked,
					company_id: login.company.id,
					interests: targetAudienceInterest
						? targetAudienceInterest.map((e: any) => e?.label ?? '')
						: [],
					other_caricaturists: targetAudienceOthers
						? targetAudienceOthers.map((e: any) => e?.label ?? '')
						: [],
					campaign_goals: campaignGoals
						? campaignGoals?.map((e: any) => e?.label ?? '')
						: [],
					tracked_event: campaignObject.uniques[e].tracked_event.map(
						(elem: any, i: number) => {
							return {
								...elem,
								eventName:
									campaignObject.uniques['M'].tracked_event[i]?.eventName,
							}
						},
					),
				}
			})
		}
		// if duplicating a campaign
		if (payloadArray.length === 0) {
			payloadArray.push(campaignObject)
		}
		let payload: any = ''
		if (props.duplicating) {
			try {
				for (let campaign of payloadArray) {
					delete campaign.uuid
					delete campaign.main_uuid
				}

				payload = await duplicateCampaignAction(
					payloadArray,
					{},
					props.setErrorMessage,
					setNewUuid,
				)
			} catch (err) {
				console.error(err)
			}
			// if creating ot editing a campaign
		} else {
			try {
				campaignObject.campaign_rules = JSON.stringify(
					campaignObject.campaign_rules,
				)
				checkAdvertiserPlatform()
				console.log('campaignObject 2 ', campaignObject)
				payload = await insertCampaignAction(
					payloadArray,
					{},
					props.setErrorMessage,
					setNewUuid,
					campaignObject,
				)
			} catch (err) {
				console.error(err)
			}
		}

		// if deleing a campaign
		if (deleteUnique && !props.duplicating) {
			deleteUnique.forEach(async (unique: any) => {
				if (unique !== undefined) {
					const obj = {
						uuid: unique,
						user_login: login.user.email!,
						// TODO: fix ruleset
						rule_set: {},
						// rule_set: ruleSet,
					}
					await deleteCampaign(obj, props.setErrorMessage)
					setDeleteUnique([])
				}
			})
		}
		if (typeof payload !== 'string') {
			// await handleLog(campaignObject)
			props.setIsSuccessPromptOpened(true)
			await fetchData(getCampaignAction, campaign.campaign)
			getCampaignHistoryByMainDtoAction(
				{ main_uuid: uuidInUrl },
				setHistoryIsLoading,
			)
		}
		props.setLoading(false)
	}
	// form and send everything to backend (historu)
	const trySaveHistoricalEnrty = async () => {
		props.setLoading(true)
		let payloadArray = []
		if (Object.keys(campaignObject.uniques).length > 0) {
			payloadArray = Object.keys(campaignObject.uniques).map((e: any) => {
				delete campaignObject.uuid
				const historyEntry = filteredHistory.find(
					(el: any) => el.date_added === dateAdded,
				)

				if (e === 'M' && isUuid(uuidInUrl)) {
					return {
						...campaignObject,
						...campaignObject.uniques[e],
						role: e,
						uuid: uuidInUrl,

						main_uuid: uuidInUrl,
						date_added: historyEntry.date_added,
						date_ended: historyEntry.date_ended,
						history_uuid: historyEntry.history_uuid,
						date_toSaveFrom: datePicked,
						interests: targetAudienceInterest
							? targetAudienceInterest.map((e: any) => e?.label ?? '')
							: [],
						other_caricaturists: targetAudienceOthers
							? targetAudienceOthers.map((e: any) => e?.label ?? '')
							: [],
						campaign_goals: campaignGoals
							? campaignGoals?.map((e: any) => e?.label ?? '')
							: [],
					}
				}
				return {
					...campaignObject,
					...campaignObject.uniques[e],
					role: e,
					main_uuid: uuidInUrl,

					date_added: historyEntry.date_added,
					date_ended: historyEntry.date_ended,
					history_uuid: historyEntry.history_uuid,
					date_toSaveFrom: datePicked,
					interests: targetAudienceInterest
						? targetAudienceInterest.map((e: any) => e?.label ?? '')
						: [],
					other_caricaturists: targetAudienceOthers
						? targetAudienceOthers.map((e: any) => e?.label ?? '')
						: [],
					campaign_goals: campaignGoals
						? campaignGoals?.map((e: any) => e?.label ?? '')
						: [],
					tracked_event: campaignObject.uniques[e].tracked_event.map(
						(elem: any, i: number) => {
							return {
								...elem,
								eventName:
									campaignObject.uniques['M'].tracked_event[i]?.eventName,
							}
						},
					),
				}
			})
		}

		if (payloadArray.length === 0) {
			payloadArray.push(campaignObject)
		}
		let payload: any = ''

		try {
			payload = await saveCampaignHistoryAction(
				payloadArray,
				props.setErrorMessage,
				setNewUuid,
				campaignObject,
			)
		} catch (err) {
			console.error(err)
		}

		if (typeof payload !== 'string') {
			// console.log('before saving', prevState.uniques, campaignObject.uniques)
			// await handleLog(campaignObject)
			props.setIsSuccessPromptOpened(true)
			await fetchData(getCampaignAction, campaign.campaign)
			getCampaignHistoryByMainDtoAction(
				{ main_uuid: uuidInUrl },
				setHistoryIsLoading,
			)
		}
		props.setLoading(false)
	}
	useEffect(() => {
		fetchData(getAccountsAction, accounts.accounts)
	}, [])

	const handleGetEventList = () => {
		if (appId || campaignObject.app_id) {
			getEventsByAppsAction(appId ?? campaignObject.app_id, setEventList)
		}
	}

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue)
	}
	useEffect(() => {
		const advert = advertiser.advertiser.filter(
			(el: any) => el.advertiser_name === advertiserName,
		)
		if (advert[0]) {
			// setCurrentAdvertiser(advert[0])
			setCampaignOwner(login.user.email)
			if (
				!advfileLink ||
				!foundCampaign ||
				(foundCampaign && !foundCampaign.adv_file_link)
			) {
				setAdvfileLink(advert[0].link_to_io)
			}
			if (
				!creativesLink ||
				!foundCampaign ||
				(foundCampaign && !foundCampaign.creatives)
			) {
				setCreativesLink(advert[0].link_to_creative)
			}
		}
	}, [advertiserName])

	useEffect(() => {
		if (newUuid !== '') {
			const main = (newUuid as any).find((el: any) => el.role === 'M')
			props.setDuplicating(false)

			navigate('/campaigns/' + main.uuid)
			for (const key in campaignObject.uniques) {
				for (const element of newUuid as any) {
					if (element.role === key) {
						campaignObject.uniques[key].uuid = element.uuid
					}
				}
			}
		}
	}, [newUuid])

	useEffect(() => {
		if (!!appId && !!advertiserName) {
			const app = advertiser.advertiser.filter(
				(el: any) => el.advertiser_name === advertiserName,
			)
			setAvailable(true)
			handleGetEventList()
			// setPreviewUrl(app[0].link_to_adv_file)
		} else {
			setAvailable(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [advertiserName, appId])
	useEffect(() => {
		if (isUuid(uuidInUrl)) {
			fillCampaignStates()

			handleGetEventList()
			getCampaignHistoryByMainDtoAction(
				{ main_uuid: uuidInUrl },
				setHistoryIsLoading,
			)
		} else {
			nullifyCompaignStates()
		}
		if (props.duplicating) {
			setCampaignName((campaignName) => campaignName + ' (Duplicated)')
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const iconsFound = app.appIcons.filter(
			(a: any) => a.app_id === appId && a.identifier === networkAccount,
		)

		if (iconsFound && iconsFound.length > 0) {
			foundCampaign['icon'] = iconsFound.icon_url
			setIconUrl(iconsFound.icon_url)
		}
	}, [app, appId, networkAccount])

	useEffect(() => {
		if (campaignObject.uniques && appId && appName) {
			let GeoArray = []
			// console.log(geoArray)

			const campaignNamgeGenerated =
				appName +
				' - ' +
				(geoArray.flat().length > 6
					? 'MultiGeo'
					: geoArray.toString().toUpperCase()) +
				' - ' +
				(appId?.startsWith('id') ? 'iOS' : 'Android') +
				' - ' +
				campaignFlow +
				// (retargeting ? ' - RT' : '') +
				(additionalTag !== '' ? ' - ' + additionalTag : '')
			// console.log(campaignNamgeGenerated)

			setCampaignName(campaignNamgeGenerated)
			campaignObject.campaign_name = campaignNamgeGenerated
		}
	}, [appName, geoArray, campaignFlow, appId, additionalTag, retargeting])

	useEffect(() => {
		fillUniqueStates()
	}, [eventNamesChosen])

	const closeDetailedView = () => {
		props.setDuplicating(false)

		navigate('/campaigns')
		props.setPopUpVisible(false)
		nullifyCompaignStates()
	}
	const duplicateUniqueTab = (tab: any) => {
		const newRole =
			Object.keys(campaignObject.uniques).length > 0
				? 'Q' + Object.keys(campaignObject.uniques).length
				: 'M'
		// const newEntry = {
		//  media_source_pid: campaignObject.uniques[tab].media_source_pid,
		//  country: campaignObject.uniques[tab].country,
		//  tracked_event: campaignObject.uniques[tab].tracked_event,
		// }
		// console.log('before', JSON.stringify(campaignObject.uniques))
		const newEntry = _.cloneDeep(uniques[tab])
		delete newEntry.uuid
		newEntry.country_excluded = false

		uniques[newRole] = newEntry
		const newValue = Object.assign({}, uniques)
		setUniques(newValue)
	}

	const generateNodesFromArrayParameter = (parameter: any[]) => {
		try {
			return generateNodesFromArray(parameter, {
				value: '',
				label: '',
			})
		} catch (error) {
			console.error(error)
			return generateNodesFromArray([], { value: '', label: '' })
		}
	}

	const [update, setUpdate] = useState(false)
	const [uniquesComponents, setUniquesComponents] = useState<any[]>([])

	const sameCompanyId = props.isNewCampaign
		? true
		: foundCampaign?.company_id === login?.user?.company_id
	const permissions = {
		edit: useIsAuthorized(permissionNames.ADD_EDIT_CAMPAIGN) && sameCompanyId,
		preview: useIsAuthorized(permissionNames.PREVIEW_CAMPAIGNS),
		editOtherParams:
			useIsAuthorized(permissionNames.EDIT_OTHER_PARAMETERS) && sameCompanyId,
		editMainParams:
			useIsAuthorized(permissionNames.EDIT_MAIN_PARAMETERS) && sameCompanyId,
		addPayouts:
			useIsAuthorized(permissionNames.ADD_UNIQUE_PAYOUTS) && sameCompanyId,
		openHistory:
			useIsAuthorized(permissionNames.OPENING_HISTORY) && sameCompanyId,
	}
	// console.log(
	// 	tab !== 3,
	// 	permissions.openHistory,
	// 	historyArray,
	// 	historyIsLoading,
	// )

	useEffect(() => {
		setUniquesComponents(
			Object.entries(uniques)
				.sort(([roleA, uniqueA], [roleB, uniqueB]) => {
					if (roleA === 'M') return -1
					if (roleB === 'M') return 1
					const numA = parseInt(roleA.slice(1))
					const numB = parseInt(roleB.slice(1))
					return numA - numB
				})
				.map(([role, unique]: [string, Unique], index: number) => (
					<CampaignUnit
						key={unique.uuid}
						role={role}
						unique={unique}
						trackedEventChanged={trackedEventChanged}
						pointer={index}
						setTrackedEventChanged={setTrackedEventChanged}
						eventList={eventList}
						uniqueTabState={uniqueTabState}
						setUniqueTabState={setUniqueTabState}
						setDeleteUnique={setDeleteUnique}
						mspidList={mspidList}
						setTrackedEvent={setTrackedEvent}
						trackedEvent={trackedEvent}
						setUniques={setUniques}
						campaignObject={campaignObject}
						eventNamesChosen={eventNamesChosen}
						setEventNamesChosen={setEventNamesChosen}
						duplicateUniqueTab={duplicateUniqueTab}
						uniques={uniques}
						externalFunction={externalFunction}
						listOfHooks={listOfHooks}
						setGeoArray={setGeoArray}
						updateSubCampaignGeo={updateSubCampaignGeo}
						companyId={login.company.id}
						setErrorMessage={props.setErrorMessage}
					/>
				)),
		)
	}, [uniques, eventList, eventNamesChosen])

	useEffect(() => {
		const apps = app.app
			.filter((el: any) => el.advertiser_name === advertiserName)
			.map((el: any) => el.app_id)
		setAppList(apps)
	}, [advertiserName])

	const makeUniqueTab = () => {
		const lastKey = Object.keys(campaignObject.uniques)[
			Object.keys(campaignObject.uniques).length - 1
		]
		let newKey = 'Q1'
		if (!lastKey) {
			newKey = 'M'
		} else if (lastKey.startsWith('Q')) {
			const newDigit = Number(lastKey.slice(1)) + 1
			newKey = 'Q' + newDigit
		}
		const newRole = newKey
		const newEntry = {
			media_source_pid: ['all'],
			country: [''],
			tracked_event: [
				{
					order: 1,
					eventName: 'installs',
					defaultCost: 0,
					defaultRevenue: 0,
				},
			],
		}
		campaignObject.uniques[newRole] = newEntry
		const newValue = Object.assign({}, campaignObject.uniques)
		setUniques(newValue)
		setCampaignAdded(true)
	}

	const fillUniqueStates = () => {
		const newValue = Object.assign({}, campaignObject.uniques)
	}

	function openADatePicker() {
		setDatePickerOpened(true)
	}

	const updateCampaignRules = (reportType: string, rules: any) => {
		let campRules = { rules, reportType }
		setCampaignRules(campRules)
		const campRuleStringified = JSON.stringify(campRules)
		campaignObject.campaign_rules = campRuleStringified
	}
	const openPreview = () => {
		if (params.id) {
			navigate('/campaigns/' + foundCampaign.uuid + '/preview')
		}
	}

	const tabOptions = [
		{ label: 'General Info', tabIndex: 0 },
		{ label: 'Guidelines', tabIndex: 1 },
		{ label: 'Targeting & Affiliates', tabIndex: 2 },
	]

	return (
		<ThemeProvider theme={theme}>
			<div
				style={{
					marginTop: 40,
					paddingInline: '5rem',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<UnborderedButton
					onClick={closeDetailedView}
					icon={
						<ArrowBackIosIcon style={{ height: '16px', marginLeft: '1rem' }} />
					}
					label={'Back'}
					style={{
						marginRight: '0.5rem',
					}}
				/>

				{/* <Box
                // style={{
                //  width: '30%',
                // }}
                > */}
				<TabContainer
					tab={tab}
					handleChangeTab={handleChangeTab}
					tabProps={a11yProps}
					options={tabOptions}
				/>
				{/* </Box> */}
				{foundCampaign && permissions.preview && params.id ? (
					<UnborderedButton
						style={{ marginRight: 100 }}
						onClick={openPreview}
						icon={<VisibilityIcon />}
						label={'Preview'}
					/>
				) : (
					<div style={{ width: '80px' }}></div>
				)}
			</div>
			<PageWrapper
				style={{
					maxHeight: '200%',
					overflow: 'visible',
					height: 'fit-content',
				}}
			>
				<TabPanel value={tab} index={0}>
					<CompanyInformationHeader
						name={campaignName}
						iconUrl={handleIconUrl(iconUrl, defaultIcon)}
						style={{ marginLeft: '-0.1rem', marginBottom: '2rem' }}
					/>
					<Grid
						container
						columnSpacing={6}
						columns={12}
						rowSpacing={3}
						direction='row'
						style={{
							display: 'flex',
							width: '92%',
						}}
					>
						<Grid item xs={3}>
							<InputFieldText
								label='App Name'
								disabled={!permissions.editMainParams}
								type='text'
								value={appName}
								onChange={(e: string) => {
									setAppNameTouched(true)
									setAppName(e)
								}}
								errormessage={
									appNameTouched && !stringIsValidName(appName)
										? "App name is mandatory. App name must not start or end with whitespace and must not contain '/', '%', ',' or '.'"
										: undefined
								}
							/>
						</Grid>
						<Grid item xs={3}>
							<InputFieldText
								label='Additional Tag'
								type='text'
								disabled={!permissions.editOtherParams}
								value={additionalTag}
								onChange={setAdditionalTag}
							/>
						</Grid>
						<Grid item xs={3}>
							<DropList
								label='Advertiser'
								options={advertiserList || []}
								disabled={
									companyType === 'advertiser' || !permissions.editMainParams
								}
								value={
									companyType === 'advertiser'
										? advertiserList[0]
										: advertiserName
								}
								onChange={(value: any) => {
									setAdvertiserName(value)
									setAppId('')
								}}
								mandatory={mandatoryFields.advertiser}
							/>
						</Grid>

						<Grid item xs={3}>
							<DropList
								label='App ID'
								options={appList}
								disabled={!permissions.editMainParams}
								value={appId}
								onChange={(value: any) => {
									setAppId(value)
								}}
								mandatory={mandatoryFields.appId}
							/>
						</Grid>

						<Grid item xs={3}>
							<InputFieldText
								label='Appsflyer C Parameter'
								type='text'
								value={capitalizeFirstLetter(cParam)}
								onChange={(e: any) => {
									setCParam(e)
								}}
								onFocus={() => setCParamValid(false)}
								onBlur={() =>
									cParam === '' ? setCParamValid(false) : setCParamValid(true)
								}
								hint={
									cParam
										? undefined
										: 'C Parameter is what comes AFTER an equality ( = ) sign'
								}
								checked={cParamValid}
								disabled={!permissions.editMainParams}
							/>
						</Grid>

						<Grid item xs={3}>
							<DropList
								label='Privacy Level'
								options={['Private', 'Premoderation', 'Open']}
								disabled={!permissions.editOtherParams}
								value={privacyLevel}
								onChange={(e: any) => {
									setPrivacyLevel(e)
								}}
							/>
						</Grid>

						<Grid item xs={3}>
							<DropList
								disabled={!permissions.editOtherParams}
								label='Status'
								options={['Active', 'Disabled']}
								value={status ? 'Active' : 'Disabled'}
								onChange={(e: any) => {
									setStatus(e === 'Active')
								}}
							/>
						</Grid>

						{/* <Grid
                            item
                            xs={3}
                            flexDirection='column'
                            style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}
                        > */}
						<Grid item xs={3}>
							<DropList
								disabled={!permissions.editOtherParams}
								label='Fraud Tools'
								options={settings.settings.fraudTools}
								value={fraudTools}
								onChange={(e: any) => {
									setFraudTools(e)
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<DropList
								disabled={!permissions.editOtherParams}
								label='Campaign Flow'
								options={['CPI', 'CPA', 'CPC', 'CPM', 'Rev-Share']}
								value={campaignFlow}
								onChange={(e: any) => {
									setCampaignFlow(e)
								}}
								errored={!campaignFlow}
								mandatory={mandatoryFields.campaignFlow}
							/>
						</Grid>
						{/* </Grid> */}

						<Grid item xs={3}>
							<ChipInputList
								disabled={!permissions.editOtherParams}
								label='Agency Account'
								openWithClick
								options={
									advertiserName
										? [
												...accounts.accounts.map((e: any) => e.identifier),
										  ]?.filter((e) => e != '') || ['thingortwo']
										: ['thingortwo']
								}
								value={networkAccount}
								onChange={(e: any) => {
									setNetworkAccount(e)
								}}
								onlyOptionsAllowed
							/>
						</Grid>
						<GridContainer item xs={3}>
							<SwitchComponent
								onChange={setIncentive}
								value={incentive}
								label={'Incent Allow'}
								disabled={!permissions.editOtherParams}
							/>
						</GridContainer>
						{companyType !== 'advertiser' && (
							<GridContainer item xs={3}>
								<SwitchComponent
									onChange={setDummyPids}
									value={dummyPids}
									label={'Dummy PIDs'}
									disabled={!permissions.editOtherParams}
								/>
							</GridContainer>
						)}
						<Grid item xs={6}>
							<InputFieldText
								label='Preview Url'
								type='text'
								value={previewUrl ?? ''}
								onChange={(e: any) => {
									setPreviewUrl(e)
								}}
								disabled={!permissions.editOtherParams}
								showUrlIcon
							/>
						</Grid>
						{companyType !== 'advertiser' && (
							<Grid item xs={6}>
								<InputFieldText
									type='text'
									onChange={setAdvfileLink}
									value={advfileLink}
									label='Link to Google Drive'
									color={
										creativesLink &&
										!checkInputStartsWithHttp(creativesLink) &&
										isValid === false
											? 'error'
											: 'primary'
									}
									disabled={!permissions.editOtherParams}
									showUrlIcon
								/>
							</Grid>
						)}
					</Grid>
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<CompanyInformationHeader
						name={campaignName}
						iconUrl={handleIconUrl(iconUrl, defaultIcon)}
						style={{ marginLeft: '-0.1rem', marginBottom: '2rem' }}
					/>
					<Grid container direction={'column'} style={{ width: '92%' }}>
						<Box
							style={{
								textOverflow: 'ellipsis',
								// padding: '15px',
								maxHeight: '200vh',
								overflow: 'hidden',
							}}
						>
							<Grid
								container
								style={{ marginLeft: '3px!important', marginTop: 12 }}
								marginLeft={'3px'}
							>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setVtaAllowed}
										value={vtaAllowed}
										label={'VTA allowed'}
										disabled={!permissions.editOtherParams}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'vtaAllowed'}
                                        control={
                                            <Checkbox
                                                disabled={!permissions.editOtherParams}
                                                onChange={(e) => {
                                                    setVtaAllowed(!vtaAllowed)
                                                }}
                                                checked={vtaAllowed}
                                                key={'vtaAllowed_checkbox'}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'VTA allowed'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setProbVta}
										value={probVta}
										label={'Probabilistic VTA'}
										disabled={!permissions.editOtherParams}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'probabilisticVTA'}
                                        control={
                                            <SwitchToggle
                                                onChange={(e: any) => {
                                                    setProbVta(!probVta)
                                                }}
                                                checked={probVta}
                                                key={'probabilisticVTA_checkbox'}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                disabled={!permissions.editOtherParams}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'Probabilistic VTA'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setRevenueNeeded}
										value={revenueNeeded}
										label={'Revenue'}
										disabled={!permissions.editOtherParams}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'revenueData'}
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setRevenueNeeded(!revenueNeeded)
                                                }}
                                                checked={revenueNeeded}
                                                key={'revenueData_checkbox'}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                disabled={!permissions.editOtherParams}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'Revenue'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setOrganicNeeded}
										value={organicNeeded}
										label={'Organic Data'}
										disabled={!permissions.editOtherParams}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'organicData'}
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setOrganicNeeded(!organicNeeded)
                                                }}
                                                checked={organicNeeded}
                                                key={'organicData_checkbox'}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                disabled={!permissions.editOtherParams}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'Organic Data'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
								<Grid item xs={2.3} style={{ display: 'flex' }}>
									<SwitchComponent
										style={{ alignSelf: 'start' }}
										onChange={setRetargeting}
										value={retargeting}
										label={'Retargeting'}
										disabled={!permissions.editOtherParams}
									/>
									{/* <StyledCheckboxFormControl
                                        key={'retargeting'}
                                        control={
                                            <Checkbox
                                                onChange={(e) => {
                                                    setRetargeting(!retargeting)
                                                    // handleChangeField(!retargeting, 0, 'retargeting')
                                                }}
                                                checked={retargeting}
                                                key={'retargeting_checkbox'}
                                                inputProps={{
                                                    'aria-label': 'controlled',
                                                }}
                                                style={{ marginLeft: '0px', color: '#ee1d4a' }}
                                                disabled={!permissions.editOtherParams}
                                                // disabled={
                                                //  login.user.role.toLowerCase() === 'view' ||
                                                //  login.user.role.toLowerCase() === 'designer'
                                                // }
                                            />
                                        }
                                        label={
                                            <Typography
                                                fontSize='13px'
                                                fontWeight={500}
                                                color={'secondary'}
                                            >
                                                {'Retargeting'}
                                            </Typography>
                                        }
                                    /> */}
								</Grid>
							</Grid>
							<Grid
								container
								style={{ marginTop: '32px', width: '100%' }}
								columnSpacing={4}
								rowSpacing={4}
							>
								<Grid item xs={6} style={{ width: '100%' }}>
									<StyledTextAreaField
										key='hardKpiInput'
										onChange={setHardKpi}
										label='Hard KPI'
										disabled={!permissions.editOtherParams}
										value={hardKpi}
									/>
								</Grid>
								<Grid item xs={6} style={{ width: '100%' }}>
									<StyledTextAreaField
										key='softKpiInput'
										onChange={setSoftKpi}
										label='Soft KPI'
										disabled={!permissions.editOtherParams}
										value={softKpi}
									/>
								</Grid>
								<Grid item xs={6}>
									<StyledTextAreaField
										key='flowInput'
										label='Complete Flow'
										onChange={setEventsFlow}
										value={eventsFlow}
										disabled={!permissions.editOtherParams}
									/>
								</Grid>
								<Grid item xs={6}>
									<StyledTextAreaField
										key='notesInput'
										label='Special Notes'
										onChange={setNote}
										value={note}
										disabled={!permissions.editOtherParams}
									/>
								</Grid>
								{advertiserName !== '' && (
									<Grid item xs={6}>
										{filteredTrafficRestrictions.length > 0 ? (
											<ChipInputList
												options={filteredTrafficRestrictions}
												label={'Traffic Restrictions'}
												value={traffic}
												onChange={(selectedTraffic: any) => {
													setTraffic(selectedTraffic)
												}}
												openWithClick
												disabled={!permissions.editOtherParams}
											/>
										) : (
											<Typography color='error'>
												All traffic restrictions are already applied by the
												advertiser.
											</Typography>
										)}
									</Grid>
								)}
								{advertiserName !== '' && (
									<Grid item xs={6}>
										<ChipInputList
											options={settings.settings.existingChannels || []}
											label={'Existing Channels Client is Using'}
											value={usedExistingChannels}
											onChange={(e: any) => {
												setUsedExistingChannels(e)
											}}
											openWithClick
											disabled={!permissions.editOtherParams}
										></ChipInputList>
									</Grid>
								)}
								{advertiserName !== '' && (
									<Grid item xs={12}>
										<div
											style={{
												color: theme.colors.base.grey900,
												fontSize: '1rem',
												fontWeight: theme.font.weight.bold,
												marginLeft: '5px',
												marginTop: '22px',
											}}
										>
											Target Audience
										</div>
									</Grid>
								)}
								{advertiserName !== '' && (
									<Grid item xs={6}>
										<RadioChipInput
											disabled={!permissions.editOtherParams}
											label='Gender'
											options={['Male', 'Female', 'Both']}
											value={gender}
											onChange={(e: any) => {
												setGender(e)
											}}
										/>
									</Grid>
								)}
								{advertiserName !== '' && (
									<Grid item xs={6}>
										<div style={{ width: '50%', position: 'relative' }}>
											<div
												style={{
													display: 'flex',
													flexDirection: 'row',
													gap: '18px',
												}}
											>
												<InputFieldText
													label='Age'
													type='number'
													value={targetAudienceAgeFrom}
													onChange={(e: any) => setTargetAudienceAgeFrom(e)}
													float='left'
													placeholder={'from'}
													disabled={!permissions.editOtherParams}
												/>

												<InputFieldText
													label={''}
													type='number'
													value={targetAudienceAgeTo}
													onChange={(e: any) => setTargetAudienceAgeTo(e)}
													float='right'
													placeholder={'to'}
													disabled={!permissions.editOtherParams}
												/>
											</div>
										</div>
									</Grid>
								)}
							</Grid>
						</Box>
					</Grid>
				</TabPanel>
				<TabPanel value={tab} index={2} key={'panel_123'}>
					<CompanyInformationHeader
						name={campaignName}
						iconUrl={handleIconUrl(iconUrl, defaultIcon)}
						style={{ marginLeft: '-0.1rem', marginBottom: '2rem' }}
					/>
					<Grid
						container
						columns={12}
						columnSpacing={6}
						rowSpacing={0}
						direction='row'
						style={{
							display: 'flex',
							rowGap: '1.2rem',
							marginTop: 24,
							marginBottom: 24,
							width: '92%',
						}}
					>
						<Grid item xs={3}>
							<DatePicker
								label={'Campaign End Date'}
								onChange={setEndDate}
								value={endDate}
								disabled={!permissions.editOtherParams}
								format='dd/MM/yyyy'
							/>
						</Grid>
						<Grid item xs={3}>
							<DropList
								label='Time Frame'
								options={['Monthly', 'Total']}
								// float='right'
								disabled={!permissions.editOtherParams}
								value={timeFrame}
								onChange={(value: any) => {
									setTimeFrame(value)
								}}
							/>
						</Grid>
						<Grid item xs={3}>
							<InputFieldText
								key='amountInput'
								onChange={(e: any) => {
									setBudgetAmount(e)
								}}
								value={budgetAmount}
								label='Budget'
								type={'text'}
								disabled={!permissions.editOtherParams}
							/>
						</Grid>
						<Grid item xs={3}>
							<InputFieldText
								key='Capnput'
								onChange={(e: any) => {
									setCap(e)
								}}
								value={cap}
								label='Cap'
								type={'text'}
								disabled={!permissions.editOtherParams}
							/>
						</Grid>
						<Grid
							item
							xs
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{conflicts.length > 0 && (
								<Card
									style={{
										marginTop: '23px',
										border: '1px solid rgba(0, 0, 0, 0.3)',
										width: '98%',
										borderRadius: '17px',

										display: 'flex',
									}}
								>
									<Typography
										style={{
											fontWeight: '500',
											fontSize: '14px',
											marginLeft: '20px',
											marginTop: '10px',
										}}
									>
										Media Source PID conflicts
									</Typography>
									<Grid container>{conflicts}</Grid>
								</Card>
							)}
						</Grid>
					</Grid>

					{uniquesComponents}

					{uniqueTabState}

					{permissions.addPayouts && (
						<div
							style={{
								display: 'flex',
								width: '100%',
								marginTop: '6vh',
							}}
						>
							<UnborderedButton
								onClick={() => makeUniqueTab()}
								label={'Add Campaign'}
								icon={
									<AddIcon
										style={{
											border: '1px solid ' + theme.colors.text.titles,
											borderRadius: '100px',
											width: '14px',
											height: '14px',
										}}
									/>
								}
								style={{ color: theme.colors.text.titles }}
								mandatory={!campaignAdded && mandatoryFields.uniqueCampaigns}
								interacted={fieldValidations.uniqueCampaigns}
							/>
						</div>
						// <PlusTab
						//  onClick={() => {
						//      makeUniqueTab()
						//  }}
						//  value={pointer}
						//  // disabled={uniqueIsEnabled}
						//  // disabled={!uniqueIsEnabled}
						//  style={{
						//      position: 'relative',
						//      display: 'flex',

						//      top: '30px',
						//      width: '17%',
						//      boxShadow:
						//          uniquesComponents.length === 0 ? 'red 0px 0px 7px' : 'none',
						//      alignSelf: 'flex-end',
						//      justifySelf: 'center',
						//      alignItems: 'center',
						//  }}
						// >
						//  + Unique
						// </PlusTab>
					)}
				</TabPanel>
				<TabPanel value={tab} index={3} key={'panel_665'}>
					<Grid
						container
						direction={'column'}
						marginTop={'0px'}
						style={{
							alignItems: 'center',
							justifyContent: 'center',
							width: '80vw',
						}}
					>
						<Box
							style={{
								textOverflow: 'ellipsis',
								padding: '15px',
								paddingInline: '100px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%',
								overflow: 'auto',
							}}
						>
							{/* {campaignRules.rules && ( */}
							{/* <CampaignRules
                                submit={() => trySubmit()}
                                updateRules={(reportType: string, rules: any) =>
                                    updateCampaignRules(reportType, rules)
                                }
                                campaignRules={campaignRules}
                                setErrorMessage={(str: string) => props.setErrorMessage(str)}
                            /> */}
							{/* )} */}
						</Box>
					</Grid>
				</TabPanel>
			</PageWrapper>
			{tab !== 3 && (
				<ButtonBar style={{ marginLeft: '30px', marginBottom: '35px' }}>
					{/* {permissions.editOtherParams ||
                        (ruleSet.rules[
                            'editing main parameters  (advertiser, app_id, c parameter, media source, geos, payouts)'
                        ] && ( */}
					<UnborderedButton
						onClick={() => {
							nullifyCompaignStates()
							props.setRow(undefined)
							navigate('/campaigns')
							props.setPopUpVisible(false)
						}}
						style={{ color: theme.colors.text.titles }}
						label={'Cancel'}
					/>
					{/* ))} */}
					{((permissions.editOtherParams && permissions.editMainParams) ||
						(permissions.edit && campaignOwner === login.user.email)) && (
						<SubmitButton
							// disabled={!isValid || pointer !== 0}
							disabled={
								!(
									appId &&
									advertiser &&
									campaignFlow &&
									uniquesComponents.length > 0
								)
							}
							onClick={() => {
								isEditingHistory ? trySaveHistoricalEnrty() : trySubmit()
								getCampaignHistoryByMainDtoAction(
									{ main_uuid: uuidInUrl },
									setHistoryIsLoading,
								)
							}}
							// style={{ width: 100, height: 30 }}
						>
							{props.duplicating === true
								? 'Duplicate'
								: foundCampaign
								? isEditingHistory
									? 'Save History'
									: 'Update'
								: 'Save'}
						</SubmitButton>
					)}
					{permissions.openHistory ? (
						<SubmitButton
							// disabled={!isValid || pointer !== 0}
							disabled={props.duplicating === true || !foundCampaign}
							onClick={() => {
								openADatePicker()
							}}
							// style={{ width: '12%' }}
						>
							{'Update From Date'}
						</SubmitButton>
					) : (
						<></>
					)}
				</ButtonBar>
			)}
			{tab !== 3 && permissions.openHistory ? (
				<DatePickerComponent
					setDatePicked={setDatePicked}
					datePicked={datePicked}
					setDatePickerOpened={setDatePickerOpened}
					datePickerOpened={datePickerOpened}
					trySubmit={trySubmit}
				></DatePickerComponent>
			) : (
				<></>
			)}
			{tab !== 3 && permissions.openHistory ? (
				<DatePickerComponent
					setDatePicked={setDatePicked}
					datePicked={datePicked}
					setDatePickerOpened={setDateMoverOpened}
					datePickerOpened={dateMoverOpened}
					trySubmit={trySaveHistoricalEnrty}
				></DatePickerComponent>
			) : (
				<></>
			)}
			{/* temporally hiding history array in order to deploy */}
			{tab !== 3 &&
			permissions.openHistory &&
			historyArray &&
			!historyIsLoading ? (
				<Paper
					style={{
						borderRadius: '30px',
						padding: '20px',
						paddingInline: '30px',
						zIndex: '15',
						gridAutoFlow: 'column',
						width: 'fit-content',
						marginInline: 'auto',
						marginBottom: '30px',
					}}
				>
					{' '}
					<div
						style={{
							color: '#f2f2f2',
							marginBottom: '20px',
							marginInline: '30px',
							fontSize: '20px',
							fontWeight: 'bold',
						}}
					>
						<div
							style={{
								color: theme.colors.text.titles,
							}}
						>
							History
						</div>
					</div>
					<Grid
						container
						columns={110}
						direction={'row'}
						alignItems={'center'}
						style={{
							background: 'transparent',
							// height: '70%',

							bottom: '200px',
							borderRadius: '30px',
							textAlign: 'center',
							width: 1000,
							gridAutoFlow: 'column',
							alignItems: 'center',
							// width: widthArray[i] + 'px',
						}}
					>
						{historyArray}
					</Grid>
					{/* <Grid
                            container
                            columns={100}
                            height={'60px'}
                            paddingBottom={'30px'}
                            style={{
                                background: 'transparent',
                                // height: '70%',
                                // marginRight: '10px',
                                bottom: '200px',
                                borderRadius: '30px',
                                textAlign: 'center',
                                width: 1000,
                                overflow: 'clip',
                                // width: widthArray[i] + 'px',
                            }}
                        >
                            {historyLining}
                        </Grid> */}
				</Paper>
			) : (
				<></>
			)}
		</ThemeProvider>
	)
}
export default CampaignAdd
